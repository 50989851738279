import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Address from '../Address.component';
import HighlightText from '../HighlightText.component';
import Country from '../Country.data';
import 'flag-icons/css/flag-icons.min.css';

const Container = styled.div`
    background-color: transparent;
`

function Location(props) {

    const country = Country[Object.keys(Country).find(key => Country[key].countryNo === props.country.countryNo)];

    return (
        <Container className="container area">
            <div className="row marginTop25px lower">
                <div className="col-12 text-center">
                    {
                        (country.flag?.length > 0) &&
                        <>
                            <span className={country.flag + " flag flagSelect"}></span>
                            <span> </span>
                        </>
                    }
                    <HighlightText
                        text={country.name[props.currentLanguage].toUpperCase()}
                        highlight={props.currentSearchText}
                    />
                    <br />
                    <Address 
                        key={"addressKey"} 
                        country={props.country} 
                        currentSearchText={props.currentSearchText}
                    />
                </div>
            </div>
        </Container>
    );
}

export default Location;