import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import DistributionIn from './DistributionIn.component.js';
import HighlightText from '../HighlightText.component.js';
import Language from '../Language.data';
import { isNullOrUndefined, highlightText, replaceSystemNewLineCharactersWithHtml } from '../../utils/utils.js';

import externalLinkImage from '../../images/externalLink.svg';

const ProductGroupContainer = styled.div`
    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
    }
` 

const ProductGroupHeader = styled.div`
    font-weight:bold;
    font-size:1.25rem;
    margin-bottom:1rem;
`

function ProductGroup(props) {
    const [hasHomepage, setHasHomepage] = useState(null);
    const [supplierName, setSupplierName] = useState(null);
    const [supplierWebsite, setSupplierWebsite] = useState(null);
    const [supplierId, setSupplierId] = useState(null);
 
    useEffect(() => {
        let firstCountryLink = props.productGroup.country_links[0];
        if(!isNullOrUndefined(firstCountryLink)) {
            setHasHomepage((firstCountryLink.supplier.Homepage.trim().length > 0 && firstCountryLink.supplier.Homepage.trim() !== 'http://www.grolman-group.com') ? true : false);
            setSupplierName(firstCountryLink.supplier.Bezeichnung);
            setSupplierWebsite(firstCountryLink.supplier.Homepage);
            setSupplierId(firstCountryLink.supplier.Lieferanten_ID);
        }
    },[]);

    function createMarkup(htmlString) {
        htmlString = htmlString.replaceAll('®','<sup>®</sup>');
        htmlString = htmlString.replaceAll('TM','<sup>TM</sup>');
        if(isNullOrUndefined(props.currentSearchText)) {
            return {__html: htmlString};
        }
        else {
            return {__html: highlightText(replaceSystemNewLineCharactersWithHtml(htmlString),props.currentSearchText)};
        }
    }

    return (
        <ProductGroupContainer className="container-fluid">
            <div className="row">
                <div className="col-12 text-center">
                    <div className="card-body">
                        <div className="borderRadius10px border1pxSolide0e0e0 padding10px">
                            <div className="d-block d-md-none">
                                <div className="row">
                                    <ProductGroupHeader className="col-12">
                                        {
                                            isNullOrUndefined(props.currentSearchText) &&
                                            props.productGroup.Bezeichnung
                                        }
                                        {
                                            !isNullOrUndefined(props.currentSearchText) &&
                                            <HighlightText
                                                text={props.productGroup.Bezeichnung}
                                                highlight={props.currentSearchText}
                                            />
                                        }
                                    </ProductGroupHeader>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>{Language[props.currentLanguage].producer}</strong>
                                    </div>
                                    <div className="col-12">
                                        {
                                            hasHomepage && props.productGroup.ID === '2061' &&
                                            <div>
                                                <a href={"https://www.momentive.com/en-us/categories/silicone-pressure-sensitive-adhesives"} className="link" target="_blank">
                                                    {
                                                        isNullOrUndefined(props.currentSearchText) &&
                                                        supplierName
                                                    }
                                                    {
                                                        !isNullOrUndefined(props.currentSearchText) &&
                                                        <HighlightText
                                                            text={supplierName}
                                                            highlight={props.currentSearchText}
                                                        />
                                                    }
                                                </a>
                                                <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                            </div>
                                        }
                                        {
                                            hasHomepage && props.productGroup.ID !== '2061' &&
                                            <div>
                                                <a href={(supplierWebsite.startsWith("http")) ? supplierWebsite : "http://" + supplierWebsite} className="link" target="_blank">
                                                    {
                                                        isNullOrUndefined(props.currentSearchText) &&
                                                        supplierName
                                                    }
                                                    {
                                                        !isNullOrUndefined(props.currentSearchText) &&
                                                        <HighlightText
                                                            text={supplierName}
                                                            highlight={props.currentSearchText}
                                                        />
                                                    }
                                                </a>
                                                <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                            </div>
                                        }
                                        {
                                            !hasHomepage && isNullOrUndefined(props.currentSearchText) &&
                                                supplierName
                                        }
                                        {
                                            !hasHomepage && !isNullOrUndefined(props.currentSearchText) &&
                                            <HighlightText
                                                text={supplierName}
                                                highlight={props.currentSearchText}
                                            />
                                        }
                                    </div> 
                                </div>
                                {
                                    // More Info Link nur anzeigen wenn Lieferant "Elementis" & WOGs 2140,2141,2142 und BU "Additive"
                                    (supplierId === "22724" && props.mainProductGroup.buId === "1" && ['2140','2141','2142'].includes(props.productGroup.productGroupID)) &&
                                    <div className="row">
                                        <div className="col-12">
                                            <a href="https://web2.grolman-group.com/elementis-additifs/" className="" rel="noopener noreferrer" target="_blank">{Language[props.currentLanguage].moreInfo}</a>
                                            <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                        </div> 
                                    </div>
                                }
                                { 
                                    // More Info Link nur anzeigen wenn Lieferant "Sapici" und BU "Binders"
                                    (supplierId === "22012" && props.mainProductGroup.buId === "2") &&
                                    <div className="row">
                                        <div className="col-12">
                                            <a href="https://web2.grolman-group.com/sapici-laminating-adhesives/" className="" rel="noopener noreferrer" target="_blank">{Language[props.currentLanguage].moreInfo}</a>
                                            <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                        </div> 
                                    </div>
                                }
                                <div className="row marginTop25px">
                                    <div className="col-12">
                                        <strong>{Language[props.currentLanguage].tradeName}</strong>
                                    </div> 
                                    <div className="col-12">
                                        <div dangerouslySetInnerHTML={createMarkup(props.productGroup.Markenname)} />
                                    </div> 
                                </div>
                                <div className="row marginTop25px">
                                    <div className="col-12">
                                        <strong>{Language[props.currentLanguage].description}</strong>
                                    </div> 
                                    <div className="col-12">
                                        <div dangerouslySetInnerHTML={createMarkup(props.productGroup.Beschreibung)} />
                                    </div> 
                                </div>
                                {
                                    props.productGroup.applications?.length > 0 &&
                                    <div className="row marginTop25px">
                                        <div className="col-12">
                                            <strong>{Language[props.currentLanguage].applications}</strong>
                                        </div>
                                        <div className="col-12">
                                            {
                                                props.productGroup.applications?.map((application) => {
                                                    let cssClass = "";
                                                    return (
                                                        <div 
                                                            key={"productGroup" + props.productGroup.productGroupID + "Application" + application.label} 
                                                            className={cssClass}
                                                        >
                                                            {
                                                                isNullOrUndefined(props.currentSearchText) &&
                                                                application.label.replace(application.mainBranchLabel + ' - ','')
                                                            }
                                                            {
                                                                !isNullOrUndefined(props.currentSearchText) &&
                                                                <HighlightText
                                                                    text={application.label.replace(application.mainBranchLabel + ' - ','')}
                                                                    highlight={props.currentSearchText}
                                                                />
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="d-none d-md-block">
                                <div className="row">
                                    <ProductGroupHeader className="col-12">
                                        {
                                            isNullOrUndefined(props.currentSearchText) &&
                                            props.productGroup.Bezeichnung
                                        }
                                        {
                                            !isNullOrUndefined(props.currentSearchText) &&
                                            <HighlightText
                                                text={props.productGroup.Bezeichnung}
                                                highlight={props.currentSearchText}
                                            />
                                        }
                                    </ProductGroupHeader>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-right">
                                        <strong>{Language[props.currentLanguage].producer}</strong>
                                    </div> 
                                    <div className="col-6 text-left">
                                        {
                                            hasHomepage && props.productGroup.ID === '2061' &&
                                            <div>
                                                <a href={"https://www.momentive.com/en-us/categories/silicone-pressure-sensitive-adhesives"} className="link" target="_blank">
                                                    {
                                                        isNullOrUndefined(props.currentSearchText) &&
                                                        supplierName
                                                    }
                                                    {
                                                        !isNullOrUndefined(props.currentSearchText) &&
                                                        <HighlightText
                                                            text={supplierName}
                                                            highlight={props.currentSearchText}
                                                        />
                                                    }
                                                </a>
                                                <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                            </div>
                                        }
                                        {
                                            hasHomepage && props.productGroup.ID !== '2061' &&
                                            <div>
                                                <a href={(supplierWebsite.startsWith("http")) ? supplierWebsite : "http://" + supplierWebsite} className="link" target="_blank">
                                                    {
                                                        isNullOrUndefined(props.currentSearchText) &&
                                                        supplierName
                                                    }
                                                    {
                                                        !isNullOrUndefined(props.currentSearchText) &&
                                                        <HighlightText
                                                            text={supplierName}
                                                            highlight={props.currentSearchText}
                                                        />
                                                    }
                                                </a>
                                                <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                            </div>
                                        }
                                        {
                                            !hasHomepage && isNullOrUndefined(props.currentSearchText) &&
                                                supplierName
                                        }
                                        {
                                            !hasHomepage && !isNullOrUndefined(props.currentSearchText) &&
                                            <HighlightText
                                                text={supplierName}
                                                highlight={props.currentSearchText}
                                            />
                                        }
                                    </div> 
                                </div>
                                {
                                    // More Info Link nur anzeigen wenn Lieferant "Elementis" & WOGs 2140,2141,2142 und BU "Additive"
                                    (supplierId === "22724" && props.mainProductGroup.buId === "1" && ['2140','2141','2142'].includes(props.productGroup.productGroupID)) &&
                                    <div className="row">
                                        <div className="col-6">
                                        </div> 
                                        <div className="col-6 text-left">
                                            <a href="https://web2.grolman-group.com/elementis-additifs/" className="" rel="noopener noreferrer" target="_blank">{Language[props.currentLanguage].moreInfo}</a>
                                            <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                        </div> 
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-6 text-right">
                                        <strong>{Language[props.currentLanguage].tradeName}</strong>
                                    </div> 
                                    <div className="col-6 text-left">
                                        <div dangerouslySetInnerHTML={createMarkup(props.productGroup.Markenname)} />
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-6 text-right">
                                        <strong>{Language[props.currentLanguage].description}</strong>
                                    </div> 
                                    <div className="col-6 text-left">
                                        <div dangerouslySetInnerHTML={createMarkup(props.productGroup.Beschreibung)} />
                                    </div> 
                                </div>
                                {
                                    props.productGroup.applications?.length > 0 &&
                                    <div className="row">
                                        <div className="col-6 text-right">
                                                <strong>{Language[props.currentLanguage].applications}</strong>
                                        </div>
                                        <div className="col-6 text-left">
                                            {
                                                props.productGroup.applications?.map((application) => {
                                                    let cssClass = "";
                                                    return (
                                                        <div 
                                                            key={"productGroup" + props.productGroup.productGroupID + "Application" + application.label} 
                                                            className={cssClass}
                                                        >
                                                            {
                                                                isNullOrUndefined(props.currentSearchText) &&
                                                                application.label.replace(application.mainBranchLabel + ' - ','')
                                                            }
                                                            {
                                                                !isNullOrUndefined(props.currentSearchText) &&
                                                                <HighlightText
                                                                    text={application.label.replace(application.mainBranchLabel + ' - ','')}
                                                                    highlight={props.currentSearchText}
                                                                />
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-12">
                                <DistributionIn
                                    currentLanguage={props.currentLanguage}
                                    productGroup={props.productGroup}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ProductGroupContainer>
    )
}

export default ProductGroup;