import styled from 'styled-components';

export const ImageColumnDiv = styled.div`
    justify-content: ${props => props.imagePosition && props.imagePosition !== null ? props.imagePosition : 'end'};

    @media only screen and (max-width: 775px) { 
        justify-content: center;
    }
`

export const ImageColumn = styled.div`
    background-image:url(${props => props.backgroundImage ? props.backgroundImage : 'unset'});
    border-radius: 50%;
    width: ${props => props.imageSize && props.imageSize !== null ? props.imageSize : '200px'};
    height: ${props => props.imageSize && props.imageSize !== null ? props.imageSize : '200px'};
    background-size: cover;
    background-repeat: no-repeat;
`

export const ImageColumnTextDiv = styled.div`
    text-align: left;
    width: 75vw;

    @media only screen and (max-width: 775px) { 
        text-align: center;
    }   
`