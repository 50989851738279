import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Additives from './businessUnits/Additives.component';
import Binders from './businessUnits/Binders.component';
import PerformanceMaterialsAndFillers from './businessUnits/PerformanceMaterialsAndFillers.component';
import PigmentsAndDyes from './businessUnits/PigmentsAndDyes.component';
import Adhesives from './industries/Adhesives.component';
import PaintsAndCoatings from './industries/PaintsAndCoatings.component';
import PrintingInksAndGraphicArts from './industries/PrintingInksAndGraphicArts.component';
import Rubber from './industries/Rubber.component';
import Construction from './industries/Construction.component';
import Plastics from './industries/Plastics.component';
import Paper from './industries/Paper.component';
import Sealants from './industries/Sealants.component';

const fadeIn = keyframes`
    0% {
        transform: scale(0);
        opacity: 0.3;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

export const FadeIn = styled.div`
    animation: ${fadeIn};
    animation-duration: 0.3s;
`

function FocusBusinessSegment(props) {
    function getComponent() {
        switch(props.businessSegment) {
            case 'additives':
                return <Additives
                            key={"focusBuAdditives"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'binders':
                return <Binders
                            key={"focusBuBinders"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'fillers':
                return <PerformanceMaterialsAndFillers
                            key={"focusBuPerformanceMaterialsAndFillers"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'pigments':
                return <PigmentsAndDyes
                            key={"focusBuPigmentAndDyes"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'adhesives':
                return <Adhesives
                            key={"focusIndustryAdhesives"}
                            currentLanguage={props.currentLanguage}
                       />
            case 'surfaceCoatings':
                return <PaintsAndCoatings
                            key={"focusIndustryPaintsAndCoatings"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'printingInk':
                return <PrintingInksAndGraphicArts
                            key={"focusIndustryPrintingInksAndGraphicArts"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'rubber':
                return <Rubber
                            key={"focusIndustryRubber"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'construction':
                return <Construction
                            key={"focusIndustryConstruction"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'plastics':
                return <Plastics
                            key={"focusIndustryPlastics"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'paper':
                return <Paper
                            key={"focusIndustryPaper"}
                            currentLanguage={props.currentLanguage}
                        />
            case 'sealants':
                return <Sealants
                            key={"focusIndustrySealants"}
                            currentLanguage={props.currentLanguage}
                        />
        }
    }

    return (
        <FadeIn key={`productsFocusBusinessSegment${props.businessSegment}`}>
            <div className="container">
                {
                    getComponent()
                }
            </div>
        </FadeIn>
    )
}

export default FocusBusinessSegment; 