import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import DistributionCountry from './DistributionCountry.component.js';
import Language from '../Language.data.js';
import Country from '../Country.data.js';
import { isNullOrUndefined } from '../../utils/utils.js';

const CursorPointerDiv = styled.div`
    cursor: pointer;
`

const PaddingDiv = styled.div`
    padding:0.5rem;
`

function DistributionIn(props) {

    const [isShowMoreCountriesActive, setIsShowMoreCountriesActive] = useState(false);
    const [countryLinks, setCountryLinks] = useState([]);

    useEffect(() => {
        getCountriesInCurrentLanguage();
    },[]);

    useEffect(() => {
        getCountriesInCurrentLanguage();
    },[props.currentLanguage])

    function getCountriesInCurrentLanguage() {
        let updatedCountryLinks = _.cloneDeep(props.productGroup.country_links);
        updatedCountryLinks.map((countryLink) => {
            countryLink.country = countryLink.country.find(country => country.Sprach_ID === Language[props.currentLanguage].languageNo);
            return countryLink;
        });
        setCountryLinks(updatedCountryLinks);
    }

    function getNumberOfValidCountryLinks() {
        let numberOfValidCountryLinks = 0;
        countryLinks.forEach(countryLink => {
            if(
                countryLink.Land_ID !== 9999 && 
                !isNullOrUndefined(countryLink.country) && 
                Country[Object.keys(Country).find(key => Country[key].countryNo === countryLink.country.ID)].showCountry === true &&
                countryLink.Politischer_Schalter == 0
            ) {
                numberOfValidCountryLinks++;
            }
        });
        return numberOfValidCountryLinks;
    }

    return (
        props.productGroup.country_links?.length > 0 &&
        <div className="row marginTop25px">
            <div className="col-12 d-block d-md-none">
                <div className="row">
                    <div className="col-12">
                            <strong>{Language[props.currentLanguage].distributionIn}</strong>
                    </div>
                    <div className="col-12">
                        {
                            _.sortBy(countryLinks.filter((countryLink) => countryLink.Politischer_Schalter === 0), (link) => link.country?.Bezeichnung).map((countryLink, index) => {
                                if(
                                    countryLink.Land_ID !== 9999 && 
                                    !isNullOrUndefined(countryLink.country) && 
                                    Country[Object.keys(Country).find(key => Country[key].countryNo === countryLink.country.ID)].showCountry === true &&
                                    props.productGroup.contacts.find(countryContact => countryContact.Land_ID === countryLink.Land_ID) !== undefined &&
                                    (
                                        index < 3 ||
                                        isShowMoreCountriesActive
                                    )
                                    
                                ) {                                                        
                                    return (
                                        <DistributionCountry
                                            key={`productGroupCountryLink${countryLink.Auto_ID}`}
                                            countryLink={countryLink}
                                            currentLanguage={props.currentLanguage}
                                            contact={props.productGroup.contacts.find(countryContact => countryContact.Land_ID === countryLink.Land_ID)?.contact}
                                        />
                                    )
                                }
                            })
                        }
                        {
                            getNumberOfValidCountryLinks() > 2 && isShowMoreCountriesActive === false &&
                            <CursorPointerDiv onClick={(event) => setIsShowMoreCountriesActive(true)}>
                                {
                                    `...and ${getNumberOfValidCountryLinks() - 3} countries more. Click here to show all`
                                }
                            </CursorPointerDiv>
                        }
                    </div>
                </div>
            </div>
            <div className="col-12 d-none d-md-block">
                <div className="row">
                    <PaddingDiv className="col-6 text-right">
                            <strong>{Language[props.currentLanguage].distributionIn}</strong>
                    </PaddingDiv>
                    <div className="col-6 text-left">
                        {
                            _.sortBy(countryLinks.filter((countryLink) => countryLink.Politischer_Schalter === 0), (link) => link.country?.Bezeichnung).map((countryLink, index) => {
                                if(
                                    countryLink.Land_ID !== 9999 && 
                                    !isNullOrUndefined(countryLink.country) && 
                                    Country[Object.keys(Country).find(key => Country[key].countryNo === countryLink.country.ID)].showCountry === true &&
                                    props.productGroup.contacts.find(countryContact => countryContact.Land_ID === countryLink.Land_ID) !== undefined &&
                                    (
                                        index < 3 ||
                                        isShowMoreCountriesActive
                                    )
                                    
                                ) {                                                        
                                    return (
                                        <DistributionCountry
                                            key={`productGroupCountryLink${countryLink.Auto_ID}`}
                                            countryLink={countryLink}
                                            currentLanguage={props.currentLanguage}
                                            contact={props.productGroup.contacts.find(countryContact => countryContact.Land_ID === countryLink.Land_ID)?.contact}
                                        />
                                    )
                                }
                            })
                        }
                        {
                            getNumberOfValidCountryLinks() > 3 && isShowMoreCountriesActive === false &&
                            <CursorPointerDiv onClick={(event) => setIsShowMoreCountriesActive(true)}>
                                {
                                    `...and ${getNumberOfValidCountryLinks() - 3} countries more. Click here to show all`
                                }
                            </CursorPointerDiv>
                        }
                    </div>   
                </div>
            </div>
        </div>
    )
}

export default DistributionIn;