import React, { Component } from 'react';
import TermsAndConditionsListEntry from './TermsAndConditionsListEntry.component';
import styled from 'styled-components';

const Div = styled.div`
    padding:1px;
`

const BackgroundWhite = styled.div`
    padding:5px;
    background-color:#fff;
    margin:5px;
    border-bottom: 1px solid #004839;
`

const CompanyHeader = styled.h4`
    font-size: 1.2rem;
`

class TermsAndConditionsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let lastCompany = '';
        return (
            <div className="container marginTop50px marginBottom50px">
                <div className="row dataContainer">
                    
                        {
                            this.props.termsAndConditions.map((termsAndCondition) => {
                                let currentCompany = termsAndCondition.company;
                                let documents = this.props.termsAndConditions.filter((document) => document.company === currentCompany);
                                if(termsAndCondition.company !== lastCompany) {
                                    lastCompany = termsAndCondition.company;
                                    return (
                                        <Div className="col-12 col-lg-6" key={"company" + lastCompany}>
                                                <BackgroundWhite>
                                                <div className="row marginTop25px justify-content-center">
                                                    <div className="col-12 text-center">
                                                        <CompanyHeader id={termsAndCondition.countryHash.toLowerCase()}>{ termsAndCondition.countryShow  + ", " + termsAndCondition.company }</CompanyHeader>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-auto text-center">
                                                    {
                                                        documents.map((document) => {
                                                            return (
                                                                <TermsAndConditionsListEntry key={"termsAndCondition" + document.language} currentLanguage={this.props.currentLanguage} termsAndCondition={document} />
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                                </BackgroundWhite>
                                        </Div>
                                    );
                                }
                                else {
                                    return undefined;
                                }
                            }).filter(document => document !== undefined)
                        }
                </div>
            </div>
        );
    }
}

export default TermsAndConditionsList;