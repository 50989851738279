import React, { useState, useEffect } from 'react';
import MainProductGroupList from './MainProductGroupList.container';
import CountrySelection from './CountrySelection.component';
import CategorySelectionForIndustries from './CategorySelectionForIndustries.component';
import Language from './Language.data';
import styled from 'styled-components';
import FocusBusinessSegment from './FocusBusinessSegment.component';
import * as Tracking from '../utils/matomo';
import { isNullOrUndefined } from '../utils/utils';
import IndustrySelection from './IndustrySelection.component';

const MainProductGroupListContainer = styled.div`
    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
    }

    @media (min-width: 992px) and (max-width:1200px) {
        padding-left:5vw;
        padding-right:5vw;
    }

    @media (min-width: 1200px) {
        padding-left:10vw;
        padding-right:10vw;
    }
` 

const CountrySelectionSticked = styled.div`
    position:sticky;
    top:72px;
    z-index:200;
    background-color:#fff;
`

function Industries(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [currentIndustry, setCurrentIndustry] = useState('');

    function handleBUChange(bu, shouldNotScroll = null) {
        Tracking.trackEvent('Industries','Click Industries - ' + props.currentIndustry + ' - ' + bu, 'Industry - ' + props.currentIndustry + ' - ' + bu);
        props.onBUChange(bu, null, shouldNotScroll);
    }

    function handleCountryChange(country) {
        props.onCountryChange(country);
    }

    function handleShowMainProductGroupDetailsChange(mainProductGroup) {
        if(!props.showMainProductGroupDetails.includes(mainProductGroup.id)) {
            Tracking.trackEvent('Industries','Click Industries - ' + props.currentIndustry + ' - ' + props.currentBU + ' - MPG: ' + mainProductGroup.name, 'Industry - ' + props.currentIndustry + ' - ' + props.currentBU + ' - MPG: ' + mainProductGroup.name);
        }
        props.onShowMainProductGroupDetailsChange(mainProductGroup);
    }

    function handleShowProductGroupDetailsChange(productGroup) {
        if(!props.showProductGroupDetails.includes(productGroup.productGroupID)) {
            Tracking.trackEvent('Industries','Click Industries - ' + props.currentIndustry + ' - ' + props.currentBU + ' - PG: ' + productGroup.productGroupName + ' (' + productGroup.productGroupID + ')', 'Industry - ' + props.currentIndustry + ' - ' + props.currentBU + ' - PG: ' + productGroup.productGroupName + ' (' + productGroup.productGroupID + ')');
        }
        props.onShowProductGroupDetailsChange(productGroup);
    }

    useEffect(() => {
        let currentIndustry = "";
        switch(props.currentIndustry) {
            case 'surfaceCoatings':
                currentIndustry = Language[props.currentLanguage].surfaceCoatings;
                break;
            case 'adhesivesAndSealants':
                currentIndustry = Language[props.currentLanguage].adhesivesAndSealants;
                break;
            case 'adhesives':
                currentIndustry = Language[props.currentLanguage].adhesives;
                break;               
            case 'printingInk':
                currentIndustry = Language[props.currentLanguage].printingInk;
                break;
            case 'paper':
                currentIndustry = Language[props.currentLanguage].paper;
                break;
            case 'construction':
                currentIndustry = Language[props.currentLanguage].construction;
                break;
            case 'plastics':
                currentIndustry = Language[props.currentLanguage].plastics;
                break;
            case 'rubber':
                currentIndustry = Language[props.currentLanguage].rubber;
                break;
            case 'pharma':
                currentIndustry = Language[props.currentLanguage].pharma;
                break;
            case 'personalCare':
                currentIndustry = Language[props.currentLanguage].personalCare;
                break;
            case 'miscellaneous':
                currentIndustry = Language[props.currentLanguage].miscellaneous;
                break;
            case 'agriculture':
                currentIndustry = Language[props.currentLanguage].agriculture;
                break;
            case 'sealants':
                currentIndustry = Language[props.currentLanguage].sealants;
                break;
            case 'moreIndustries':
                currentIndustry = Language[props.currentLanguage].moreIndustries;
                break;
            case 'energystorage':
                currentIndustry = Language[props.currentLanguage].energyStorage;
                break;
            default:
                break;
        }
        setCurrentIndustry(currentIndustry);
        if(window.location.pathname !== '/industries' && document.getElementById('focusBU')) {
            window.scroll({
                top: document.getElementById('focusBU').offsetTop - 120, 
                left: 0, 
                behavior: 'smooth'
            });
        }
    },[props.currentIndustry]);

    return (
        <div>
            <IndustrySelection
                currentLanguage={props.currentLanguage}
                currentIndustry={props.currentIndustry}
                onIndustryChange={(industry) => props.onIndustryChange(industry)}
            />
            <div id="focusBU">
            {
                props.currentIndustry !== '' &&
                    <FocusBusinessSegment
                        businessSegment={props.currentIndustry}
                        currentLanguage={props.currentLanguage}
                    />
            }
            </div>
            {
                props.currentIndustry === 'energystorage' &&
                <>
                    <div className="row marginTop50px">
                        <div className="col-12 text-center">
                            <a className="anchor"> </a>
                            <h3>{Language[props.currentLanguage].industry + " \"" + currentIndustry + "\""}</h3>
                        </div>
                    </div>
                    <div className="row marginTop50px">
                        <div className="col-12 text-center">
                            {
                                `${Language[props.currentLanguage].applications}:`
                            }
                            <br />
                            <ul style={{listStyle:'none'}}>
                                <li>{ Language[props.currentLanguage].industries.energyStorage.batteryCells }</li>
                                <li>{ Language[props.currentLanguage].industries.energyStorage.batteryModules }</li>
                                <li>{ Language[props.currentLanguage].industries.energyStorage.batteryRawMaterials }</li>
                                <li>{ Language[props.currentLanguage].industries.energyStorage.fuelCells }</li>
                                <li>{ Language[props.currentLanguage].industries.energyStorage.adjacentMarkets }</li>
                            </ul>
                            <br />
                            {
                                Language[props.currentLanguage].industries.energyStorage.text
                            }
                        </div>
                    </div>
                </>
            }
            {
                props.currentIndustry !== 'energystorage' &&
                <MainProductGroupListContainer>
                    { 
                        props.showMainProductGroups && 
                        <div>
                            <div className="row marginTop50px">
                                <div className="col-12 text-center">
                                    <a className="anchor"> </a>
                                    <h3>{Language[props.currentLanguage].industry + " \"" + currentIndustry + "\""}</h3>
                                </div>
                            </div>
                            <CountrySelectionSticked>
                                <div className="row marginTop50px backgroundColorWhite">
                                    <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3 text-center">
                                        <CountrySelection showSpacing={false} currentLanguage={props.currentLanguage} country={props.country} onCountryChange={handleCountryChange} />
                                    </div>
                                </div>
                            </CountrySelectionSticked>
                            {
                                props.isLoadingMainProductGroups === false && !isNullOrUndefined(props.mainProductGroups) && props.mainProductGroups.length === 0 && !isNullOrUndefined(props.mainProductGroupsForIndustry) && props.mainProductGroupsForIndustry.length === 0 &&
                                <div className="row marginTop50px">
                                    <div className="col-12 text-center">
                                        {
                                            Language[props.currentLanguage].noProductsToDisplay
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !props.country.productGroupInfosNotAvailable &&
                                <div className="row marginTop25px marginBottom50px">
                                    <div className="col-12 text-center">
                                        <CategorySelectionForIndustries 
                                            showBUAdditives={props.showBUAdditives} 
                                            showBUBinders={props.showBUBinders} 
                                            showBUFillers={props.showBUFillers} 
                                            showBUPigments={props.showBUPigments} 
                                            currentLanguage={props.currentLanguage} 
                                            isLoading={isLoading} 
                                            currentBU={props.currentBU} 
                                            currentIndustry={props.currentIndustry} 
                                            mainProductGroupsForIndustry={props.mainProductGroupsForIndustry} 
                                            onBUChange={handleBUChange} 
                                        />
                                    </div>
                                </div>
                            }
                            {
                                props.currentBU !== "" && !isNullOrUndefined(props.mainProductGroups) && props.mainProductGroups.length > 0 &&
                                <div id="mainProductGroups">
                                    <MainProductGroupList 
                                        hideHeader={props.country.productGroupInfosNotAvailable ? true : false} 
                                        isLoading={props.isLoading} 
                                        isLoadingMainProductGroups={props.isLoadingMainProductGroups}
                                        currentLanguage={props.currentLanguage} 
                                        contacts={props.contacts} 
                                        country={props.country} 
                                        currentBU={props.currentBU} 
                                        mainProductGroups={props.mainProductGroups} 
                                        showMainProductGroupDetails={props.showMainProductGroupDetails} 
                                        onShowMainProductGroupDetailsChange={handleShowMainProductGroupDetailsChange} 
                                        showProductGroupDetails={props.showProductGroupDetails} 
                                        onShowProductGroupDetailsChange={handleShowProductGroupDetailsChange} 
                                    />
                                </div>
                            }
                        </div>
                    }	
                </MainProductGroupListContainer>
            }
        </div>
    );
}

export default Industries;