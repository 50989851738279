import React from 'react';
import styled from 'styled-components';

const ContainerDiv = styled.div`
  margin-top:${props => props.marginTop ? props.marginTop : '50px'};
  margin-bottom:${props => props.marginBottom ? props.marginBottom : '50px'};
`

function Divider(props) {
  return (
    <ContainerDiv marginTop={props.marginTop} marginBottom={props.marginBottom} className="container">
      <div className="row">
        <div className="col-12">
            <hr />
        </div>
      </div>
    </ContainerDiv>
  );
};

export default Divider;