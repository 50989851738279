import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ExternalLink from './ExternalLink.component';
import instagram from '../images/socialmedia_instagram.png';
import linkedin from '../images/socialmedia_linkedin.png';
import facebook from '../images/socialmedia_facebook.png';

const Row = styled.div`
    padding-top:0.7rem;
    padding-bottom:0.7rem;
`

function SocialMediaLinksInFixedRightBarContent(props) {
    return (
        <>
            <Row className="row text-center">
                <div className="col">
                    <ExternalLink
                        {...props}
                        key={"socialMediaBarFacebook"}
                        id={"socialMediaBarFacebook"}
                        linkHrefTag={"https://www.facebook.com/grolmangroup" }
                        linkTargetTag={"_blank"}
                        linkAdditionalClasses={"footerLink"}
                        imageSrcTag={facebook}
                        imageAltTag={"Follow us on Facebook"}
                        imageHeight={"35px"}
                        iconWidth={"16px"}
                        iconHeight={"16px"}
                        iconPositonRight={"-10px"}
                        iconPositonBottom={"-10px"}
                        maskColor={"#fff"}
                    />
                </div>
            </Row>
            <Row className="row text-center">
                <div className="col">
                    <ExternalLink
                        {...props}
                        key={"socialMediaBarInstagram"}
                        id={"socialMediaBarInstagram"}
                        linkHrefTag={"https://www.instagram.com/grolmangroup/" }
                        linkTargetTag={"_blank"}
                        linkAdditionalClasses={"footerLink"}
                        imageSrcTag={instagram}
                        imageAltTag={"Follow us on Instagram"}
                        imageHeight={"35px"}
                        iconWidth={"16px"}
                        iconHeight={"16px"}
                        iconPositonRight={"-10px"}
                        iconPositonBottom={"-10px"}
                        maskColor={"#fff"}
                    />
                </div>
            </Row>
            <Row className="row text-center">
                <div className="col">
                    <ExternalLink
                        {...props}
                        key={"socialMediaBarLinkedIn"}
                        id={"socialMediaBarLinkedIn"}
                        linkHrefTag={"https://www.linkedin.com/company/grolmangroup" }
                        linkTargetTag={"_blank"}
                        linkAdditionalClasses={"footerLink"}
                        imageSrcTag={linkedin}
                        imageAltTag={"Follow us on LinkedIn"}
                        imageHeight={"35px"}
                        iconWidth={"16px"}
                        iconHeight={"16px"}
                        iconPositonRight={"-10px"}
                        iconPositonBottom={"-10px"}
                        maskColor={"#fff"}
                    />
                </div>
            </Row>
        </>
    )
}

export default SocialMediaLinksInFixedRightBarContent;