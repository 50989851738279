import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import $ from 'jquery'
import Language from './Language.data';
require('bootstrap');

const video = require('../videos/backgroundVideo.mp4').default;
//const video = React.lazy(() => import('../videos/backgroundVideo.mp4'));

const VideoBackground = styled.div`
    position: absolute;
    top: 100px;
    left: 0px;
    width: 100%;
    overflow:hidden;
    background-color:transparent;

    @media (max-width: 576px) { 
        position: absolute;
        top: -15%;
        left: -75%;
        width: 200%;
    }
`

const ColorOverlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: lightblue;
    width: 100%;
    height: 100vh;
    opacity: 0;
`

const GGText = styled.div`
    font-size:large;

    @media (min-width: 576px) and (max-width: 992px) { 
        font-size:medium;
    }

    @media (max-width: 576px) { 
        font-size:unset;
    }
`

const BoldText = styled.div`
    font-weight:bold;
`

const Height = styled.div`
    height: fit-content; /*calc(100vh - 100px);*/
    min-height:100vh;
`

const Background = styled.div`
    background-color:#016686;
    width: 100%;
    height: 100vh;
    padding:0px;
    margin:0px;
    position:absolute;
    top:0px;
    left:0px;
    overflow: hidden;
`

const ContainerWithoutBackgroundColor = styled.div`
    background-color:transparent;
` 

const DivWithWhiteBackground = styled.div`
    background-color:#fff;
    border-radius:0.5rem;
    padding:1rem;
    opacity:0.8;
    margin-left:2rem;

    @media (max-width: 576px) { 
        margin-left:unset;
    }
` 

const MarginTop4Rem = styled.div`
    margin-top:12rem;

    @media (min-width: 576px) and (max-width: 992px) { 
        margin-top:6rem;
    }

    @media (min-width: 992px) and (max-width: 1127px) { 
        margin-top:4rem;
    }


    @media (min-width: 1128px) and (max-width: 1320px) { 
        margin-top:6rem;
    }

    @media (max-height: 576px) { 
        margin-top:1rem; 
    }

    @media (max-width: 576px) { 
        margin-top:1rem; 
    }
`

function StartPageVideoBackground(props) {

    useEffect(() => {
        async function initialize() {
            setVideoLayerHeight();

            /*
            window.setTimeout(() => {
                $('#grolmanGroupStartText').fadeIn(500);
            },100);
            */
        }

        initialize();

    },[]);

    function setVideoLayerHeight() {
        let videoBackgroundElement = document.getElementById('backgroundVideo');
        let backgroundVideoTextLayer = document.getElementById('backgroundVideoTextLayer');
        let backgroundVideoContainer = document.getElementById('backgroundVideoContainer');
        if(videoBackgroundElement !== undefined) {
            videoBackgroundElement.onloadeddata = function() {
                let maxHeight = Math.max(videoBackgroundElement.offsetHeight,window.screen.height);
                if(backgroundVideoTextLayer !== undefined) {
                    backgroundVideoTextLayer.style.height = (maxHeight - 100) + "px";
                }
                if(backgroundVideoContainer.offsetHeight < videoBackgroundElement.offsetHeight) {
                    backgroundVideoContainer.style.height = videoBackgroundElement.offsetHeight + "px";
                }
            };
        }
    }

    return (
        <div id="video">
            <Background id="backgroundVideoContainer" key="background">
                <VideoBackground>
                    <ColorOverlay className="color-overlay"></ColorOverlay>
                    <figure>
                        <video autoPlay muted id="backgroundVideo">
                            <source src={video} type="video/mp4" />
                        </video>
                    </figure>
                </VideoBackground>
            </Background>
            <Height id="backgroundVideoTextLayer" className="container-fluid">
                <div className="row"> 
                    <Height className="col-12">
                        <ContainerWithoutBackgroundColor className="container-fluid">
                            <MarginTop4Rem className="row">
                                <div className="offset-0 offset-sm-0 offset-md-0 offset-lg-7"></div>
                                <DivWithWhiteBackground id="grolmanGroupStartText" className="col col-lg-4">
                                    <GGText>
                                        <BoldText>
                                            {Language[props.currentLanguage].theGrolmanGroup}
                                        </BoldText>
                                        {Language[props.currentLanguage].companyText1}
                                        <br />
                                        {Language[props.currentLanguage].companyText2}
                                        <br />
                                        <br />
                                        <BoldText>{Language[props.currentLanguage].grolmanQualitySince1855}</BoldText>
                                    </GGText>
                                </DivWithWhiteBackground>
                                <div className="offset-0 offset-sm-0 offset-md-0 offset-lg-1"></div>
                            </MarginTop4Rem>
                        </ContainerWithoutBackgroundColor>
                    </Height>
                </div>
            </Height>
        </div>
    );
}

export default StartPageVideoBackground;