import React from 'react';
import Language from '../../Language.data.js';
import Link from '../../Link.component';

function Twitter(props) {
    return (
        <>
            <div className="row mt-2" id="twitter">
                <div className="col text-justify font-weight-bold">
                    {
                        Language[props.selectedLanguage].privacyTexts.twitter.twitter
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.twitter.twitterText1
                    }
                    <Link 
                        linkUrl="https://twitter.com/privacy" 
                        target="_blank"
                    />
                    {
                        Language[props.selectedLanguage].privacyTexts.twitter.twitterText2
                    }
                    <Link 
                        linkUrl="https://twitter.com/personalization" 
                        target="_blank"
                    />
                </div>
            </div>
        </>
    )
}

export default Twitter;