import React from 'react';
import styled from 'styled-components';
import Language from '../Language.data.js';

const PrivacyDataListItem = styled.li`
    width: fit-content;
`

function Rights(props) {
    return (
        <>
            <div className="row mt-5">
                <div className="col text-center">
                    <h4>
                        {
                            Language[props.selectedLanguage].privacyTexts.yourRights
                        }
                    </h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.yourRightsText1
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-center d-flex justify-content-start">
                    <ul>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.right1}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.right2}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.right3}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.right4}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.right5}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.right6}</PrivacyDataListItem>
                    </ul>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.yourRightsText2
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.yourRightsText3
                    }
                </div>
            </div>
        </>
    )
}

export default Rights;