import React from 'react';
import Language from '../../Language.data.js';
import Link from '../../Link.component';

function Instagram(props) {
    return (
        <>
            <div className="row mt-2" id="instagram">
                <div className="col text-justify font-weight-bold">
                    {
                        Language[props.selectedLanguage].privacyTexts.instagram.instagram
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.instagram.instagramText1
                    }
                    <Link 
                        linkUrl="https://instagram.com/about/legal/privacy" 
                        target="_blank"
                    />.
                </div>
            </div>
        </>
    )
}

export default Instagram;