import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import $ from "jquery";
import Language from './Language.data';
import Country from './Country.data';
import * as Tracking from '../utils/matomo';
import styled, { keyframes } from 'styled-components';
import { isNullOrUndefined } from '../utils/utils';
import { ImageColumn } from '../styles/Image.styles';
var _ = require('lodash');

// *** IMPORT IMAGES ***
// Country Icons from https://www.iconfinder.com/iconsets/flags_gosquared
import logo from '../images/GrolmanLogo_240x75.webp';
import searchIcon from '../images/search.svg';

const decreaseHeight = keyframes`
    0% {
        height: 75px;
		width: 240px;
    }
    100% {
        height: 45px;
		width: 144px;
    }
`;

const increaseHeight = keyframes`
    0% {
        height: 45px;
		width: 144px;
    }
    100% {
        height: 75px;
		width: 240px;
    }
`;

const showSearchTextField = keyframes`
    0% {
		width: 0px;
    }
    100% {
		width: 200px;
    }
`;

const hideSearchTextField = keyframes`
    0% {
		width: 200px;
    }
    100% {
		width: 0px;
    }
`;

const NavBarContainer = styled.div`
	border-radius:unset;

	&:hover {
	}
`

const Logo = styled.img`
	animation-fill-mode: forwards;
	animation-duration: 0.25s;
    animation-name: ${props => !isNullOrUndefined(props.isNavBarSmall) && props.isNavBarSmall === true ? decreaseHeight : increaseHeight};
`

const SearchTextInputField = styled.input`
	visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
	animation-fill-mode: forwards;
	animation-duration: 0.25s;
    animation-name: ${props => props.isVisible ? showSearchTextField : hideSearchTextField};
	margin-right:0.5rem;
`

function Navigation(props) {

	const [isNavBarSmall, setIsNavBarSmall] = useState(false);
	const [isSearchTextFieldVisible, setIsSearchTextFieldVisible] = useState(false);
	const searchRef = useRef();

	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener("scroll", onScroll);
		return () => {
            window.removeEventListener("scroll", onScroll);
        }
	},[]);

	useEffect(() => {
		if(isSearchTextFieldVisible) {
			searchRef.current.focus();
		}
	},[isSearchTextFieldVisible])

	function onScroll(event) {
		let scroll = this.scrollY;
		if(scroll > 100) {
			setIsNavBarSmall(true);
		}
		else {
			setIsNavBarSmall(false);
		}
		//console.log('Scrolling Y:', scroll);
	}

	const industriesList = _.sortBy([
		{
			name: Language[props.currentLanguage].surfaceCoatings.toUpperCase(),
			industry: 'surfaceCoatings',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].adhesives.toUpperCase(),
			industry: 'adhesives',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].printingInk.toUpperCase(),
			industry: 'printingInk',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].sealants.toUpperCase(),
			industry: 'sealants',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].construction.toUpperCase(),
			industry: 'construction',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].plastics.toUpperCase(),
			industry: 'plastics',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].paper.toUpperCase(),
			industry: 'paper',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].rubber.toUpperCase(),
			industry: 'rubber',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].moreIndustries.toUpperCase(),
			industry: 'moreIndustries',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].personalCare.toUpperCase(),
			industry: 'personalCare',
			showLink: true,
			link: 'https://www.grolman-personalcare.com',
		},
		{
			name: Language[props.currentLanguage].battery.toUpperCase(),
			industry: 'battery',
			showLink: true,
			link: '//grolman-energystorage.com',
		},
	],'name');
	
	const buList = _.sortBy([
		{
			name: Language[props.currentLanguage].businessUnits.additives.additives.toUpperCase(),
			bu: 'additives',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].businessUnits.binders.binders.toUpperCase(),
			bu: 'binders',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.fillers.toUpperCase(),
			bu: 'fillers',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].businessUnits.pigmentsAndDyes.pigments.toUpperCase(),
			bu: 'pigments',
			showLink: false,
			link: '',
		},
		{
			name: Language[props.currentLanguage].personalCare.toUpperCase(),
			bu: 'personalCare',
			showLink: true,
			link: 'https://www.grolman-personalcare.com',
		}
	],'name');
	
	const countryList = _.sortBy(Country,(c) => { return _.deburr(c.name[props.currentLanguage]) });

	function scrollToTop() {
		window.scrollTo({top: 0, behavior: 'smooth'});
		hideMenu();
	}
	
	function hideMenu() {
		$('#navbarSupportedContent').removeClass('show');
	}
	
    function handleClickLanguage(event, language) {
		Tracking.trackEvent('General','Click Language ' + language,'Menu Language ' + language);
		props.onLanguageChange(language);
	}
	
	function handleClickBU(event, bu) {
		props.onBUChange(bu,'products');
		hideMenu();
		Tracking.trackEvent('General','Click Menu Products','Menu Products ' + bu);
	}

	function handleClickIndustry(event, industry) {
		props.onIndustryChange(industry);
		hideMenu();
		Tracking.trackEvent('General','Click Menu Industry','Menu Industry ' + industry);
	}
	
	function handleClickCountry(event, country) {
		props.onCountryChange(country);
		hideMenu();
		Tracking.trackEvent('General','Click Menu Locations','Menu Locations ' + country);
	}

	function handleClickCompany() {
		Tracking.trackEvent('General','Click Menu Company','Menu Company Company');
		hideMenu();
	}

	function handleClickHistory() {
		Tracking.trackEvent('General','Click Menu Company','Menu Company History');
		hideMenu();
	}

	function handlePersonalCareLinkClick() {
		Tracking.trackEvent('General','Click Menu Products','Menu Products - Personal Care');
	}

	function handleClickLogo() {
		Tracking.trackEvent('General','Click Menu Logo','Menu Logo');
		scrollToTop();
	}

	function handleClickJobs() {
		Tracking.trackEvent('General','Click Menu Jobs','Menu Jobs');
		scrollToTop();
	}

	function handleClickLab() {
		Tracking.trackEvent('General','Click Menu Laboratory','Menu Laboratory');
	}

	function handleEarthClick() {
		Tracking.trackEvent('General','Click Menu Vision & Sustainability','Menu Vision & Sustainability');
	}

	function handleSearchSubmit(event) {
		event.preventDefault();
		let searchText = searchRef.current.value;
		navigate("/search/" + searchText);
		document.getElementById("hamburgerButton").click();
	}

	return (
		<div>
			<NavBarContainer 
				className="container-fluid fixed-top bg-light" 
				id="nav"
			>
				<div className="row">
					<div className="col-12 borderBottom1pxSolid888">
						<nav className="navbar navbar-expand-xl navbar-light text-center">			
							<Link 
								to="/" 
								className="navbar-brand" 
								onClick={handleClickLogo} 
								key={"navLogoKey"}
							>
								<Logo 
									id="ggSiteLogo" 
									src={logo} 
									className="img-fluid" 
									alt="Logo" 
									isNavBarSmall={isNavBarSmall}
								/>
							</Link>
							<button 
								id="hamburgerButton"
								className="navbar-toggler" 
								type="button" 
								data-toggle="collapse" 
								data-target="#navbarSupportedContent" 
								aria-controls="navbarSupportedContent" 
								aria-expanded="false" 
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon"></span>
							</button>

							<div className={"collapse navbar-collapse paddingTop45px ml-3"} id="navbarSupportedContent">
								<ul className="navbar-nav mr-auto">
									<li className="nav-item dropdown active whiteSpaceNoWrap">
										<a className="nav-link dropdown-toggle imageLink animated" id="navbarDropdownCompany" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{Language[props.currentLanguage].menu.company.toUpperCase()}
										</a>
										<div className="dropdown-menu whiteSpaceNoWrap" aria-labelledby="navbarDropdownCompany">
											<Link 
												to="/company" 
												className="nav-link imageLink animated" 
												key={"navCompanyKey"} 
												onClick={handleClickCompany}
											>
												{Language[props.currentLanguage].menu.company.toUpperCase()}
											</Link>
											<Link 
												to="/history" 
												className="nav-link imageLink animated" 
												key={"navHistoryKey"} 
												onClick={handleClickHistory}
											>
												{Language[props.currentLanguage].menu.history.toUpperCase()}
											</Link>
											<Link 
												to="/jobs" 
												className="nav-link imageLink animated" 
												onClick={handleClickJobs} 
												key={"navJobsKey"}
											>
												{Language[props.currentLanguage].menu.jobs.toUpperCase()}
											</Link> 
											<a 
												className="nav-link imageLink animated" 
												rel="noopener noreferrer" 
												href="https://lab.grolman-group.com" 
												onClick={handleClickLab} target="_blank"
											>
												{Language[props.currentLanguage].menu.lab.toUpperCase()}
											</a>
											<a 
												className="nav-link imageLink animated" 
												rel="noopener noreferrer" 
												href="https://grolman.earth" 
												target="_blank" 
												onClick={handleEarthClick}
											>
												{Language[props.currentLanguage].menu.visionAndSustainability.toUpperCase()}
											</a>
										</div>
									</li>
									
									<li className="nav-item active dropdown whiteSpaceNoWrap">
										<div className="nav-link dropdown-toggle imageLink animated cursorPointer" href="#" id="navbarDropdownProducts" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{Language[props.currentLanguage].menu.products.toUpperCase()}
										</div>
										<div className="dropdown-menu whiteSpaceNoWrap" aria-labelledby="navbarDropdownProducts">
											{
												buList.map((bu) => {
													if(bu.showLink) {
														return (
															<a 
																key={"navigation" + bu.name + "Key"} 
																className="nav-link imageLink animated" 
																rel="noopener noreferrer" 
																onClick={handlePersonalCareLinkClick} 
																href={bu.link} 
																target="_blank"
															>
																{bu.name}
															</a>
														);
													}
													else {
														return (
															<Link 
																key={`navigation${bu.name}Key`} 
																to={`/products/${bu.bu}`} 
																className="nav-link dropdown-item imageLink animated" 
																onClick={(e) => handleClickBU(e, bu.bu)}
															>
																{bu.name}
															</Link>
														);
													}	
												})
											}	
										</div>
									</li>
									<li className="nav-item active dropdown whiteSpaceNoWrap">
										<div className="cursorPointer nav-link dropdown-toggle imageLink animated" id="navbarDropdownIndustries" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{Language[props.currentLanguage].menu.industries.toUpperCase()}
										</div>
										<div className="dropdown-menu whiteSpaceNoWrap" aria-labelledby="navbarDropdownIndustries">
											{
												industriesList.map((industry) => {
													if(industry.showLink) {
														return (
															<a 
																key={`navigation${industry.name}Key`} 
																className="nav-link imageLink animated" 
																rel="noopener noreferrer" 
																href={industry.link} 
																target="_blank"
															>
																{industry.name}
															</a>
														);
													}
													else {
														return (
															<Link 
																key={`navigation${industry.name}Key`} 
																to={`/industries/${industry.industry}`} 
																className="nav-link dropdown-item imageLink animated" 
																onClick={(e) => handleClickIndustry(e, industry.industry)}
															>
																{industry.name}
															</Link>
														);
													}	
												})
											}
										</div>
									</li>
									<li className="nav-item active dropdown whiteSpaceNoWrap">
										<a className="nav-link dropdown-toggle imageLink animated" id="navbarDropdownCountry" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{
												props.currentCountry != null && props.currentCountry !== 'grolmanGroup' &&
												<span className={`mr-1 flagSelect fi ${Country[props.currentCountry].flag}`} />
											}
											{props.currentCountry != null && props.currentCountry !== 'grolmanGroup' ? Country[props.currentCountry].name[props.currentLanguage].toUpperCase() : Language[props.currentLanguage].locations.toUpperCase()}
										</a>
										<div className="dropdown-menu overflowYScrollHeight400px" aria-labelledby="navbarDropdownCountry">
											<HashLink 
												to="/products" 
												className={"dropdown-item imageLink animated"} 
												onClick={(e) => handleClickCountry(e, "grolmanGroup")} 
												key={"navgrolmanGroup"}
											>
												{Country["grolmanGroup"].name[props.currentLanguage].toUpperCase()}
											</HashLink> 
											{
												countryList.map((countryElement) => {
													if(countryElement.showCountry === true && countryElement.key !== "grolmanGroup") {
														return (
															<HashLink 
																to="/products" 
																className={"dropdown-item imageLink animated"}
																onClick={(e) => handleClickCountry(e, countryElement.key)} 
																key={"nav" + countryElement.key}
															>
																<span className={`mr-1 flagSelect fi ${countryElement.flag}`} />
																{countryElement.name[props.currentLanguage].toUpperCase()}
															</HashLink> 
														);
													}
													else {
														return undefined;
													}
												}).filter(country => country !== undefined)
											}
										</div>
									</li>
								</ul>
								<span className="navbar-text text-center fontSizeXSmall">
									<form onSubmit={(event) => handleSearchSubmit(event)}>
										<div className="input-group">
											<span 
												className="input-group-text" 
												id="searchIcon"
												onClick={(event) => {
													setIsSearchTextFieldVisible(!isSearchTextFieldVisible);
												}}
											>
												<ImageColumn
													backgroundImage={searchIcon}
													imageSize={"20px"}
												/>
											</span>
											<SearchTextInputField 
												isVisible={isSearchTextFieldVisible}
												type="text" 
												className="form-control" 
												placeholder="Search" 
												aria-label="Search" 
												aria-describedby="searchIcon"
												ref={searchRef}
												onBlur={(event) => setIsSearchTextFieldVisible(false)}
											/>
										</div>
									</form>
								</span>
								<span className="navbar-text text-center fontSizeXSmall">
									LANGUAGE<br />
									{
										props.languagesToShow.map((language) => {
											return (
												<div 
													key={`${language}Selection`} 
													className={`cursorPointer flagSelect fi fi-${Language[language].flag}`} 
													onClick={(e) => {
														handleClickLanguage(e, language);
														document.getElementById("hamburgerButton").click();
													}} 
													alt={Language[language].languageName}
												/>
											);
										})
									}
								</span>
							</div>
						</nav>
					</div>
				</div>
			</NavBarContainer>
			<div className="navigation">
			</div>
		</div>
	);
}

export default Navigation;