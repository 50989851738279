import React from 'react';
import Language from '../Language.data.js';
import Facebook from './socialMedia/Facebook.component';
import Twitter from './socialMedia/Twitter.component';
import Instagram from './socialMedia/Instagram.component';
import LinkedIn from './socialMedia/LinkedIn.component';

function DataProcessingSocialNetworks(props) {
    return (
        <>
            <div className="row mt-5">
                <div className="col text-center">
                    <h4>
                        {
                            Language[props.selectedLanguage].privacyTexts.furtherDataProcessingInInternetPresencesOnSocialNetworks
                        }
                    </h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText1
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText2
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText3
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText4
                    }
                    <br />
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText5
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText6
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText7
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText8
                    }
                    <br />
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.socialNetworksText9
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.furtherInformationAboutSocialNetworks
                    }
                </div>
            </div>
            <Facebook 
                key={"privacyFacebook"}
                selectedLanguage={props.selectedLanguage}
            />
            <Twitter
                key={"privacyTwitter"}
                selectedLanguage={props.selectedLanguage}
            />
            <Instagram
                key={"privacyInstagram"}
                selectedLanguage={props.selectedLanguage}
            />
            <LinkedIn
                key={"privacyLinkedIn"}
                selectedLanguage={props.selectedLanguage}
            />
        </>
    )
}

export default DataProcessingSocialNetworks;