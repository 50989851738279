import React, { Component } from 'react';
import styled from 'styled-components';
import * as Tracking from '../utils/matomo';
import { v4 as uuidv4 } from 'uuid';

const EmailLink = styled.a`
    white-space: nowrap;
`

class EmailAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleClick(event) {
        event.preventDefault();
        Tracking.trackEvent('Contact','Click Contact E-Mail', 'Contact E-Mail - ' + this.props.emailAddress);
        document.location.href = "mailto:" + this.props.emailAddress;
    }

    render() {
        let displayAddress = '';
        if(this.props.emailAddress !== undefined) {
            displayAddress = this.props.emailAddress.replace('@','<span>(at)</span>').replace('.','<span class="displayNone">' + uuidv4() + '</span>.');
        }
        return (
            <EmailLink 
                href={""} 
                className={this.props.classNames} 
                style={this.props.additionalStyles}
                onClick={this.handleClick.bind(this)}
            >
                <span dangerouslySetInnerHTML={{__html: displayAddress}} />
            </EmailLink>
        );
    }
}

export default EmailAddress;