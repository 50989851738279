import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Switch from "react-switch";
import Language from './Language.data';
import * as Cookies from '../utils/cookies';
import * as Tracking from '../utils/matomo';
require('bootstrap');

const OptOutBackgroundLayer = styled.div`
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background-color: rgb(14, 14, 14);
    opacity: 0.6;
    z-index:10000;
`

const OptOutDiv = styled.div`
    position:absolute;
    top:3%;
    width: 500px;
    left:50%;
    margin-left:-250px;
    background-color: #fff; /*#1e80a0;*/
    color:#000;/*white;*/
    border-radius: 5px;
    z-index:10001;
    padding: 1rem;

    &:empty {
        
    }

    @media (max-width: 576px) {
        width: 300px;
        margin-left:-150px;
    }
` 

const AcceptButton = styled.button`
    background-color:#1e80a0;
    color:#fff;

    &:hover {
        text-decoration:underline;
        color:#fff;
    }
`

const ChangePrivacyOptions = styled.button`
    &:hover {
        text-decoration:underline;
    }
`

const OptOutHeader = styled.div`
    font-weight: bold;
`

const MarginTop1rem = styled.div`
    margin-top:1rem;
`

const PaddingLeftRight2rem = styled.div`
    padding-left:2rem;
    padding-right:2rem;
`

const SwitchDiv = styled.div`
    padding-left:2rem;
    padding-right:2rem;
    display: flex;
    align-items: center;
`

const PaddingLeftSpan05rem = styled.span `
    padding-left:0.5rem;
`

const BoldText = styled.div`
    font-weight:bold;
`

const CloseButton = styled.button`
    font-size: small;
    color: #868585;
    position: relative;
    top: -5px;
`

class PrivacyOptOut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optOutOptions: props.optOutOptions,
            showOptOut: (props.optOutOptions && props.optOutOptions.showOptOut) ? props.optOutOptions.showOptOut : (window.localStorage.getItem('showOptOutWindow') === "false") ? false : true,
            optOutLanguage: (props.optOutOptions && props.optOutOptions.showOptOut) ? props.optOutOptions.language : props.currentLanguage,
            showPrivacyOptions: false,
            allowTracking: (window.localStorage.getItem('allowTracking') === "true") ? true : false,
        };

        this.setLocalStorageSettings = this.setLocalStorageSettings.bind(this);
        this.outputReference = React.createRef();
    }

    componentDidMount() {
        if(window.localStorage.getItem('allowTracking') === "true") {
            this.activateTracking(true);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.optOutOptions !== undefined) {
            if( 
                (prevProps.optOutOptions === undefined && this.props.optOutOptions !== undefined) 
                || 
                (prevProps.optOutOptions !== undefined && this.props.optOutOptions !== undefined && prevProps.optOutOptions.showOptOut !== this.props.optOutOptions.showOptOut)
            ) {
                this.setState({
                    showOptOut: this.props.optOutOptions.showOptOut,
                    optOutLanguage: this.props.optOutOptions.language
                },() => {
                    window.scrollTo(0,0);
                });
            }
            if((prevProps.optOutOptions === undefined && this.props.optOutOptions !== undefined) || (prevProps.optOutOptions !== undefined && this.props.optOutOptions !== undefined && prevProps.optOutOptions.language !== this.props.optOutOptions.language)) {
                this.setState({
                    optOutLanguage: this.props.optOutOptions.language
                });
            }
        }
        if(prevProps.hideOptOutTemporarily !== this.props.hideOptOutTemporarily) {
            if(this.props.hideOptOutTemporarily === false) {
                this.setState({
                    showOptOut: this.props.optOutOptions.showOptOut
                },() => {
                    window.scrollTo(0,0);
                });
            }
        }
        if((this.props.optOutOptions === undefined || prevProps.optOutOptions === undefined || this.props.optOutOptions === prevProps.optOutOptions) && this.props.currentLanguage !== prevProps.currentLanguage) {
            this.setState({
                optOutLanguage: this.props.currentLanguage
            });
        }
    }

    componentDidCatch(error, info) {

    }

    onSaveChangesClick(event) {
        event.preventDefault();
        this.setLocalStorageSettings(this.state.allowTracking);
        this.setState({
            showOptOut: false
        });
        window.localStorage.setItem('showOptOutWindow',false);
        this.props.onShowOptOutOption(undefined);
    }

    onChangePrivacyOptions() {
        this.setState({
            showPrivacyOptions: true
        });
    }

    onAcceptButtonClick() {
        this.setLocalStorageSettings(true);
        this.activateTracking(true);
        window.localStorage.setItem('showOptOutWindow',false);
        this.setState({
            showOptOut: false,
        });
        this.props.onShowOptOutOption(undefined);
    }

    
    onDenyButtonClick() {
        this.setLocalStorageSettings(false);
        this.activateTracking(false);
        window.localStorage.setItem('showOptOutWindow',false);
        this.setState({
            showOptOut: false,
        });
        this.props.onShowOptOutOption(undefined);
    }


    activateTracking(trackingActive) {
        Tracking.addMatomoScriptTag(trackingActive);
    }

    deactivateTracking() {
        Tracking.removeMatomoScriptTag()
        .then((result) => {
            Cookies.deleteAllCookies();
        });
    }

    setLocalStorageSettings(allowTracking) {
        window.localStorage.setItem('allowTracking',allowTracking);
        this.setState({
            allowTracking: allowTracking
        });
    }

    onAllowTrackingCheckboxChanged(newValue) {
        if(newValue) {
            this.activateTracking(true); 
        }
        else {
            this.deactivateTracking();
            window._paq = undefined;
        }
        this.setLocalStorageSettings(newValue);
    }

    onShowPrivacyStatementClick() {
       this.props.onHideOptOutTemporarily(true);
    }

    doNothing() {}

    render() {
        return (
            <div>           
                {            
                    this.state.showOptOut && this.props.hideOptOutTemporarily === false &&
                    <div>
                        <OptOutBackgroundLayer key="optOutBackgroundLayer" />
                        <OptOutDiv key={"statusMessageDiv"} id={"statusMessageDiv"} className="statusMessageDiv" ref={this.outputReference}>
                            <div className="row d-flex align-items-center">
                                <div className="col">
                                    <CloseButton type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.onDenyButtonClick.bind(this)}>
                                        <span aria-hidden="true">&times;</span>
                                    </CloseButton>
                                    {
                                        this.state.showPrivacyOptions === false &&
                                        <div>
                                            <div className="row">
                                                <BoldText className="col text-center">
                                                    {
                                                        Language[this.state.optOutLanguage].optOut.dataPrivacyInformation
                                                    }
                                                </BoldText>
                                            </div>
                                            <div className="row">
                                                <div className="col text-center">
                                                    {
                                                        Language[this.state.optOutLanguage].optOut.weUseCookiesNote + ' '
                                                    }
                                                    <Link to="/privacy" className="optOutPrivacyLink" onClick={this.onShowPrivacyStatementClick.bind(this)}>{ Language[this.state.optOutLanguage].optOut.linkToPrivacyStatement }</Link>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col text-center">
                                                    <ChangePrivacyOptions className="btn btn-light" onClick={this.onChangePrivacyOptions.bind(this)}>{Language[this.state.optOutLanguage].optOut.changePrivacySettings}</ChangePrivacyOptions>
                                                </div>
                                                <div className="col text-center">
                                                    <AcceptButton className="btn" onClick={this.onAcceptButtonClick.bind(this)}>{Language[this.state.optOutLanguage].optOut.acceptAllCookies}</AcceptButton>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.showPrivacyOptions === true &&
                                        <div>
                                            <div className="row">
                                                <PaddingLeftRight2rem className="col">
                                                    <OptOutHeader>
                                                        { Language[this.state.optOutLanguage].optOut.tracking }
                                                    </OptOutHeader>
                                                </PaddingLeftRight2rem>
                                            </div>
                                            <div className="row">
                                                <PaddingLeftRight2rem className="col">
                                                        { Language[this.state.optOutLanguage].optOut.matomoCookieAndTrackingExplanation }
                                                        { 
                                                        /*
                                                            Language[this.state.optOutLanguage].optOutLinkToPrivacy }
                                                            <Link to="/privacy" className="optOutPrivacyLink" onClick={this.onShowPrivacyStatementClick.bind(this)}>{ Language[this.state.optOutLanguage].optOutLinkToPrivacyLinkText }</Link>
                                                        */
                                                        }
                                                </PaddingLeftRight2rem>
                                            </div>
                                            <div className="row">
                                                <SwitchDiv className="col">
                                                    <Switch 
                                                        onChange={this.onAllowTrackingCheckboxChanged.bind(this)} 
                                                        onColor={'#888888'}
                                                        disabled={false}
                                                        checked={this.state.allowTracking} 
                                                    />
                                                    <PaddingLeftSpan05rem>{Language[this.state.optOutLanguage].optOut.allowTracking}</PaddingLeftSpan05rem>
                                                </SwitchDiv>
                                            </div>
                                            <MarginTop1rem className="row">
                                                <div className="col text-center">
                                                    <PaddingLeftRight2rem className="col text-center">
                                                        <button className="btn btn-light" onClick={this.onSaveChangesClick.bind(this)}>{Language[this.state.optOutLanguage].optOut.acceptChanges}</button>
                                                    </PaddingLeftRight2rem>
                                                </div>
                                                <div className="col text-center">
                                                    <PaddingLeftRight2rem className="col text-center">
                                                        <AcceptButton className="btn" onClick={this.onAcceptButtonClick.bind(this)}>{Language[this.state.optOutLanguage].optOut.allowAll}</AcceptButton>
                                                    </PaddingLeftRight2rem>
                                                </div>
                                            </MarginTop1rem>
                                        </div>
                                    }
                                </div>
                            </div>
                        </OptOutDiv>
                    </div>
                }
            </div>
        );
    }
}

export default PrivacyOptOut;