import React, { Component } from 'react';
import Language from './Language.data';
import LoadingSpinner from './LoadingSpinner.component.js';
import $ from "jquery";
require('bootstrap');


class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historyText: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.reloadHistoryText();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.currentLanguage !== this.props.currentLanguage) {
            if(!this.state.isLoading) {
                this.setState({
                    isLoading: true,
                });
                this.reloadHistoryText();
            }
        }
    }

    reloadHistoryText() {
        this.setState({
            isLoading: true,
        },() => {
            window.axios.post(window.location.origin + '/company/history', {
                requestType: 'history',
                countryID: this.props.country.countryNo,
                languageID: Language[this.props.currentLanguage].languageNo,
            })
            .then((response) => {
                if(response.data?.success) {
                    this.setState({
                        historyText: response.data.historyText,
                        isLoading: false,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        });
    }

    render() {
        return (
            <div className="container-fluid marginTop50px marginBottom50px area" id="imprint">
                <div className="row marginTop50px">
                    <div className="col-12 text-center">
                        <h2>{Language[this.props.currentLanguage].history}</h2>
                    </div>
                </div>
                    {
                        this.state.isLoading && 
                        <LoadingSpinner key="loadingSpinnerTermsAndConditions"></LoadingSpinner>
                    }    
                    {
                        !this.state.isLoading &&
                        <div className="row marginTop25px">
                            <div className="col-12" id="dataContainer">
                            {
                                this.state.historyText.map((historyTextEntry) => {
                                    let text = '';
                                    let picture = '';
                                    if(historyTextEntry.picture !== undefined && historyTextEntry.picture.trim() !== '-none-')
                                    {
                                        let src = require('../images/history/' + historyTextEntry.picture.trim()).default;
                                        picture = <img src={src} className="img-fluid" alt="" /> 
                                    }
                                    if(historyTextEntry.title !== undefined && historyTextEntry.title.trim() !== '-none-') {
                                        if(historyTextEntry.title.trim().indexOf('- to date') !== -1) {
                                            let date = new Date();
                                            text = <strong>{historyTextEntry.title.trim().replace('- to date','- ' + date.getFullYear())}</strong>
                                        }
                                        else {
                                            text = <strong>{historyTextEntry.title.trim()}</strong>
                                        }
                                    }
                                    return (
                                            <div className="container">
                                                <div className="row marginTop25px">
                                                    <div className="col-12">
                                                    </div>
                                                </div>
                                                <div className="row padding10px animated fadeIn animDelay2 productLink">
                                                    <div className="col-1 d-none d-md-block"></div>
                                                    <div className="col-12 col-md-3 text-center">
                                                        {picture} 
                                                        <br />
                                                        {text}
                                                    </div>
                                                    <div className="col-12 col-md-5 text-center">
                                                        <div dangerouslySetInnerHTML={{__html: historyTextEntry.text.trim()}}></div>
                                                    </div>
                                                    <div className="col-2 d-none d-md-block"></div>
                                                </div>
                                            </div>
                                    );
                                })
                            }
                            </div>
                        </div>
                    }
            </div>
        );
    }
}

export default History;