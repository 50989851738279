import React from 'react';
import styled from 'styled-components';
import '../css/loadingSpinner.css';

const LoadingSpinnerContainer = styled.div`
    background-color:transparent !important;
`

const LoadingSpinner = () => (
    <LoadingSpinnerContainer className="container-fluid p-2">
        <div className="row">
            <div className="col-1 col-md-5"></div>
            <div className="col-10 col-md-2 text-center">
                <div className="d-flex justify-content-center">
                    <div className="dot-loader"></div>
                    <div className="dot-loader"></div>
                    <div className="dot-loader"></div>
                </div>
            </div>
            <div className="col-1 col-md-5"></div>
        </div>
    </LoadingSpinnerContainer>
);

export default LoadingSpinner;