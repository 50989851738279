import React from 'react';
import Language from '../Language.data';

function Additives(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].businessUnits.additives.additives}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].businessUnits.additives.buText}
                </div>
            </div>
        </>
    )
}

export default Additives;