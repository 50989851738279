import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Language from './Language.data';
import EmailAddress from './EmailAddress.component';
import ExternalLink from './ExternalLink.component';
import styled from 'styled-components';
import * as Tracking from '../utils/matomo';
import { phoneNumberToLink } from '../utils/utils';
import { FormattedText } from '../styles/FormattedText.styles';

import instagram from '../images/socialmedia_instagram.png';
import linkedin from '../images/socialmedia_linkedin.png';
import facebook from '../images/socialmedia_facebook.png';
import growNewImage from '../images/GrowNew_Root.webp';
import '../css/footer.css';

const FooterDiv = styled.footer`
    @media (max-width: 576px) { 
        position:relative;
        left:-3px;
        width:calc(100% + 6px);
    }
`

const GrowNew = styled.img`
	max-width:200px;
`

const FooterContainerDiv = styled.div`
    border-radius:0px;
`

const WhiteSpaceNoWrapDiv = styled.div`
    white-space:nowrap;
`

function Footer(props) {

    function onLinkClick(event,clickType) {
        Tracking.trackEvent('General','Click Footer ' + clickType, 'Footer ' + clickType);
    }

    return (
        <FooterDiv className="footer">
            <FooterContainerDiv className="container-fluid borderTop1px footerLayout">
                <div className="row">
                    <div className="col-md-12 col-lg-4 d-flex justify-content-start justify-content-lg-center align-items-center">
                        <GrowNew
                            src={growNewImage}
                            alt="Grow New"
                        />
                    </div>
                    <div className="col-md-12 col-lg-8 d-flex justify-content-start ">
                        <div className="row w-100">
                            <div className="col-md-12 col-lg-4 mt-3">
                                <div className="row">
                                    <div className="col d-flex justify-content-start">
                                        <FormattedText
                                            color={"#004839"}
                                            fontFamily={"Dazzed-Medium"}
                                            fontSize={"1.25rem"}
                                        >
                                            Get in touch
                                        </FormattedText>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col d-flex justify-content-start">
                                        <address>
                                            <small>
                                                <strong>{props.country.name[props.currentLanguage].toUpperCase()}</strong>
                                                <br />
                                                <address>
                                                    <span dangerouslySetInnerHTML={{__html: props.country.address}}></span>
                                                </address>
                                            </small>
                                        </address>
                                    </div>
                                </div>
                                {
                                    (props.country.phone !== undefined && props.country.phone.length > 0) &&
                                    <div className="row mt-5">
                                        <div className="col overflowHidden d-flex justify-content-start">
                                            <small>{`${Language[props.currentLanguage].phone}`} 
                                                <a className="footerLink" href={phoneNumberToLink(props.country.phone)}>
                                                    {
                                                        props.country.phone.replace("(1)", "( 1 )")
                                                        .replace("(2)", "( 2 )")
                                                        .replace("(3)", "( 3 )")
                                                        .replace("(4)", "( 4 )")
                                                        .replace("(5)", "( 5 )")
                                                        .replace("(6)", "( 6 )")
                                                        .replace("(7)", "( 7 )")
                                                        .replace("(8)", "( 8 )")
                                                        .replace("(9)", "( 9 )")
                                                    }
                                                </a>
                                            </small>
                                        </div>
                                    </div>
                                }
                                {
                                    (props.country.fax !== undefined && props.country.fax.length > 0) &&
                                    <div className="row">
                                        <div className="col overflowHidden d-flex justify-content-start">
                                            <small>{`${Language[props.currentLanguage].fax}`} 
                                                <a className="footerLink" href={phoneNumberToLink(props.country.fax)}>
                                                    {
                                                        props.country.fax.replace("(1)", "( 1 )")
                                                        .replace("(2)", "( 2 )")
                                                        .replace("(3)", "( 3 )")
                                                        .replace("(4)", "( 4 )")
                                                        .replace("(5)", "( 5 )")
                                                        .replace("(6)", "( 6 )")
                                                        .replace("(7)", "( 7 )")
                                                        .replace("(8)", "( 8 )")
                                                        .replace("(9)", "( 9 )")
                                                    }
                                                </a>
                                            </small>
                                        </div>
                                    </div>
                                }
                                {
                                    (props.country.email !== undefined && props.country.email.length > 0) &&
                                    <WhiteSpaceNoWrapDiv className="row">
                                        <div className="col d-flex justify-content-start">
                                            <small>
                                                {`${Language[props.currentLanguage].email}`} <EmailAddress key={"emailAddressKey" + props.country.name[props.currentLanguage]} classNames="footerLink" emailAddress={props.country.email} />
                                            </small>
                                        </div>
                                    </WhiteSpaceNoWrapDiv>
                                }   
                            </div>
                            <div className="col-md-12 col-lg-4 mt-3">
                                <div className="row">
                                    <div className="col d-flex justify-content-start">
                                        <FormattedText
                                            color={"#004839"}
                                            fontFamily={"Dazzed-Medium"}
                                            fontSize={"1.25rem"}
                                        >
                                            Find us on social media
                                        </FormattedText>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col d-flex justify-content-start">
                                                <ExternalLink
                                                    {...props}
                                                    key={"socialMediaFooterFacebook"}
                                                    id={"socialMediaFooterFacebook"}
                                                    linkHrefTag={"https://www.facebook.com/grolmangroup" }
                                                    linkTargetTag={"_blank"}
                                                    linkAdditionalClasses={"footerLink"}
                                                    imageSrcTag={facebook}
                                                    imageAltTag={"Follow us on Facebook"}
                                                    imageWidth={"50px"}
                                                    imageMinWidth={"50px"}
                                                    imageMaxWidth={"50px"}
                                                    imageMinHeight={"50px"}
                                                    imageBackgroundColor={"#004839"}
                                                    imageIsMaskImage={true}
                                                    iconWidth={"40px"}
                                                    iconHeight={"40px"}
                                                    iconPositonRight={"-7px"}
                                                    iconPositonBottom={"-7px"}
                                                    maskColor={"#004839"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col d-flex justify-content-start">
                                                <ExternalLink
                                                    {...props}
                                                    key={"socialMediaFooterInstagram"}
                                                    id={"socialMediaFooterInstagram"}
                                                    linkHrefTag={"https://www.instagram.com/grolmangroup/" }
                                                    linkTargetTag={"_blank"}
                                                    linkAdditionalClasses={"footerLink"}
                                                    imageSrcTag={instagram}
                                                    imageAltTag={"Follow us on Instagram"}
                                                    imageWidth={"50px"}
                                                    imageMinWidth={"50px"}
                                                    imageMaxWidth={"50px"}
                                                    imageMinHeight={"50px"}
                                                    imageBackgroundColor={"#004839"}
                                                    imageIsMaskImage={true}
                                                    iconWidth={"40px"}
                                                    iconHeight={"40px"}
                                                    iconPositonRight={"-7px"}
                                                    iconPositonBottom={"-7px"}
                                                    maskColor={"#004839"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col d-flex justify-content-start">
                                                <ExternalLink
                                                    {...props}
                                                    key={"socialMediaFooterLinkedIn"}
                                                    id={"socialMediaFooterLinkedIn"}
                                                    linkHrefTag={"https://www.linkedin.com/company/grolmangroup" }
                                                    linkTargetTag={"_blank"}
                                                    linkAdditionalClasses={"footerLink"}
                                                    imageSrcTag={linkedin}
                                                    imageAltTag={"Follow us on LinkedIn"}
                                                    imageWidth={"50px"}
                                                    imageMinWidth={"50px"}
                                                    imageMaxWidth={"50px"}
                                                    imageMinHeight={"50px"}
                                                    imageBackgroundColor={"#004839"}
                                                    imageIsMaskImage={true}
                                                    iconWidth={"40px"}
                                                    iconHeight={"40px"}
                                                    iconPositonRight={"-7px"}
                                                    iconPositonBottom={"-7px"}
                                                    maskColor={"#004839"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4 mt-3 d-flex justify-content-start justify-content-lg-center align-items-center" style={{flexDirection:'column'}}>
                                <div className="row w-100">
                                    <div className="col">
                                        <Link to="/resources" className="nav-link footerLink" onClick={(e) => onLinkClick(e,'Resources')}><small>{Language[props.currentLanguage].resources}</small></Link>
                                    </div>
                                </div>
                                <div className="row w-100">
                                    <div className="col">
                                        <Link to="/imprint" className="nav-link footerLink" onClick={(e) => onLinkClick(e,'Imprint')}><small>{Language[props.currentLanguage].imprint}</small></Link>
                                    </div>
                                </div>
                                <div className="row w-100">
                                    <div className="col">
                                        <Link to="/privacy" className="nav-link footerLink" onClick={(e) => onLinkClick(e,'Privacy')}><small>{Language[props.currentLanguage].privacy}</small></Link>
                                    </div>
                                </div>
                                <div className="row w-100">
                                    <div className="col">
                                        <Link to="/contact" className="nav-link footerLink" onClick={(e) => onLinkClick(e,'Contact')}><small>{Language[props.currentLanguage].contact}</small></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FooterContainerDiv>
        </FooterDiv>
    );
}

export default Footer;