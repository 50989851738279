import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Language from './Language.data';
import * as Tracking from '../utils/matomo';

import additivesImage from '../images/Additives.webp';
import bindersImage from '../images/Resins.webp';
import fillersImage from '../images/Fillers_Perfomormance_Materials.webp';
import pigmentsImage from '../images/Pigments_Dyes.webp';
import personalCareImage from '../images/PersonalCareProducts.webp';

const blendIn = keyframes`
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
`;

const ProductsDiv = styled.div`
    @media (max-width: 576px) { 
        padding-left:5px;
        padding-right:5px;
        margin-left:0px;
        margin-right:0px;
    }    
`
const ProductsImg = styled.img`
    border-radius: 1rem;
    animation-duration: ${props => props.isActive ? 'unset' : '0.5'};
    animation-name: ${props => props.isActive ? 'unset' : blendIn};
    opacity: ${props => props.isActive ? 'unset' : '0.3'};
`

function BusinessUnitSelection(props) {
    const [hoverImageElement, setHoverImageElement] = useState(null);

    function handleClickBU(event, bu) {
        Tracking.trackEvent('Products','Click Products - ' + bu, 'Products - ' + bu);
        props.onBUChange(bu,'products');
    }

    function handleHover(event, bu) {
        var element = document.getElementById(bu);
        if(element.classList.contains("fadeInDown") || element.classList.contains("fadeInUp")) {
            if(element.classList.contains("fadeInDown")) {
                element.classList.remove("fadeInDown");
            }
            if(element.classList.contains("fadeInUp")) {
                element.classList.remove("fadeInUp");
            }
            element.classList.remove("animDelay2");
            element.classList.add("productLink");
        }
    }

    return (
        <ProductsDiv className="container marginBottom50px area" id="productsDiv">
            <div className="row">
                <div className="col-12 text-center">
                    <h2 id="products">{Language[props.currentLanguage].menu.products}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    {Language[props.currentLanguage].productsText}
                </div>
            </div>

            <div className="row marginTop25px d-flex justify-content-center align-items-start">
                <div 
                    className={`col-6 col-md-2 col-xs-12 text-center padding10px imageLink animated cursorPointer`} 
                    id="additives" 
                    onClick={(e) => handleClickBU(e, 'additives')} 
                    onMouseOver={(e) => handleHover(e, 'additives')}
                    onMouseEnter={() => setHoverImageElement('additives')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <ProductsImg 
                                isActive={(
                                        props.currentBU === 'additives' || 
                                        props.currentBU === '' || 
                                        hoverImageElement === 'additives' ||
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={additivesImage} 
                                alt="Additives" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].businessUnits.additives.additives}
                        </div>
                    </div>
                </div>

                <div 
                    className={`col-6 col-md-2 col-xs-12 text-center padding10px imageLink animated cursorPointer`}
                    id="binders" 
                    onClick={(e) => handleClickBU(e, 'binders')} 
                    onMouseOver={(e) => handleHover(e, 'binders')}
                    onMouseEnter={() => setHoverImageElement('binders')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <ProductsImg 
                                isActive={(
                                        props.currentBU === 'binders' || 
                                        props.currentBU === '' || 
                                        hoverImageElement === 'binders' || 
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={bindersImage} 
                                alt="Resins" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].businessUnits.binders.binders}
                        </div>
                    </div>
                </div>
                <div 
                    className={`col-6 col-md-2 col-xs-12 text-center padding10px imageLink animated cursorPointer`}
                    id="fillers" 
                    onClick={(e) => handleClickBU(e, 'fillers')} 
                    onMouseOver={(e) => handleHover(e, 'fillers')}
                    onMouseEnter={() => setHoverImageElement('fillers')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <ProductsImg 
                                isActive={(
                                        props.currentBU === 'fillers' || 
                                        props.currentBU === '' || 
                                        hoverImageElement === 'fillers' ||
                                        props.areImagesActive
                                    ) ? true : false
                                }    
                                src={fillersImage} 
                                alt="Fillers" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.fillers}
                        </div>
                    </div>
                </div>
                <div 
                    className={`col-6 col-md-2 col-xs-12 text-center padding10px imageLink animated cursorPointer`}
                    id="pigments" 
                    onClick={(e) => handleClickBU(e, 'pigments')} 
                    onMouseOver={(e) => handleHover(e, 'pigments')}
                    onMouseEnter={() => setHoverImageElement('pigments')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <ProductsImg 
                                isActive={(
                                        props.currentBU === 'pigments' || 
                                        props.currentBU === '' || 
                                        hoverImageElement === 'pigments' ||
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={pigmentsImage} 
                                alt="PigmentsAndDyes" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].businessUnits.pigmentsAndDyes.pigments}
                        </div>
                    </div>
                </div>


                <div 
                    className={`col-6 col-md-2 col-xs-12 text-center padding10px imageLink animated cursorPointer`}
                    id="personalcare" 
                    onClick={(e) => handleClickBU(e, 'personalcare')} 
                    onMouseOver={(e) => handleHover(e, 'personalcare')}
                    onMouseEnter={() => setHoverImageElement('personalcare')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <ProductsImg 
                                isActive={(
                                        props.currentBU === 'personalcare' || 
                                        props.currentBU === '' || 
                                        hoverImageElement === 'personalcare' ||
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={personalCareImage} 
                                alt="Personal Care" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].businessUnits.personalCare.personalCare}
                        </div>
                    </div>
                </div>

            </div>
        </ProductsDiv>
    );
}

export default BusinessUnitSelection;