import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner.component.js';
import ContactPerson from './ContactPerson.component.js';
import ProductGroup from './searchResults/ProductGroup.component.js';
import SearchLinkResult from './searchResults/SearchLinkResult.component.js';
import Location from './searchResults/Location.component.js';
import Language from './Language.data';
import { ImageColumn } from '../styles/Image.styles.js';
import { isNullOrUndefined } from '../utils/utils.js';
import { trackEvent } from '../utils/matomo.js';

import searchIcon from '../images/search.svg';

const ResultContainer = styled.div`
    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
    }
`

function SearchResults(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [isSearchDone, setIsSearchDone] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [productGroups, setProductGroups] = useState([]);
    const [addresses,setAddresses] = useState([]);
    const [links, setLinks] = useState([]);
    const [searchText, setSearchText] = useState(null)
    const [hintText, setHintText] = useState(null);

    const searchTextRef = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        readUrlSearchText();
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        readUrlSearchText();
    },[location.pathname]);

    useEffect(() => {
        search();
    },[searchText]);

    useEffect(() => {
        search();
    },[props.currentLanguage])

    function search() {
        if(!isNullOrUndefined(searchText)) {
            trackEvent('Search','Search',searchText,null);
            if(searchText?.trim().length < 3) {
                setHintText('Please specify minimum 3 characters');
                clearPreviousResults();
            }
            else {
                setHintText(null);
                setIsLoading(true);
                setIsSearchDone(true);
                clearPreviousResults();
                window.axios.post(window.location.origin + '/search', {
                    requestType: 'search',
                    searchText: searchText,
                    languageID: Language[props.currentLanguage].languageNo,
                    countryID: props.country.countryNo
                })
                .then((response) => {
                    if(response.data?.success) {
                        if(!isNullOrUndefined(response.data.contacts) && response.data.contacts.length > 0) {
                            setContacts(response.data.contacts);
                        }
                        if(!isNullOrUndefined(response.data.productGroups) && response.data.productGroups.length > 0) {
                            setProductGroups(response.data.productGroups);
                        }
                        if(!isNullOrUndefined(response.data.addresses) && response.data.addresses.length > 0) {
                            setAddresses(response.data.addresses);
                        }
                        if(!isNullOrUndefined(response.data.links) && response.data.links.length > 0) {
                            setLinks(response.data.links);
                        }
                    }
                })
                .then(() => {
                    setIsLoading(false);
                });
            }
        }
        else {
            setIsLoading(false);
        }
    }

    function clearPreviousResults() {
        setContacts([]);
        setProductGroups([]);
        setAddresses([]);
        setLinks([]);
    }

    function readUrlSearchText() {
        let searchText = decodeURIComponent(location.pathname.replaceAll("/search/",""));
        if(searchText === "/search") {
            searchText = decodeURIComponent(location.pathname.replaceAll("/search",""));
        }
        if(searchText.trim().length > 0) {
            setSearchText(searchText);
        }
    }

    return (
        <div className="container marginTop50px marginBottom50px area" id="searchResultsContainer">
            <div className="row marginTop50px">
                <div className="col-12 text-center">
                    <h2>{Language[props.currentLanguage].search}</h2>
                </div>
            </div>
            <div className="row marginTop50px">
                <div className="col-12">
                    <form 
                        onSubmit={(event) => {
                            event.preventDefault();
                            navigate("/search/" + searchTextRef.current.value);
                            setSearchText(searchTextRef.current.value);
                        }}
                    >
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="searchIcon">
                                <ImageColumn
                                    backgroundImage={searchIcon}
                                    imageSize={"20px"}
                                />
                            </span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Search" 
                                aria-label="Search" 
                                aria-describedby="searchIcon"
                                ref={searchTextRef}
                            />
                        </div>
                    </form>
                </div>
            </div>
            {
                !isNullOrUndefined(searchText) && hintText === null &&
                <div className="row marginTop50px">
                    <div className="col-12">
                        {
                            `${(isLoading === true ? 'Searching for' : (contacts.length + productGroups.length + addresses.length + links.length) + ' Result(s) for')} "${searchText}"`
                        }
                    </div>
                </div>
            }
            {
                hintText !== null &&
                <div className="row marginTop50px mb-4">
                    <div className="col-12">
                        {
                            `${hintText}`
                        }
                    </div>
                </div>
            }
            {
                isLoading &&
                <LoadingSpinner key={"searchLoadingSpinner"} />
            }
            {
                isLoading === false &&
                isSearchDone == true && 
                contacts.length === 0 &&
                productGroups.length === 0 &&
                addresses.length === 0 &&
                links.length === 0 &&
                <div>
                    We are sorry. We could not find any related information with your search query. Please try again with other keywords.
                </div>
            }
            {
                isLoading === false && 
                <div id="searchResultsResults">
                    <>
                        {
                            contacts.length > 0 &&
                            <>
                                <div className="row">
                                    <div className="col-12 font-weight-bold">
                                        {Language[props.currentLanguage].contacts}
                                    </div>
                                </div>
                                <>
                                    {
                                        contacts.map((contact) => {
                                            return (
                                                <ResultContainer className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card-body">
                                                                <div className="borderRadius10px border1pxSolide0e0e0 padding10px">
                                                                    <div className="row">
                                                                        <ContactPerson 
                                                                            contact={contact} 
                                                                            currentLanguage={props.currentLanguage} 
                                                                            key={"contact" + contact.id} 
                                                                            currentSearchText={searchText}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ResultContainer>
                                            )
                                        })
                                    }
                                </>
                            </>
                        }
                    </>
                    <>
                        {
                            addresses.length > 0 &&
                            <>
                                <div className="row mt-4">
                                    <div className="col-12 font-weight-bold">
                                        {Language[props.currentLanguage].locations}
                                    </div>
                                </div>
                                <>
                                    {
                                        addresses.map((address) => {
                                            return (
                                                <>
                                                    <ResultContainer className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="card-body">
                                                                    <div className="borderRadius10px border1pxSolide0e0e0 padding10px">
                                                                        <div className="row">
                                                                            <Location 
                                                                                currentLanguage={props.currentLanguage} 
                                                                                country={address} 
                                                                                currentSearchText={searchText}
                                                                            />
                                                                        </div>
                                                                        <div className="row justify-content-center">
                                                                        {
                                                                            address.country?.contacts?.length > 0 &&
                                                                            address.country.contacts.map((contact) => {
                                                                                return (
                                                                                    <div className="col-12 col-lg-6">
                                                                                        <div className="card-body">
                                                                                            <div className="borderRadius10px border1pxSolide0e0e0 padding10px">
                                                                                                <div className="row">
                                                                                                    <ContactPerson 
                                                                                                        contact={contact} 
                                                                                                        currentLanguage={props.currentLanguage} 
                                                                                                        key={"contactCountry" + contact.id} 
                                                                                                        currentSearchText={searchText}
                                                                                                        imagePosition={"center"}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ResultContainer>
                                                    {/*
                                                        address.country?.contacts?.length > 0 &&
                                                        address.country.contacts.map((contact) => {
                                                            return (
                                                                <ResultContainer className="container-fluid">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="card-body">
                                                                                <div className="borderRadius10px border1pxSolide0e0e0 padding10px">
                                                                                    <div className="row">
                                                                                        <ContactPerson 
                                                                                            contact={contact} 
                                                                                            currentLanguage={props.currentLanguage} 
                                                                                            key={"contactCountry" + contact.id} 
                                                                                            currentSearchText={searchText}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ResultContainer>
                                                            )
                                                        })
                                                    */}
                                                </>
                                            )
                                        })
                                    }
                                </>
                            </>
                        }
                    </>
                    <>
                        {
                            productGroups.length > 0 &&
                            <>
                                <div className="row mt-4">
                                    <div className="col-12 font-weight-bold">
                                        {Language[props.currentLanguage].productGroups}
                                    </div>
                                </div>
                                <>
                                    {
                                        productGroups.map((productGroup) => {
                                            return (
                                                <ProductGroup 
                                                    currentLanguage={props.currentLanguage} 
                                                    productGroup={productGroup} 
                                                    country={props.country} 
                                                    key={productGroup.productGroupName} 
                                                    currentSearchText={searchText}
                                                />
                                            )
                                        })
                                    }
                                </>
                            </>
                        }
                    </>
                    <>
                        {
                            links.length > 0 &&
                            <>
                                <div className="row mt-4">
                                    <div className="col-12 font-weight-bold">
                                        {Language[props.currentLanguage].links}
                                    </div>
                                </div>
                                <>
                                    {
                                        links.map((link) => {
                                            return (
                                                <SearchLinkResult 
                                                    currentLanguage={props.currentLanguage} 
                                                    link={link} 
                                                    currentSearchText={searchText}
                                                />
                                            )
                                        })
                                    }
                                </>
                            </>
                        }
                    </>
                </div>
            }
        </div>
    )
}

export default SearchResults;