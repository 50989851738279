import React, { useState, useEffect, useRef } from 'react';
import Language from './Language.data';
import styled from 'styled-components';

const ComingSoonImage = styled.img`
`

function Laboratory(props) {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <img 
                            src="/images/ComingSoon.webp"
                            alt="Laboratory"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Laboratory;