import React, { useEffect } from 'react';
import sorryImage from '../../images/sorry.webp';

function Error404(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col text-center">
                    <img src={sorryImage} className="img-fluid" />
                </div>
            </div>
            <div className="row mt-5">
                <div className="col text-center">
                    The page you are looking for cannot be found.
                </div>
            </div>
        </div>
    )
}

export default Error404;