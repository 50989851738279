import React, { useState, useEffect } from 'react';
import EmailAddress from './EmailAddress.component';
import Link from './Link.component';
import { v4 as uuidv4 } from 'uuid';
import { isNullOrUndefined, phoneNumberToLink, highlightText } from '../utils/utils';

import phone from '../images/phoneBlack.svg';
import fax from '../images/faxBlack.svg';
import email from '../images/emailBlack.svg';

function Address(props) {
    const [addressHtmlText, setAddressHtmlText] = useState((props.country.address || props.country.fullAddress));

    useEffect(() => {
        if(!isNullOrUndefined(props.currentSearchText)) {
            let highlightedAddress = highlightText(addressHtmlText,props.currentSearchText);
            setAddressHtmlText(highlightedAddress);
        }
    },[]);

    return (
        !isNullOrUndefined(props.country) &&
        <address>
            <span dangerouslySetInnerHTML={{__html: addressHtmlText }}></span>
            <br />
            <br />
            {
                (props.country.phone !== undefined && props.country.phone.length > 0) &&
                <div>
                    <img src={phone} alt="Anrufen" className="phoneMain"></img><span> </span>
                    <a href={phoneNumberToLink(props.country.phone)} className="link">
                        {
                            props.country.phone.replace("(1)", "( 1 )")
                                                .replace("(2)", "( 2 )")
                                                .replace("(3)", "( 3 )")
                                                .replace("(4)", "( 4 )")
                                                .replace("(5)", "( 5 )")
                                                .replace("(6)", "( 6 )")
                                                .replace("(7)", "( 7 )")
                                                .replace("(8)", "( 8 )")
                                                .replace("(9)", "( 9 )")
                        }
                    </a>
                </div>
            }
            {
                (props.country.fax !== undefined && props.country.fax.length > 0) &&
                <div>
                    <img src={fax} alt="Fax" className="phoneMain"></img><span> </span>
                    <a href={phoneNumberToLink(props.country.fax)} className="link">
                        {
                            props.country.fax.replace("(1)", "( 1 )")
                                            .replace("(2)", "( 2 )")
                                            .replace("(3)", "( 3 )")
                                            .replace("(4)", "( 4 )")
                                            .replace("(5)", "( 5 )")
                                            .replace("(6)", "( 6 )")
                                            .replace("(7)", "( 7 )")
                                            .replace("(8)", "( 8 )")
                                            .replace("(9)", "( 9 )")
                        }
                    </a>
                </div>
            }
            {
                (props.country.email !== undefined && props.country.email.length > 0) &&
                <div>
                    <img src={email} alt="Email" className="emailMain"></img><span> </span>
                    <EmailAddress key={uuidv4()} classNames="link" emailAddress={props.country.email} />
                </div>
            }
            {
                props.showWebsiteLink && 
                <Link
                    linkUrl="https://www.grolman-group.com"
                    isExternalLink={false}
                />
            }
        </address>
    )
}

export default Address;