import React from 'react';
import Language from '../Language.data';

function Construction(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].industries.construction.construction}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].applications}:
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.construction.applicationText}
                </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.construction.industryText1}
                </div>
            </div>
            <div className="row mt-1 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.construction.industryText2}
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <ul>
                        <li>{Language[props.currentLanguage].functionalities.coloursAndEffects}</li>
                        <li>{Language[props.currentLanguage].functionalities.irReflective}</li>
                        <li>{Language[props.currentLanguage].functionalities.antiCorrosion}</li>
                        <li>{Language[props.currentLanguage].functionalities.electricalAndThermalConductivity}</li>
                        <li>{Language[props.currentLanguage].functionalities.defoaming}</li>
                    </ul>
                </div>
                <div className="col">
                    <ul>                            
                        <li>{Language[props.currentLanguage].functionalities.airEntrainingAgent}</li>
                        <li>{Language[props.currentLanguage].functionalities.shrinkageControl}</li>
                        <li>{Language[props.currentLanguage].functionalities.viscosityModification}</li>
                        <li>{Language[props.currentLanguage].functionalities.wettingAndDispersing}</li>
                        <li>{Language[props.currentLanguage].functionalities.longerLifetime}</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Construction;