import React from 'react';
import Language from '../Language.data';
import StyledLink from '../Link.component';

function PersonalCare(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].businessUnits.personalCare.personalCare}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].businessUnits.personalCare.buText}
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center marginTop25px">
                <div className="col">
                    {`${Language[props.currentLanguage].businessUnits.personalCare.moreInformationPart1} `}
                    <StyledLink 
                        linkUrl={"https://www.grolman-personalcare.com"} 
                        linkText={Language[props.currentLanguage].businessUnits.personalCare.website}
                        isExternalLink={true}
                        target="_blank"
                        color="#000"
                    />
                    {` ${Language[props.currentLanguage].businessUnits.personalCare.moreInformationPart2} `}
                </div>
            </div>
        </>
    )
}

export default PersonalCare;