import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import $ from "jquery";
import Language from './Language.data';
import Country from './Country.data';
import * as Tracking from '../utils/matomo';
import styled, { keyframes } from 'styled-components';
import { isNullOrUndefined } from '../utils/utils';
import { FormattedText } from '../styles/FormattedText.styles';
import { ImageColumn } from '../styles/Image.styles';
import ExternalLink from './ExternalLink.component';
var _ = require('lodash');

// *** IMPORT IMAGES ***
// Country Icons from https://www.iconfinder.com/iconsets/flags_gosquared
import logo from '../images/Grolman_Root.webp';
import growNewImage from '../images/GrowNew_Root.webp';
import searchIcon from '../images/search.svg';
import instagram from '../images/socialmedia_instagram.png';
import linkedin from '../images/socialmedia_linkedin.png';
import facebook from '../images/socialmedia_facebook.png';


const fadeInDown = keyframes`
	from {
		opacity:0;
		transform:translate3d(0,-100vh,0);
	}
	to {
		opacity:1;
		transform:none;
	}
`;

const fadeOutUp = keyframes`
	from {
		opacity:1;
		transform:none;
	}
	to {
		opacity:0;
		transform:translate3d(0,-100vh,0);
	}
`;

const decreaseHeight = keyframes`
    0% {
        height: 75px;
		width: 240px;
    }
    100% {
        height: 45px;
		width: 144px;
    }
`;

const increaseHeight = keyframes`
    0% {
        height: 45px;
		width: 144px;
    }
    100% {
        height: 75px;
		width: 240px;
    }
`;

const showSearchTextField = keyframes`
    0% {
		width: 0px;
		display:block;
    }
    100% {
		width: 200px;
    }
`;

const hideSearchTextField = keyframes`
    0% {
		width: 200px;
    }
    100% {
		width: 0px;
		display: none;
    }
`;

const NavBarContainer = styled.div`
	border-radius:unset;
	background-color: #00DD81 !important;

	position: fixed;
    top: 30px;
    right: 0;
    left: 0;
    z-index: 1030;
	min-height:130px;

	&:hover {
	}
`

const MenuOpen = styled.div`
	opacity:0;
	position:absolute;
	top:0vw;
	left:0px;
	width:99.5vw;
	height:100vh;
	z-index:10000;
	padding-top:130px;
	background-color: #00DD81 !important;
	/*padding-top:1rem;*/
	padding-bottom:1rem;
	padding-left:5rem;
	padding-right:5rem;
	/*
	overflow-y: auto;
	scrollbar-width: thin;
	*/

	animation-name:${props => props.isMenuOpen ? fadeInDown : fadeOutUp};
	animation-delay:${props => !props.isMenuOpen ? '1s' : 'inherit'};
	animation-duration:${props => props.isMenuOpen ? '0.8s' : 'inherit'};
	animation-fill-mode: forwards;
`

const MenuCloseIcon = styled.div`
	max-height:60px;
	max-width:60px;
	width:100%;
	position:absolute;
	right:0px;
	top:0px;
	padding: 20px 20px 20px 20px;
	color: #004839;
	cursor:pointer;

	&:hover {
		color: #00DD81;
		background-color: #004839;
		border-radius: 0px 0px 0px 20px;
	}
`

const Logo = styled.img`
	max-width:200px;
	/*
	animation-fill-mode: forwards;
	animation-duration: 0.25s;
    animation-name: ${props => !isNullOrUndefined(props.isNavBarSmall) && props.isNavBarSmall === true ? decreaseHeight : increaseHeight};
	*/
`

const GrowNew = styled.img`
	max-width:200px;
`

const SearchTextInputField = styled.input`
	visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
	animation-fill-mode: forwards;
	animation-duration: 0.25s;
    animation-name: ${props => props.isVisible ? showSearchTextField : hideSearchTextField};
	margin-right:0.5rem;
`

const MainMenuButton = styled.button`
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	background-color: rgba(255, 255, 255, 0.7);
	color: #004839;
	border-radius: 9999px;
	border:0px;
	margin-left:0.25rem;
	margin-right:0.25rem;
	width:150px;
`

const MainMenuBurgerButton = styled.button`
	padding-top:0.25rem;
	padding-bottom:0.25rem;
	background-color: rgba(255, 255, 255, 0.7);
	color: #004839;
	border-radius: 9999px;
	border:0px;
	margin-left:0.25rem;
	margin-right:0.25rem;
	width:70px;
`

const GetInTouchDiv = styled.div`
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	background-color: #BACCFF;
	color: #004839;
	border-radius: 9999px;
	border:0px;
	margin-left:0.25rem;
	margin-right:0.25rem;
	width:150px;
	text-align:center;

	&:hover {
		color: #BACCFF;
		background-color: #004839;
	}
`


const SubMenuHeader = styled.div`
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	background-color: transparent;
	color: #004839;
	border-radius: 9999px;
	border:${props => props.border ? props.border : '1px'} solid #004839;
	margin-left:0.25rem;
	margin-right:0.25rem;
	width:150px;
	text-align:center;

	&:hover {
		background-color: #004839;
		color: #00DD81 !important;
	}

	&:hover a {
		background-color: #004839;
		color: #00DD81 !important;
	}
`

const InvisibleSubMenuHeader = styled.div`
padding-top:0.5rem;
	padding-bottom:0.5rem;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	margin-left:0.25rem;
	margin-right:0.25rem;
	width:150px;
`

const SubMenuHeaderLink = styled.a`
	color: #004839 !important;
	cursor:pointer;

	&:hover {
		text-decoration:none !important;
		color: #00DD81 !important;
	}
`

const SloganDiv = styled.div`
	height:30px;
	width:100vw;
	background-color: #004839;
	color: #00DD81;
	text-align: center;
	position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
`

const Nav = styled.nav`
	min-height:130px;
`

const LanguagePickerSpan = styled.span`
	margin-left:0.5rem;
	min-width: 65px;
	max-width: 65px;
`

const LanguageSelectionEntry = styled.div`
	background-color: ${props => props.isActiveLanguage ? '#004839' : 'transparent'};
	color: ${props => props.isActiveLanguage ? '#00DD81' : '#004839'};
	border-radius: 9999px;
	width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
	font-size:12px;
`

function Navigation(props) {

	const [isNavBarSmall, setIsNavBarSmall] = useState(false);
	const [isSearchTextFieldVisible, setIsSearchTextFieldVisible] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [smallMenuState, setSmallMenuState] = useState({  isCompanyMenuOpen: false, isProductsMenuOpen: false, isIndustriesMenuOpen: false, isLocationsMenuOpen: false });
	const searchRef = useRef();
	const searchMenuRef = useRef();

	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener("scroll", onScroll);
		return () => {
            window.removeEventListener("scroll", onScroll);
        }
	},[]);

	useEffect(() => {
		if(isSearchTextFieldVisible) {
			searchRef?.current?.focus();
		}
	},[isSearchTextFieldVisible])

	const updateSmallMenuState = (key, value) => {
		setSmallMenuState((prevState) => ({
		  ...prevState,
		  [key]: value,
		}));
	  };

	function onScroll(event) {
		let scroll = this.scrollY;
		if(scroll > 100) {
			setIsNavBarSmall(true);
		}
		else {
			setIsNavBarSmall(false);
		}
		//console.log('Scrolling Y:', scroll);
	}

	const countryList = _.sortBy(Country,(c) => { return _.deburr(c.name[props.currentLanguage]) });

	function scrollToTop() {
		window.scrollTo({top: 0, behavior: 'smooth'});
		hideMenu();
	}
	
	function hideMenu() {
		//$('#navbarSupportedContent').removeClass('show');
		setIsMenuOpen(false);
	}
	
    function handleClickLanguage(event, language) {
		Tracking.trackEvent('General','Click Language ' + language,'Menu Language ' + language);
		props.onLanguageChange(language);
	}
	
	function handleClickBU(event, bu) {
		props.onBUChange(bu,'products');
		hideMenu();
		Tracking.trackEvent('General','Click Menu Products','Menu Products ' + bu);
	}

	function handleClickIndustry(event, industry) {
		props.onIndustryChange(industry);
		hideMenu();
		Tracking.trackEvent('General','Click Menu Industry','Menu Industry ' + industry);
	}
	
	function handleClickCountry(event, country) {
		props.onCountryChange(country);
		hideMenu();
		Tracking.trackEvent('General','Click Menu Locations','Menu Locations ' + country);
	}

	function handleClickCompany() {
		Tracking.trackEvent('General','Click Menu Company','Menu Company Company');
		hideMenu();
	}

	function handleClickHistory() {
		Tracking.trackEvent('General','Click Menu Company','Menu Company History');
		hideMenu();
	}

	function handleClickLocations() {
		Tracking.trackEvent('General','Click Menu Company','Menu See all locations');
		hideMenu();
	}

	function handlePersonalCareLinkClick() {
		Tracking.trackEvent('General','Click Menu Products','Menu Products - Personal Care');
	}

	function handleClickLogo() {
		Tracking.trackEvent('General','Click Menu Logo','Menu Logo');
		scrollToTop();
	}

	function handleClickJobs() {
		Tracking.trackEvent('General','Click Menu Jobs','Menu Jobs');
		scrollToTop();
	}

	function handleSearchSubmit(event) {
		event.preventDefault();
		let searchText = searchRef?.current?.value;
		if(isNullOrUndefined(searchText)) {
			searchText = searchMenuRef?.current?.value;
		}
		navigate("/search/" + searchText);
		//document.getElementById("hamburgerButton").click();
	}
	
	function handleClickLab() {
		Tracking.trackEvent('General','Click Menu Laboratory','Menu Laboratory');
		scrollToTop();
	}

	function handleEarthClick() {
		Tracking.trackEvent('General','Click Menu Vision & Sustainability','Menu Vision & Sustainability');
	}

	return (
		<>
			<SloganDiv>
				Global Specialty Chemical Distribution for a Circular Future
			</SloganDiv>
			<div>
				<NavBarContainer 
					className="container-fluid" 
					id="nav"
				>
					<div className="row">
						<div className="col-12 borderBottom1pxSolid888">
							<Nav className="navbar navbar-expand-xl text-center">
								<div className="row w-100">
									<div className="col-8 d-flex align-items-center justify-content-start">
										<div className="">
											<a 
												href="/"
												className="logo" 
												onClick={handleClickLogo} 
												key={"navLogoKey"}
											>
												<Logo
													src={logo}
												/>
											</a>
										</div>
										<div className="d-none d-xl-block">
											<Link
												to="/products" 
												className="newNavLink" 
												onClick={(e) => handleClickBU(e, null)} 
												key={"navProductsKey"}
											>
												<MainMenuButton>
													{Language[props.currentLanguage].menu.products}
												</MainMenuButton>
											</Link>
										</div>
										<div className="d-none d-xl-block">
											<Link
												to="/industries" 
												className="newNavLink" 
												onClick={(e) => handleClickIndustry(e, null)} 
												key={"navIndustryKey"}
											>
												<MainMenuButton>
														{Language[props.currentLanguage].menu.industries}
												</MainMenuButton>
											</Link>
										</div>
										<div className="d-none d-xl-block">
											<Link
												to="/jobs" 
												className="newNavLink" 
												onClick={handleClickJobs} 
												key={"navJobsKey"}
											>
												<MainMenuButton>
														{Language[props.currentLanguage].menu.jobs}
												</MainMenuButton>
											</Link>
										</div>
										<div>
											<MainMenuBurgerButton
												onClick={(e) => setIsMenuOpen(!isMenuOpen)}
											>
												{/*<span className="navbar-toggler-icon"></span>*/}
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#004839" className="bi bi-list" viewBox="0 0 16 16">
													<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
												</svg>
											</MainMenuBurgerButton>
										</div>
									</div>
									<div className="col-4 d-none d-md-flex justify-content-end align-items-center">
										{
											!isMenuOpen &&
											<> 
												<span className="navbar-text text-center fontSizeXSmall">
													<form onSubmit={(event) => handleSearchSubmit(event)}>
														<div className="input-group">
															<span 
																className="input-group-text" 
																id="searchIcon"
																onClick={(event) => {
																	setIsSearchTextFieldVisible(!isSearchTextFieldVisible);
																}}
															>
																<ImageColumn
																	backgroundImage={searchIcon}
																	imageSize={"20px"}
																/>
															</span>
															<SearchTextInputField 
																isVisible={isSearchTextFieldVisible}
																type="text" 
																className="form-control" 
																placeholder="Search" 
																aria-label="Search" 
																aria-describedby="searchIcon"
																ref={searchRef}
																onBlur={(event) => setIsSearchTextFieldVisible(false)}
															/>
														</div>
													</form>
												</span>
												<LanguagePickerSpan className="navbar-text text-center fontSizeXSmall d-flex justify-content-center">
													{
														props.languagesToShow.map((language) => {
															return (
																<LanguageSelectionEntry 
																	key={`${language}Selection`} 
																	className={`cursorPointer`} 
																	onClick={(e) => {
																		handleClickLanguage(e, language);
																		document.getElementById("hamburgerButton").click();
																	}} 
																	isActiveLanguage={props.currentLanguage === language}
																>
																	{ `${language.toUpperCase()}` }
																</LanguageSelectionEntry>
															);
														})
													}
												</LanguagePickerSpan>
												<GrowNew
													src={growNewImage}
													alt="Grow New"
												/>
											</>
										}
									</div>
								</div>
							</Nav>
							{
								/*isMenuOpen &&*/
								<MenuOpen
									isMenuOpen={isMenuOpen}
								>
									<div className="row d-flex d-lg-none">
										<div className="col">
											<SubMenuHeader>
												<SubMenuHeaderLink 
													className="dropdown-toggle"
													onClick={(e) => updateSmallMenuState("isCompanyMenuOpen", !smallMenuState.isCompanyMenuOpen)}
												>
													{Language[props.currentLanguage].menu.company.toUpperCase()}
												</SubMenuHeaderLink>
											</SubMenuHeader>
											{
												smallMenuState.isCompanyMenuOpen &&
												<>
													<div className="mt-4">
														<Link 
															to="/company" 
															className="submenuLink" 
															key={"navCompanyCompanySubMenuKey"} 
															onClick={handleClickCompany}
														>
															{Language[props.currentLanguage].menu.company}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/history" 
															className="submenuLink" 
															key={"navCompanyHistorySubMenuKey"} 
															onClick={handleClickHistory}
														>
															{Language[props.currentLanguage].menu.history}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/jobs" 
															className="submenuLink" 
															key={"navCompanyJobsSubMenuKey"} 
															onClick={handleClickJobs}
														>
															{Language[props.currentLanguage].menu.jobs}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/laboratories" 
															className="submenuLink" 
															key={"navCompanyLaboratoriesSubMenuKey"} 
															onClick={handleClickLab}
														>
															{Language[props.currentLanguage].menu.lab}
														</Link>
													</div>
													<div className="mt-4">
														<a 
															className="submenuLink" 
															rel="noopener noreferrer" 
															href="https://grolman.earth" 
															target="_blank" 
															onClick={handleEarthClick}
														>
															{Language[props.currentLanguage].menu.visionAndSustainability}
														</a>
													</div>
												</>
											}
											<SubMenuHeader className="mt-4">
												<SubMenuHeaderLink 
													className="dropdown-toggle"
													onClick={(e) => updateSmallMenuState("isProductsMenuOpen", !smallMenuState.isProductsMenuOpen)}
												>
													{Language[props.currentLanguage].menu.products.toUpperCase()}
												</SubMenuHeaderLink>
											</SubMenuHeader>
											{
												smallMenuState.isProductsMenuOpen &&
												<>
													<div className="mt-4">
														<Link 
															to="/products/additives" 
															className="submenuLink" 
															key={"navProductsAdditivesSubMenuKey"} 
															onClick={(e) => handleClickBU(e, 'additives')}
														>
															{Language[props.currentLanguage].businessUnits.additives.additives}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/products/fillers" 
															className="submenuLink" 
															key={"navProductsFillersSubMenuKey"} 
															onClick={(e) => handleClickBU(e, 'fillers')}
														>
															{Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.fillers}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/products/personalcare" 
															className="submenuLink" 
															key={"navProductsPersonalCareSubMenuKey"} 
															onClick={(e) => handleClickBU(e, 'personalcare')}
														>
															{Language[props.currentLanguage].personalCare}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/products/pigments" 
															className="submenuLink" 
															key={"navProductsPigmentsSubMenuKey"} 
															onClick={(e) => handleClickBU(e, 'pigments')}
														>
															{Language[props.currentLanguage].businessUnits.pigmentsAndDyes.pigments}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/products/binders" 
															className="submenuLink" 
															key={"navProductsResinsSubMenuKey"} 
															onClick={(e) => handleClickBU(e, 'binders')}
														>
															{Language[props.currentLanguage].businessUnits.binders.binders}
														</Link>
													</div>
												</>
											}
											<SubMenuHeader className="mt-4">
												<SubMenuHeaderLink 
													className="dropdown-toggle"
													onClick={(e) => updateSmallMenuState("isIndustriesMenuOpen", !smallMenuState.isIndustriesMenuOpen)}
												>
													{Language[props.currentLanguage].menu.industries.toUpperCase()}
												</SubMenuHeaderLink>
											</SubMenuHeader>
											{
												smallMenuState.isIndustriesMenuOpen &&
												<>
													<div className="mt-4">
														<Link 
															to="/industries/adhesives" 
															className="submenuLink" 
															key={"navIndustriesAdhesivesSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'adhesives')}
														>
															{Language[props.currentLanguage].adhesives}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/energystorage" 
															className="submenuLink" 
															key={"navIndustriesBatteriesSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'energystorage')}
														>
															{Language[props.currentLanguage].energyStorage}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/construction" 
															className="submenuLink" 
															key={"navIndustriesConstructionSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'construction')}
														>
															{Language[props.currentLanguage].construction}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/surfaceCoatings" 
															className="submenuLink" 
															key={"navIndustriesPaintsAndCoatingsSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'surfaceCoatings')}
														>
															{Language[props.currentLanguage].surfaceCoatings}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/paper" 
															className="submenuLink" 
															key={"navIndustriesPaperSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'paper')}
														>
															{Language[props.currentLanguage].paper}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/plastics" 
															className="submenuLink" 
															key={"navIndustriesPlasticsSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'plastics')}
														>
															{Language[props.currentLanguage].plastics}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/printingInk" 
															className="submenuLink" 
															key={"navIndustriesPrintingInkSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'printingInk')}
														>
															{Language[props.currentLanguage].printingInk}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/rubber" 
															className="submenuLink" 
															key={"navIndustriesRubberSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'rubber')}
														>
															{Language[props.currentLanguage].rubber}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/sealants" 
															className="submenuLink" 
															key={"navIndustriesSealantsSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'sealants')}
														>
															{Language[props.currentLanguage].sealants}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/products/personalcare" 
															className="submenuLink" 
															key={"navProductsPersonalCareSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'personalcare')}
														>
															{Language[props.currentLanguage].personalCare}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/energystorage" 
															className="submenuLink" 
															key={"navIndustriesBatteriesSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'energystorage')}
														>
															{Language[props.currentLanguage].energyStorage}
														</Link>
													</div>
													<div className="mt-4">
														<Link 
															to="/industries/moreIndustries" 
															className="submenuLink" 
															key={"navIndustriesRubberSubMenuKey"} 
															onClick={(e) => handleClickIndustry(e, 'moreIndustries')}
														>
															{Language[props.currentLanguage].other}
														</Link>
													</div>
												</>
											}
											<SubMenuHeader className="mt-4">
												<SubMenuHeaderLink 
													className="dropdown-toggle"
													onClick={(e) => updateSmallMenuState("isLocationsMenuOpen", !smallMenuState.isLocationsMenuOpen)}
												>
													{Language[props.currentLanguage].locations.toUpperCase()}
												</SubMenuHeaderLink>
											</SubMenuHeader>
											{
												smallMenuState.isLocationsMenuOpen &&
												<>
													<div className="mt-4">
														<Link 
															to="/locations" 
															className="submenuLink" 
															key={"navLocationsSeeAllSubMenuKey"} 
															onClick={handleClickLocations}
														>
															{Language[props.currentLanguage].seeAllLocations}
														</Link>
													</div>
												</>
											}
										</div>
									</div>
									<div className="row d-none d-lg-flex">
										<div className="col-2">
											<div className="row">
												<div className="col">
													<SubMenuHeader>{Language[props.currentLanguage].menu.company.toUpperCase()}</SubMenuHeader>
												</div>
											</div>
											<div className="row mt-5">
												<div className="col">
													<Link 
														to="/company" 
														className="submenuLink" 
														key={"navCompanyCompanySubMenuKey"} 
														onClick={handleClickCompany}
													>
														{Language[props.currentLanguage].menu.company}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/history" 
														className="submenuLink" 
														key={"navCompanyHistorySubMenuKey"} 
														onClick={handleClickHistory}
													>
														{Language[props.currentLanguage].menu.history}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/jobs" 
														className="submenuLink" 
														key={"navCompanyJobsSubMenuKey"} 
														onClick={handleClickJobs}
													>
														{Language[props.currentLanguage].menu.jobs}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/laboratories" 
														className="submenuLink" 
														key={"navCompanyLaboratoriesSubMenuKey"} 
														onClick={handleClickLab}
													>
														{Language[props.currentLanguage].menu.lab}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<a 
														className="submenuLink" 
														rel="noopener noreferrer" 
														href="https://grolman.earth" 
														target="_blank" 
														onClick={handleEarthClick}
													>
														{Language[props.currentLanguage].menu.visionAndSustainability}
													</a>
												</div>
											</div>
										</div>
										<div className="col-2 borderLeft">
											<div className="row">
												<div className="col">
													<SubMenuHeader>{Language[props.currentLanguage].menu.products.toUpperCase()}</SubMenuHeader>
												</div>
											</div>
											<div className="row mt-5">
												<div className="col">
													<Link 
														to="/products/additives" 
														className="submenuLink" 
														key={"navProductsAdditivesSubMenuKey"} 
														onClick={(e) => handleClickBU(e, 'additives')}
													>
														{Language[props.currentLanguage].businessUnits.additives.additives}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/products/fillers" 
														className="submenuLink" 
														key={"navProductsFillersSubMenuKey"} 
														onClick={(e) => handleClickBU(e, 'fillers')}
													>
														{Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.fillers}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<a 
														key={"navProductsPersonalCareSubMenuKey"} 
														className="submenuLink" 
														rel="noopener noreferrer" 
														onClick={handlePersonalCareLinkClick} 
														href={'https://grolman-personalcare.com'} 
														target="_blank"
													>
														{Language[props.currentLanguage].personalCare}
													</a>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/products/pigments" 
														className="submenuLink" 
														key={"navProductsPigmentsSubMenuKey"} 
														onClick={(e) => handleClickBU(e, 'pigments')}
													>
														{Language[props.currentLanguage].businessUnits.pigmentsAndDyes.pigments}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/products/binders" 
														className="submenuLink" 
														key={"navProductsResinsSubMenuKey"} 
														onClick={(e) => handleClickBU(e, 'binders')}
													>
														{Language[props.currentLanguage].businessUnits.binders.binders}
													</Link>
												</div>
											</div>
										</div>
										<div className="col-2 borderLeft">
											<div className="row">
												<div className="col">
													<SubMenuHeader>{Language[props.currentLanguage].menu.industries.toUpperCase()}</SubMenuHeader>
												</div>
											</div>
											<div className="row mt-5">
												<div className="col">
													<Link 
														to="/industries/adhesives" 
														className="submenuLink" 
														key={"navIndustriesAdhesivesSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'adhesives')}
													>
														{Language[props.currentLanguage].adhesives}
													</Link>
												</div>
											</div>			
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/industries/construction" 
														className="submenuLink" 
														key={"navIndustriesConstructionSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'construction')}
													>
														{Language[props.currentLanguage].construction}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/industries/energystorage" 
														className="submenuLink" 
														key={"navIndustriesBatteriesSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'energystorage')}
													>
														{Language[props.currentLanguage].energyStorage}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/industries/surfaceCoatings" 
														className="submenuLink" 
														key={"navIndustriesPaintsAndCoatingsSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'surfaceCoatings')}
													>
														{Language[props.currentLanguage].surfaceCoatings}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/industries/paper" 
														className="submenuLink" 
														key={"navIndustriesPaperSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'paper')}
													>
														{Language[props.currentLanguage].paper}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/products/personalcare" 
														className="submenuLink" 
														key={"navProductsPersonalCareSubMenuKey"} 
														onClick={(e) => handleClickBU(e, 'personalcare')}
													>
														{Language[props.currentLanguage].personalCare}
													</Link>
												</div>
											</div>
										</div>
										<div className="col-2">
											<div className="row">
												<div className="col">
													<InvisibleSubMenuHeader>
														&nbsp;
													</InvisibleSubMenuHeader>
												</div>
											</div>
											<div className="row mt-5">
												<div className="col">
													<Link 
														to="/industries/plastics" 
														className="submenuLink" 
														key={"navIndustriesPlasticsSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'plastics')}
													>
														{Language[props.currentLanguage].plastics}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/industries/printingInk" 
														className="submenuLink" 
														key={"navIndustriesPrintingInkSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'printingInk')}
													>
														{Language[props.currentLanguage].printingInk}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/industries/rubber" 
														className="submenuLink" 
														key={"navIndustriesRubberSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'rubber')}
													>
														{Language[props.currentLanguage].rubber}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/industries/sealants" 
														className="submenuLink" 
														key={"navIndustriesSealantsSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'sealants')}
													>
														{Language[props.currentLanguage].sealants}
													</Link>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col">
													<Link 
														to="/industries/moreIndustries" 
														className="submenuLink" 
														key={"navIndustriesMoreIndustriesSubMenuKey"} 
														onClick={(e) => handleClickIndustry(e, 'moreIndustries')}
													>
														{Language[props.currentLanguage].moreIndustries}
													</Link>
												</div>
											</div>
										</div>
										<div className="col-2 borderLeft">
											<div className="row">
												<div className="col">
													<SubMenuHeader>{Language[props.currentLanguage].countries}</SubMenuHeader>
												</div>
											</div>
											<div className="row mt-5">
												<div className="col">
													<Link 
														to="/locations" 
														className="submenuLink" 
														key={"navLocationsSeeAllSubMenuKey"} 
														onClick={handleClickLocations}
													>
														{Language[props.currentLanguage].seeAllLocations}
													</Link>
												</div>
											</div>
										</div>
									</div>
									<div className="row mt-5">
										<div className="col-12">
											<span className="navbar-text text-center fontSizeXSmall">
												<form onSubmit={(event) => handleSearchSubmit(event)}>
													<div className="input-group">
														<span 
															className="input-group-text" 
															id="searchIcon"
															onClick={(event) => {
																setIsSearchTextFieldVisible(!isSearchTextFieldVisible);
															}}
														>
															<ImageColumn
																backgroundImage={searchIcon}
																imageSize={"20px"}
															/>
														</span>
														<SearchTextInputField 
															isVisible={isSearchTextFieldVisible}
															type="text" 
															className="form-control" 
															placeholder="Search" 
															aria-label="Search" 
															aria-describedby="searchIcon"
															ref={searchMenuRef}
															onBlur={(event) => setIsSearchTextFieldVisible(false)}
														/>
													</div>
												</form>
											</span>
											<LanguagePickerSpan className="navbar-text text-center fontSizeXSmall d-flex justify-content-center">
												{
													props.languagesToShow.map((language) => {
														return (
															<LanguageSelectionEntry 
																key={`${language}Selection`} 
																className={`cursorPointer`} 
																onClick={(e) => {
																	handleClickLanguage(e, language);
																	document.getElementById("hamburgerButton").click();
																}} 
																isActiveLanguage={props.currentLanguage === language}
															>
																{ `${language.toUpperCase()}` }
															</LanguageSelectionEntry>
														);
													})
												}
											</LanguagePickerSpan>
										</div>
									</div>
									<div className="row mt-5">
										<div className="col-12 col-md-9 d-flex justify-content-center justify-content-md-start align-items-end">
											<GetInTouchDiv>
												Get in touch
												<span> </span>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
													<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
												</svg>
											</GetInTouchDiv>
											<div className="ml-3">
												<ExternalLink
													{...props}
													key={"socialMediaFooterLinkedIn"}
													id={"socialMediaFooterLinkedIn"}
													linkHrefTag={"https://www.linkedin.com/company/grolmangroup" }
													linkTargetTag={"_blank"}
													linkAdditionalClasses={"footerLink"}
													imageSrcTag={linkedin}
													imageAltTag={"Follow us on LinkedIn"}
													imageWidth={"35px"}
													imageMinWidth={"35px"}
													imageMaxWidth={"35px"}
													imageMinHeight={"35px"}
													imageBackgroundColor={"#004839"}
													imageIsMaskImage={true}
													iconWidth={"25px"}
													iconHeight={"25px"}
													iconPositonRight={"-7px"}
													iconPositonBottom={"-7px"}
													maskColor={"#004839"}
												/>
											</div>
											<div className="ml-3">
												<ExternalLink
													{...props}
													key={"socialMediaFooterFacebook"}
													id={"socialMediaFooterFacebook"}
													linkHrefTag={"https://www.facebook.com/grolmangroup" }
													linkTargetTag={"_blank"}
													linkAdditionalClasses={"footerLink"}
													imageSrcTag={facebook}
													imageAltTag={"Follow us on Facebook"}
													imageWidth={"35px"}
													imageMinWidth={"35px"}
													imageMaxWidth={"35px"}
													imageMinHeight={"35px"}
													imageBackgroundColor={"#004839"}
													imageIsMaskImage={true}
													iconWidth={"25px"}
													iconHeight={"25px"}
													iconPositonRight={"-7px"}
													iconPositonBottom={"-7px"}
													maskColor={"#004839"}
												/>
											</div>
											<div className="ml-3">
												<ExternalLink
													{...props}
													key={"socialMediaFooterInstagram"}
													id={"socialMediaFooterInstagram"}
													linkHrefTag={"https://www.instagram.com/grolmangroup/" }
													linkTargetTag={"_blank"}
													linkAdditionalClasses={"footerLink"}
													imageSrcTag={instagram}
													imageAltTag={"Follow us on Instagram"}
													imageWidth={"35px"}
													imageMinWidth={"35px"}
													imageMaxWidth={"35px"}
													imageMinHeight={"35px"}
													imageBackgroundColor={"#004839"}
													imageIsMaskImage={true}
													iconWidth={"25px"}
													iconHeight={"25px"}
													iconPositonRight={"-7px"}
													iconPositonBottom={"-7px"}
													maskColor={"#004839"}
												/>
											</div>
										</div>
										<div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-end align-items-end">
											<GrowNew
												src={growNewImage}
												alt="Grow New"
												className="img-fluid"
											/>
										</div>
									</div>
									<MenuCloseIcon
										onClick={(e) => setIsMenuOpen(!isMenuOpen)}
									>
										<svg xmlns="http://www.w3.org/2000/svg" id="Ebene_1" viewBox="0 0 38.2 38.2" fill="currentColor"><polygon points="38.2 36.8 20.5 19.1 38.2 1.4 36.8 0 19.1 17.7 1.4 0 0 1.4 17.7 19.1 0 36.8 1.4 38.2 19.1 20.5 36.8 38.2 38.2 36.8"></polygon>
										</svg>
									</MenuCloseIcon>
								</MenuOpen>
							}
							{/*
							<nav className="navbar navbar-expand-xl navbar-light text-center">			
								<Link 
									to="/" 
									className="navbar-brand" 
									onClick={handleClickLogo} 
									key={"navLogoKey"}
								>
									<Logo 
										id="ggSiteLogo" 
										src={logo} 
										className="img-fluid" 
										alt="Logo" 
										isNavBarSmall={isNavBarSmall}
									/>
								</Link>
								<button 
									id="hamburgerButton"
									className="navbar-toggler" 
									type="button" 
									data-toggle="collapse" 
									data-target="#navbarSupportedContent" 
									aria-controls="navbarSupportedContent" 
									aria-expanded="false" 
									aria-label="Toggle navigation"
								>
									<span className="navbar-toggler-icon"></span>
								</button>

								<div className={"collapse navbar-collapse paddingTop45px ml-3"} id="navbarSupportedContent">
									<ul className="navbar-nav mr-auto">
										<li className="nav-item dropdown active whiteSpaceNoWrap">
											<a className="nav-link dropdown-toggle imageLink animated" id="navbarDropdownCompany" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												{Language[props.currentLanguage].menu.company.toUpperCase()}
											</a>
											<div className="dropdown-menu whiteSpaceNoWrap" aria-labelledby="navbarDropdownCompany">
												<Link 
													to="/history" 
													className="nav-link imageLink animated" 
													key={"navHistoryKey"} 
													onClick={handleClickHistory}
												>
													{Language[props.currentLanguage].menu.history.toUpperCase()}
												</Link>
												<Link 
													to="/jobs" 
													className="nav-link imageLink animated" 
													onClick={handleClickJobs} 
													key={"navJobsKey"}
												>
													{Language[props.currentLanguage].menu.jobs.toUpperCase()}
												</Link>
											</div>
										</li>
										
										<li className="nav-item active dropdown whiteSpaceNoWrap">
											<div className="nav-link dropdown-toggle imageLink animated cursorPointer" href="#" id="navbarDropdownProducts" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												{Language[props.currentLanguage].menu.products.toUpperCase()}
											</div>
											<div className="dropdown-menu whiteSpaceNoWrap" aria-labelledby="navbarDropdownProducts">
												{
													buList.map((bu) => {
														if(bu.showLink) {
															return (
																<a 
																	key={"navigation" + bu.name + "Key"} 
																	className="nav-link imageLink animated" 
																	rel="noopener noreferrer" 
																	onClick={handlePersonalCareLinkClick} 
																	href={bu.link} 
																	target="_blank"
																>
																	{bu.name}
																</a>
															);
														}
														else {
															return (
																<Link 
																	key={`navigation${bu.name}Key`} 
																	to={`/products/${bu.bu}`} 
																	className="nav-link dropdown-item imageLink animated" 
																	onClick={(e) => handleClickBU(e, bu.bu)}
																>
																	{bu.name}
																</Link>
															);
														}	
													})
												}	
											</div>
										</li>
										<li className="nav-item active dropdown whiteSpaceNoWrap">
											<div className="cursorPointer nav-link dropdown-toggle imageLink animated" id="navbarDropdownIndustries" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												{Language[props.currentLanguage].menu.industries.toUpperCase()}
											</div>
											<div className="dropdown-menu whiteSpaceNoWrap" aria-labelledby="navbarDropdownIndustries">
												{
													industriesList.map((industry) => {
														if(industry.showLink) {
															return (
																<a 
																	key={`navigation${industry.name}Key`} 
																	className="nav-link imageLink animated" 
																	rel="noopener noreferrer" 
																	href={industry.link} 
																	target="_blank"
																>
																	{industry.name}
																</a>
															);
														}
														else {
															return (
																<Link 
																	key={`navigation${industry.name}Key`} 
																	to={`/industries/${industry.industry}`} 
																	className="nav-link dropdown-item imageLink animated" 
																	onClick={(e) => handleClickIndustry(e, industry.industry)}
																>
																	{industry.name}
																</Link>
															);
														}	
													})
												}
											</div>
										</li>
										<li className="nav-item active dropdown whiteSpaceNoWrap">
											<a className="nav-link dropdown-toggle imageLink animated" id="navbarDropdownCountry" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												{
													props.currentCountry != null && props.currentCountry !== 'grolmanGroup' &&
													<span className={`mr-1 flagSelect fi ${Country[props.currentCountry].flag}`} />
												}
												{props.currentCountry != null && props.currentCountry !== 'grolmanGroup' ? Country[props.currentCountry].name[props.currentLanguage].toUpperCase() : Language[props.currentLanguage].locations.toUpperCase()}
											</a>
											<div className="dropdown-menu overflowYScrollHeight400px" aria-labelledby="navbarDropdownCountry">
												<HashLink 
													to="/products" 
													className={"dropdown-item imageLink animated"} 
													onClick={(e) => handleClickCountry(e, "grolmanGroup")} 
													key={"navgrolmanGroup"}
												>
													{Country["grolmanGroup"].name[props.currentLanguage].toUpperCase()}
												</HashLink> 
												{
													countryList.map((countryElement) => {
														if(countryElement.showCountry === true && countryElement.key !== "grolmanGroup") {
															return (
																<HashLink 
																	to="/products" 
																	className={"dropdown-item imageLink animated"}
																	onClick={(e) => handleClickCountry(e, countryElement.key)} 
																	key={"nav" + countryElement.key}
																>
																	<span className={`mr-1 flagSelect fi ${countryElement.flag}`} />
																	{countryElement.name[props.currentLanguage].toUpperCase()}
																</HashLink> 
															);
														}
														else {
															return undefined;
														}
													}).filter(country => country !== undefined)
												}
											</div>
										</li>
									</ul>
									<span className="navbar-text text-center fontSizeXSmall">
										<form onSubmit={(event) => handleSearchSubmit(event)}>
											<div className="input-group">
												<span 
													className="input-group-text" 
													id="searchIcon"
													onClick={(event) => {
														setIsSearchTextFieldVisible(!isSearchTextFieldVisible);
													}}
												>
													<ImageColumn
														backgroundImage={searchIcon}
														imageSize={"20px"}
													/>
												</span>
												<SearchTextInputField 
													isVisible={isSearchTextFieldVisible}
													type="text" 
													className="form-control" 
													placeholder="Search" 
													aria-label="Search" 
													aria-describedby="searchIcon"
													ref={searchRef}
													onBlur={(event) => setIsSearchTextFieldVisible(false)}
												/>
											</div>
										</form>
									</span>
									<span className="navbar-text text-center fontSizeXSmall">
										LANGUAGE<br />
										{
											props.languagesToShow.map((language) => {
												return (
													<div 
														key={`${language}Selection`} 
														className={`cursorPointer flagSelect fi fi-${Language[language].flag}`} 
														onClick={(e) => {
															handleClickLanguage(e, language);
															document.getElementById("hamburgerButton").click();
														}} 
														alt={Language[language].languageName}
													/>
												);
											})
										}
									</span>
								</div>
							</nav>
							*/}
						</div>
					</div>
				</NavBarContainer>
				<div className="navigation">
				</div>
			</div>
		</>
	);
}

export default Navigation;