import React, { Component } from 'react';
import pdf from '../images/pdf.svg';
import styled from 'styled-components';

const PDFImage = styled.img`
    height:35px;
`


class TermsAndConditionsListEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        return (
            <div className="floating">
                <a href={this.props.termsAndCondition.pdfDocument} target="_blank" className="link">
                    <PDFImage src={pdf} alt=""></PDFImage><br />
                    {this.props.termsAndCondition.language}
                </a>
            </div>
        );
    }
}

export default TermsAndConditionsListEntry;
