import * as BusinessUnits from '../constants/businessUnits';

export function getVisibleBusinessUnits(mainProductGroups) {
    let mainPGAdditives = mainProductGroups?.filter((mainProductGroup) => mainProductGroup.buId === BusinessUnits.ADDITIVES);
    let mainPGBinders = mainProductGroups?.filter((mainProductGroup) => mainProductGroup.buId === BusinessUnits.BINDERS);
    let mainPGFillers = mainProductGroups?.filter((mainProductGroup) => mainProductGroup.buId === BusinessUnits.FILLERS);
    let mainPGPigments = mainProductGroups?.filter((mainProductGroup) => mainProductGroup.buId === BusinessUnits.PIGMENTS);
    return {
        isBusinessUnitAdditivesVisible: (mainPGAdditives?.length > 0) ? true : false,
        isBusinessUnitBindersVisible: (mainPGBinders?.length > 0) ? true : false,
        isBusinessUnitFillersVisible: (mainPGFillers?.length > 0) ? true : false,
        isBusinessUnitPigmentsVisible: (mainPGPigments?.length > 0) ? true : false
    }
}