import React from 'react';
import _ from 'lodash';
import { isNullOrUndefined, replaceSystemNewLineCharactersWithHtml } from '../utils/utils';


export const HighlightText = ({text = '', highlight = ''}) => {
    if(!isNullOrUndefined(text)) {
        if (!highlight.trim()) {
            return <span>{text}</span>
        }
        const regexMarkText = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
        const parts = text.split(regexMarkText)
        return (
            <span>
                {
                    parts.filter(part => part).map((part, i) => (
                        regexMarkText.test(part) ? 
                            (
                                <mark key={i}>
                                    {
                                        part
                                    }
                                </mark>
                            ) : 
                            (
                                <span key={i}>
                                    {
                                        part
                                    }
                                </span>
                            )
                    ))
                }
            </span>
        )
    }
 }

 export default HighlightText;