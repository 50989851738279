import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Language from './Language.data';
import EmailAddress from './EmailAddress.component';
import { base64toBlob } from '../utils/utils';

import pdfIcon from '../images/pdf.svg'

const PDFImage = styled.img`
    height:35px;
`
const CardHeader = styled.div`
    background-color: #006384 !important;
    color: #fff !important;
`
const Flag = styled.span`
    display: inline-block;
    width: 30px !important;
    height: 20px;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #eee;
    background-position: center;
`

const ColumnHeaderText = styled.span`
    color: #18657a;
    font-weight: 700;
`

const ButtonWithoutBorders = styled.button`
    background-color:transparent;
    border:0px;
`

function Job(props) {

    function openDocument(documentId) {
        window.open('/jobs/document/' + documentId + '/' + props.job.title.replaceAll(' ','').replaceAll('/',''), '_blank');
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center align-items-center h-100 marginBottom50px">
                <div className="offset-1 marginTop50px">
                </div>
                <div className="col-10 text-center justify-content-center">
                    <div className="card">
                        <CardHeader className="card-header" data-toggle="collapse" data-target={"#job" + props.job.id} aria-expanded="false" aria-controls={"job" + props.job.id}>
                            <div className="text-center cursorPointer">
                                <Flag className={"fi " + props.job.country.flag.replace("flag-icon-","fi-")}></Flag> {props.job.country.name + ((props.job.location !== null && props.job.location.length > 0) ? ', ' + props.job.location : '')}
                            </div>
                            <h5 className="card-title cursorPointer mainProductGroupTitle">
                                {props.job.title}
                                <br /> 
                                {Language["en"].jobsSection.mfd}
                            </h5>
                        </CardHeader>
                        <div className="card-body borderBottom1pxSolid888 padding5px collapse" id={"job" + props.job.id}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 text-left">
                                    <ColumnHeaderText>
                                        {
                                            props.job?.job_texts?.find((jobText) => jobText.column_no === 1 && jobText.is_header === 1)?.text
                                        }
                                    </ColumnHeaderText>
                                    <ul>
                                        {
                                            props.job?.job_texts?.filter((jobText) => jobText.column_no === 1 && jobText.is_header === 0).map((jobText) => {
                                                return (
                                                    <li key={"jobText" + jobText.id}>
                                                        {
                                                            jobText.text
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>                       
                                <div className="col-xs-12 col-sm-12 col-md-6 text-left">
                                    <ColumnHeaderText>
                                        {
                                            props.job?.job_texts?.find((jobText) => jobText.column_no === 2 && jobText.is_header === 1)?.text
                                        }
                                    </ColumnHeaderText>
                                    <ul>
                                        {
                                            props.job?.job_texts?.filter((jobText) => jobText.column_no === 2 && jobText.is_header === 0).map((jobText) => {
                                                return (
                                                    <li key={"jobText" + jobText.id}>
                                                        {
                                                            jobText.text
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {Language["en"].jobsSection.findDetailsInPDF + ':'}
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                {
                                    props.job?.job_files?.map((jobFile) => {
                                        return (
                                            <div key={"jobFile" + jobFile.id} className="col justify-content-center">
                                                <ButtonWithoutBorders onClick={(e) => openDocument(jobFile.id)} target="_blank" className="link">
                                                    <PDFImage src={pdfIcon} alt=""></PDFImage>
                                                </ButtonWithoutBorders>
                                                <br />
                                                <small>{jobFile.language.name}</small>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="row align-items-center h-100 marginBottom50px">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                    {Language["en"].jobsSection.footerText + ' '}
                                    <br />
                                    <EmailAddress key={"emailAddressKeyApplicants"} classNames={"link"} emailAddress={props.job?.send_email_to} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="offset-1 marginTop50px">
                </div>
            </div>
        </div>
    )
}

export default Job;