import React, { Component } from 'react';
import LoadingSpinner from './LoadingSpinner.component.js';
import TermsAndConditionsList from './TermsAndConditionsList.container';
import Language from './Language.data';
import $ from "jquery";
require('bootstrap');
let smoothScroll = require('smoothscroll');

class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            termsAndConditions: [],
            isLoading: true,
        };

        this.loadTermsAndConditions = this.loadTermsAndConditions.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadTermsAndConditions();
    }    

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.currentLanguage !== this.props.currentLanguage) {
            this.loadTermsAndConditions();
        } 
    }

    handleCountryChange = (country) => {
        let destination = document.getElementById(country);
        smoothScroll(destination);
        //window.location.href = '#' + country;
    }

    loadTermsAndConditions()
    {
        this.setState({
            isLoading: true
        });
        window.axios.post(window.location.origin + '/legal/termsAndConditions', {
            requestType: 'getTermsAndConditions',
            languageID: Language[this.props.currentLanguage].languageNo,
        })
        .then((response) => {
            if(response.data?.success) {
                this.setState({
                    termsAndConditions: response.data.termsAndConditions,
                    isLoading: false,
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    render() {
        let countriesWithTermsOfConditions = [];
        this.state.termsAndConditions.forEach((toc) => {
            if(countriesWithTermsOfConditions.indexOf(toc.countryHash) === -1) {
                countriesWithTermsOfConditions.push(toc.countryHash.toLowerCase());
            }
        });
        return (
            <div className="container-fluid marginTop50px marginBottom50px area">
                <div className="row marginTop50px">
                    <div className="col-12 text-center">
                        <h2>{Language[this.props.currentLanguage].termsAndConditions}</h2>
                    </div>
                </div>
                {/*
                // DN: Buggy due to providing the countriesToShow property
                
                <div className="row marginTop50px marginBottom50px upper">
                    <div className="col-12 text-center">
                        <CountrySelection countriesToShow={countriesWithTermsOfConditions} showSpacing={false} currentLanguage={this.props.currentLanguage} country={this.props.country} onCountryChange={this.handleCountryChange.bind(this)} />
                    </div>
                </div>
                */}
                <div className="row lower">
                    <div className="col-12 text-center">
                        <div>
                            {
                                this.state.isLoading && 
                                <LoadingSpinner key="loadingSpinnerTermsAndConditions"></LoadingSpinner>
                            }    
                            {
                                !this.state.isLoading &&
                                <TermsAndConditionsList currentLanguage={this.props.currentLanguage} termsAndConditions={this.state.termsAndConditions} key={"termsAndConditionsList"} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsAndConditions;