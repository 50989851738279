import React, { Component } from 'react';
import Language from './Language.data';
import image from '../images/carlsplatz.jpg';

class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="container marginTop50px marginBottom50px area" id="company">
                <div className="row marginTop50px">
                    <div className="col-12 text-center">
                        <h2>{Language[this.props.currentLanguage].company}</h2>
                    </div>
                </div>
                <div className="row marginTop50px">
                    <div className="col-12 text-center">
                        {Language[this.props.currentLanguage].companyText1}
                    </div>
                </div>
                <div className="row marginTop25px">
                    <div className="col-12 text-center">
                        {Language[this.props.currentLanguage].companyText2}
                    </div>
                </div>
                <div className="row marginTop25px">
                    <div className="col-12 text-center">
                        <strong>{Language[this.props.currentLanguage].togetherWeGrowNew}</strong>
                        <br/>
                        <strong>{Language[this.props.currentLanguage].grolmanQualitySince1855}</strong>
                    </div>
                </div>
                <div className="row marginTop25px">
                    <div className="col"></div>
                    <div className="col-10 text-center">
                        <img src={image} alt="Grolman. Quality since 1855" className="img-fluid"></img>
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        );
    }
}

export default Company;