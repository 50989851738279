export function addMatomoScriptTag(isTrackingActive) {
    if(document.querySelector("script[src='//matomo.grolman-group.com/matomo.js']") === null) {
        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        // Call disableCookies before calling trackPageView 
        _paq.push(['disableCookies']);
        // require user tracking consent before processing data
        //_paq.push(['requireConsent']);
        // OR require user cookie consent before storing and using any cookies
        //_paq.push(['requireCookieConsent']);
        
        _paq.push(['trackPageView']);
        _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
        _paq.push(["setCookieDomain", "*.www.grolman-group.com"]);
        //_paq.push(['rememberCookieConsentGiven', 4320]);
        if(isTrackingActive) {
            _paq.push(['forgetUserOptOut']);
        }
        else {
            _paq.push(['optUserOut']);
        }
        //_paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
            var u="//matomo.grolman-group.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
    }
}

export function removeMatomoScriptTag() {
    return new Promise((resolve, reject) => {
        try {
            if(document.querySelector("script[src='//matomo.grolman-group.com/matomo.js']") !== null) {
                document.querySelector("script[src='//matomo.grolman-group.com/matomo.js']").remove();
                resolve(true);
            }
            else {
                resolve(false);
            }
        }
        catch(exception) {
            reject(exception);
        }
    });
}

export function trackEvent(eventCategory, eventAction, eventName = null, eventValue = null) {
    if(window._paq) {
        if(eventValue !== null) {
            window._paq.push(['trackEvent', eventCategory, eventAction, eventName, eventValue]);
        }
        else {
            window._paq.push(['trackEvent', eventCategory, eventAction, eventName]);
        }
    }
}