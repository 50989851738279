import React, { Component } from 'react';
import Language from './Language.data';
import Address from './Address.component';
import EmailAddress from './EmailAddress.component';
import LoadingSpinner from './LoadingSpinner.component.js';
import externalLinkImage from '../images/externalLink.svg';
require('bootstrap');

class Imprint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            legalInfo: '',
            isLoading: true,
            imprint: [],
        };

        this.reloadLegalText = this.reloadLegalText.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.reloadLegalText();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.currentLanguage !== this.props.currentLanguage) {
            if(!this.state.isLoading) {
                this.setState({
                    isLoading: true,
                });
                this.reloadLegalText();
            }
        }
    }

    replaceExternalLinks(text) {
        return text.replace('[extern]','<img src="' + externalLinkImage + '" alt="External Link" class="externalLinkImage"></img>');
    }

    reloadLegalText() {
        this.setState({
            isLoading: true
        },() => {
            window.axios.post(window.location.origin + '/legal/legalInfo', {
                requestType: 'legalInfo',
                countryID: this.props.country.countryNo,
                languageID: Language[this.props.currentLanguage].languageNo,
            })
            .then((response) => {
                if(response.data?.success) {
                    let text = this.replaceExternalLinks(response.data.legalInfo?.legalInfo);
                    this.setState({
                        legalInfo: text,
                        isLoading: false,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        });
    }

    render() {
        return (
            <div className="container marginTop50px marginBottom50px area" id="imprint">
                <div className="row marginTop50px">
                    <div className="col-12 text-center">
                        <h2>{Language[this.props.currentLanguage].imprint}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 text-center">
                    {
                        this.props.imprint !== undefined && this.props.imprint.company !== undefined &&
                        <Address key={"addressKey"} country={this.props.imprint.company} />
                    }
                    </div>
                </div>
                <div className="row">
                    <div className="d-none d-md-block col-md-6 text-right">
                        <strong>{Language[this.props.currentLanguage].vatRegistrationNumber}</strong>
                    </div>
                    <div className="col-12 d-block d-md-none text-center">
                        <strong>{Language[this.props.currentLanguage].vatRegistrationNumber}</strong>
                    </div>
                    <div className="d-none d-md-block col-md-6 text-left">
                        {
                            this.props.imprint !== undefined && this.props.imprint.company !== undefined &&
                            this.props.imprint.company.vatNo
                        }
                    </div>
                    <div className="col-12 d-block d-md-none text-center">
                        {
                            this.props.imprint !== undefined && this.props.imprint.company !== undefined &&
                            this.props.imprint.company.vatNo
                        }
                    </div>
                </div>
                <div className="row">
                    {
                        (this.props.country.countryNo === 61 || this.props.country.countryNo === 63) &&
                        <>
                            <div className="d-none d-md-block col-md-6 text-right">
                                <strong>{Language[this.props.currentLanguage].taxRegistrationNumber}</strong>
                            </div>
                            <div className="col-12 d-block d-md-none text-center marginTop25px">
                                <strong>{Language[this.props.currentLanguage].taxRegistrationNumber}</strong>
                            </div>
                        </>
                    }
                    {
                        this.props.country.countryNo !== 61 && this.props.country.countryNo !== 63 &&
                        <>
                            <div className="d-none d-md-block col-md-6 text-right">
                                <strong>{Language[this.props.currentLanguage].registrationOffice}</strong>
                            </div>
                            <div className="col-12 d-block d-md-none text-center marginTop25px">
                                <strong>{Language[this.props.currentLanguage].registrationOffice}</strong>
                            </div>
                        </>
                    }
                    <div className="d-none d-md-block col-md-6 text-left">
                    {
                        this.props.imprint !== undefined && this.props.imprint.company !== undefined &&
                        this.props.imprint.company.registrationOffice
                    }
                    </div>
                    <div className="col-12 d-block d-md-none text-center">
                    {
                        this.props.imprint !== undefined && this.props.imprint.company !== undefined &&
                        this.props.imprint.company.registrationOffice
                    }
                    </div>
                </div>
                {
                    this.props.imprint !== undefined && this.props.imprint.alternateImprintText !== undefined && this.props.imprint.alternateImprintText.length > 0 &&
                    <div className="row">
                        <div className="col-12 col-md-6 text-right">
                            
                        </div>
                        <div className="col-12 col-md-6 text-left">
                            {this.props.imprint.alternateImprintText}
                        </div>
                    </div>
                }

                <div className="row marginTop25px">
                    <div className="col-12 text-center">
                        <div className="row">
                            <div className="d-none d-md-block col-md-6 text-right">
                                <strong>
                                    {Language[this.props.currentLanguage].managingDirectors}
                                </strong>
                            </div>
                            <div className="col-12 d-block d-md-none text-center">
                                <strong>
                                    {Language[this.props.currentLanguage].managingDirectors}
                                </strong>
                            </div>
                            <div className="d-none d-md-block col-md-6 text-left">
                            {
                                this.props.imprint.managingDirectors !== undefined && this.props.imprint.managingDirectors.map((managingDirector) => {
                                    return (
                                        <div key={"managingDirector1" + managingDirector.id}>
                                            {managingDirector.titel !== null ? `${managingDirector.titel} ${managingDirector.name}` : `${managingDirector.name}`}
                                        </div>
                                    );
                                })
                            }
                            </div>
                            <div className="col-12 d-block d-md-none text-center">
                            {
                                this.props.imprint.managingDirectors !== undefined && this.props.imprint.managingDirectors.map((managingDirector) => {
                                    return (
                                        <div key={"managingDirector2" + managingDirector.id}>
                                            {managingDirector.name}
                                        </div>
                                    );
                                })
                            }
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-none d-md-block col-md-6 text-right">
                                <strong>{Language[this.props.currentLanguage].editor}</strong>
                            </div>
                            <div className="col-12 d-block d-md-none text-center marginTop25px">
                                <strong>{Language[this.props.currentLanguage].editor}</strong>
                            </div>
                            <div className="d-none d-md-block col-md-6 text-left">
                                <EmailAddress key={"emailAddressKeyAdministration"} classNames="link" emailAddress={"administration@grolman-group.com"} />
                            </div>
                            <div className="col-12 d-block d-md-none text-center">
                                <EmailAddress key={"emailAddressKeyAdministration"} classNames="link" emailAddress={"administration@grolman-group.com"} />
                            </div>
                        </div>
                        <div className="row marginTop50px" id="legalInformation">
                            <div className="col-12 text-center">
                                <strong>{Language[this.props.currentLanguage].legalInformation}</strong>
                            </div>
                            <div className="col-12 text-center">
                                {
                                    this.state.isLoading && 
                                    <LoadingSpinner key="loadingSpinnerImprint"></LoadingSpinner>
                                }    
                                {
                                    !this.state.isLoading &&
                                    <div dangerouslySetInnerHTML={{__html: this.state.legalInfo}}></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Imprint;