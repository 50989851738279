import React, { useState,useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Footer from './components/Footer.component';
import Navigation from './components/Navigation.component';
import Products from './components/Products.container';
import Industries from './components/Industries.container';
import ContactList from './components/ContactList.container';
import Imprint from './components/Imprint.container';
import Privacy from './components/Privacy.container';
import TermsAndConditions from './components/TermsAndConditions.container';
import ScrollUp from './components/ScrollUp.component';
import Company from './components/Company.container';
import History from './components/History.container';
import Country from './components/Country.data';
import Language from './components/Language.data';
import MainPage from './components/MainPage.container';
import Jobs from './components/Jobs.container';
import Resources from './components/Resources.container';
import Podcast from './components/Podcast.component';
import Search from './components/Search.component';
import AllLocations from './components/AllLocations.component';
import PrivacyOptOut from './components/PrivacyOptOut.component';
import Error404 from './components/errors/404.component';
import locale2 from 'locale2';
import { getVisibleBusinessUnits } from './utils/businessUnit';
import styled from 'styled-components';
import { usePrevious } from './utils/utils';
import * as Tracking from './utils/matomo';
import Laboratory from './components/Laboratory.container';

const FullWidthContainer = styled.div`
    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
    }
` 

const WrapDiv = styled.div`
  @media (max-width: 576px) { 
    padding-left:3px;
    padding-right:3px;
    /*height: calc(100vh - 150px - 60px);*/
    text-align:center;
  }
` 

const language = locale2;

function App(props) {

  const languagesToShow = ['de','en'];

  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [currentBU, setCurrentBU] = useState('');
  const [currentIndustry, setCurrentIndustry] = useState('');
  const [showMainProductGroups, setShowMainProductGroups] = useState(false);
  const [showMainProductGroupDetails, setShowMainProductGroupDetails] = useState([]);
  const [showProductGroupDetails, setShowProductGroupDetails] = useState([]);
  const [mainProductGroups, setMainProductGroups] = useState([]);
  const [mainProductGroupsForIndustry, setMainProductGroupsForIndustry] = useState([]);
  const [showBUAdditivesInIndustries, setShowBUAdditivesInIndustries] = useState(false);
  const [showBUBindersInIndustries, setShowBUBindersInIndustries] = useState(false);
  const [showBUFillersInIndustries, setShowBUFillersInIndustries] = useState(false);
  const [showBUPigmentsInIndustries, setShowBUPigmentsInIndustries] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMainProductGroups, setIsLoadingMainProductGroups] =  useState(false);
  const [currentCountry, setCurrentCountry] = useState('grolmanGroup');
  const [countryObject, setCurrentCountryObject] = useState(Country['grolmanGroup']);
  const [contacts, setContacts] = useState([]);
  const [imprint, setImprint] = useState([]);
  const [optOutOptions, setOptOutOptions] = useState(undefined);
  const [hideOptOutTemporarily, setHideOptOutTemporarily] = useState(false);
  const [currentMainProductGroup, setCurrentMainProductGroup] = useState(null);

  const previousMainProductGroups = usePrevious(mainProductGroups);
  const previousShowMainProductGroupDetails = usePrevious(showMainProductGroupDetails);
  const previousLanguage = usePrevious(currentLanguage);

  const location = useLocation();
  const history = window.history;
  const navigate = useNavigate();

  const routes = [
    { 
      path: '/',
      exact: true,
      components: 
        /*
          <MainPage 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          contacts= {contacts} 
          onCountryChange={handleCountryChange} 
          onBUChange={handleBUChange}
          onIndustryChange={handleIndustryChange}
        />
        */
        <Products 
          ref={props.innerRef} 
          key="products" 
          isLoading={isLoading} 
          isLoadingMainProductGroups={isLoadingMainProductGroups}
          contacts={contacts} 
          country={countryObject} 
          currentLanguage={currentLanguage} 
          currentBU={currentBU} 
          showMainProductGroups={showMainProductGroups} 
          showMainProductGroupDetails={showMainProductGroupDetails} 
          showProductGroupDetails={showProductGroupDetails} 
          mainProductGroups={mainProductGroups} 
          onShowMainProductGroupDetailsChange={handleShowMainProductGroupDetailsChange} 
          onShowProductGroupDetailsChange={handleShowProductGroupDetailsChange} 
          onBUChange={handleBUChange} 
          onCountryChange={handleCountryChange} 
        />
    },
    {
      path: '/laboratories',
      exact: true,
      components: 
        <Laboratory  
          currentLanguage={currentLanguage} 
          key={"laboratories"} 
        />
    },
    { 
      path: '/contact',
      exact: true,
      components: 
        <ContactList  
          showCountrySelection={true} 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          contacts={contacts} 
          onCountryChange={handleCountryChange} 
          key={"contactList"} 
        />
    },
    { 
      path: '/products',
      exact: true,
      components:
        <Products 
          ref={props.innerRef} 
          key="products" 
          isLoading={isLoading} 
          isLoadingMainProductGroups={isLoadingMainProductGroups}
          contacts={contacts} 
          country={countryObject} 
          currentLanguage={currentLanguage} 
          currentBU={currentBU} 
          showMainProductGroups={showMainProductGroups} 
          showMainProductGroupDetails={showMainProductGroupDetails} 
          showProductGroupDetails={showProductGroupDetails} 
          mainProductGroups={mainProductGroups} 
          onShowMainProductGroupDetailsChange={handleShowMainProductGroupDetailsChange} 
          onShowProductGroupDetailsChange={handleShowProductGroupDetailsChange} 
          onBUChange={handleBUChange} 
          onCountryChange={handleCountryChange} 
        />
    },
    { 
      path: '/products/:bu',
      exact: true,
      components:
        <Products 
          ref={props.innerRef} 
          key="products" 
          isLoading={isLoading} 
          isLoadingMainProductGroups={isLoadingMainProductGroups}
          contacts={contacts} 
          country={countryObject} 
          currentLanguage={currentLanguage} 
          currentBU={currentBU} 
          showMainProductGroups={showMainProductGroups} 
          showMainProductGroupDetails={showMainProductGroupDetails} 
          showProductGroupDetails={showProductGroupDetails} 
          mainProductGroups={mainProductGroups} 
          onShowMainProductGroupDetailsChange={handleShowMainProductGroupDetailsChange} 
          onShowProductGroupDetailsChange={handleShowProductGroupDetailsChange} 
          onBUChange={handleBUChange} 
          onCountryChange={handleCountryChange} 
        />
    },
    { 
      path: '/industries',
      exact: true,
      components:
        <Industries 
          ref={props.innerRef} 
          key="industries" 
          contacts={contacts} 
          mainProductGroupsForIndustry={mainProductGroupsForIndustry} 
          showBUAdditives={showBUAdditivesInIndustries} 
          showBUBinders={showBUBindersInIndustries} 
          showBUFillers={showBUFillersInIndustries} 
          showBUPigments={showBUPigmentsInIndustries} 
          isLoading={isLoading} 
          isLoadingMainProductGroups={isLoadingMainProductGroups}
          country={countryObject} 
          currentLanguage={currentLanguage} 
          currentBU={currentBU} 
          showMainProductGroups={showMainProductGroups} 
          showMainProductGroupDetails={showMainProductGroupDetails} 
          showProductGroupDetails={showProductGroupDetails} 
          mainProductGroups={mainProductGroups} 
          onShowMainProductGroupDetailsChange={handleShowMainProductGroupDetailsChange} 
          onShowProductGroupDetailsChange={handleShowProductGroupDetailsChange} 
          onBUChange={handleBUChange} 
          onCountryChange={handleCountryChange} 
          currentIndustry={currentIndustry} 
          onIndustryChange={handleIndustryChange} 
        />
    },
    { 
      path: '/industries/:industry',
      exact: true,
      components:
        <Industries 
          ref={props.innerRef} 
          key="industries" 
          contacts={contacts} 
          mainProductGroupsForIndustry={mainProductGroupsForIndustry} 
          showBUAdditives={showBUAdditivesInIndustries} 
          showBUBinders={showBUBindersInIndustries} 
          showBUFillers={showBUFillersInIndustries} 
          showBUPigments={showBUPigmentsInIndustries} 
          isLoading={isLoading} 
          isLoadingMainProductGroups={isLoadingMainProductGroups}
          country={countryObject} 
          currentLanguage={currentLanguage} 
          currentBU={currentBU} 
          showMainProductGroups={showMainProductGroups} 
          showMainProductGroupDetails={showMainProductGroupDetails} 
          showProductGroupDetails={showProductGroupDetails} 
          mainProductGroups={mainProductGroups} 
          onShowMainProductGroupDetailsChange={handleShowMainProductGroupDetailsChange} 
          onShowProductGroupDetailsChange={handleShowProductGroupDetailsChange} 
          onBUChange={handleBUChange} 
          onCountryChange={handleCountryChange} 
          currentIndustry={currentIndustry} 
          onIndustryChange={handleIndustryChange} 
        />
    },
    { 
      path: '/imprint',
      exact: true,
      components:
        <Imprint 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          imprint={imprint} 
          key={"imprint"} 
        />
    },
    { 
      path: '/privacy',
      exact: true,
      components:
        <Privacy 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          key={"privacy"} 
          onShowOptOutOption={onShowOptOutOption}
        />
    },
    { 
      path: '/termsandconditions',
      exact: true,
      components:
        <TermsAndConditions 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          onCountryChange={handleCountryChange} 
          key={"termsAndConditions"} 
        />
    },
    { 
      path: '/company',
      exact: true,
      components:
        <Company 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          key={"company"} 
        />
    },
    { 
      path: '/history',
      exact: true,
      components:
        <History 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          key={"history"} 
        />
    },
    { 
      path: '/locations',
      exact: true,
      components:
        <AllLocations 
          currentLanguage={currentLanguage} 
          key={"locations"} 
        />
    },
    { 
      path: '/jobs',
      exact: true,
      components:
        <Jobs 
          currentLanguage={currentLanguage} 
          country={countryObject}
          key={"jobs"} 
        />
    },
    { 
      path: '/downloads',
      exact: true,
      components:
        <Resources
          currentLanguage={currentLanguage} 
          country={countryObject} 
          key={"resources"} 
        />
    },
    { 
      path: '/resources',
      exact: true,
      components:
        <Resources 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          key={"resources"} 
        />
    },
    { 
      path: '/podcast',
      exact: true,
      components:
        <Podcast 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          key={"podcast"} 
        />
    },
    { 
      path: '/search/',
      exact: true,
      components: 
        <Search 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          key={"search"} 
        />
    },
    { 
      path: '/search/:searchText',
      exact: true,
      components: 
        <Search 
          currentLanguage={currentLanguage} 
          country={countryObject} 
          key={"search"} 
        />
    },
    {
      path: 'errors/404',
      exact: true, 
      components: <Error404 />
    }
  ];

  const productsPages = [
    '/products/additives',
    '/products/pigments',
    '/products/fillers',
    '/products/binders',
    '/products/personalcare',
  ];
  
  const industryPages = [
    '/industries/surfaceCoatings',
    '/industries/adhesives',
    '/industries/printingInk',
    '/industries/rubber',
    '/industries/construction',
    '/industries/plastics',
    '/industries/paper',
    '/industries/sealants',
    '/industries/moreIndustries',
    '/industries/energystorage',
  ];

  function handleHistoryState(event) {
    if(event?.state?.businessUnit !== undefined) {
      handleBUChange(event.state.businessUnit,'products');
    }
    if(event?.state?.industry !== undefined) {
      handleIndustryChange(event.state.industry);
    }
  }

  useEffect(() => {
    async function initialize() {
      if(window.location.pathname === '/index.php') {
        window.location.href = '/';
      }
      window.addEventListener('popstate', (event) => handleHistoryState(event));
      window.addEventListener('click',checkPrivacyDecision);
      getPageToShow()
      .then((pageResult) => {
        if(pageResult !== null) {
          if(pageResult.businessUnit !== undefined) {
            handleBUChange(pageResult.businessUnit,'products'); 
          }
          else if(pageResult.industry !== undefined) {
            handleIndustryChange(pageResult.industry);
          }
        }
        //return getLanguage()
        return getCountry()
      });
      if(document.getElementById('errorCode')) {
        navigate("/errors/" + document.getElementById('errorCode').value);
      }
    }
    
    initialize();
  },[]);

  useEffect(() => {
    if(window.localStorage.getItem('showOptOutWindow') !== "false" && optOutOptions === undefined) {
      setOptOutOptions({
        showOptOut: true,
        language: currentLanguage
      });
    }
    else if (window.localStorage.getItem('showOptOutWindow') === "false" && optOutOptions !== undefined) {
      setOptOutOptions(undefined);
    }
  },[optOutOptions, hideOptOutTemporarily]);

  useEffect(() => {
    checkPrivacyDecision();
  },[location]);

  function checkPrivacyDecision() {
    if(hideOptOutTemporarily === true && window.location.pathname !== '/privacy') {
      setHideOptOutTemporarily(false);
    }
  }

  function getPageToShow() {
    return new Promise((resolve, reject) => {
      let returnValue = null;
      if(productsPages.indexOf(window.location.pathname) > -1) {
        let splitted = window.location.pathname.split('/');
        returnValue = { 
          type: 'businessUnit',
          businessUnit: splitted[splitted.length-1]
        };
      }
      if(industryPages.indexOf(window.location.pathname) > -1) {
        let splitted = window.location.pathname.split('/');
        returnValue = { 
          type: 'industry',
          industry: splitted[splitted.length-1]
        };
      }
      resolve(returnValue);
    });
  }

  function getCountry() {
    return new Promise((resolve, reject) => {
      const languageArray = language.split('-');
      let valueToCheck;
      if(languageArray.length > 1) { 
        valueToCheck = languageArray[1];
      }
      else {
        valueToCheck = languageArray[0];
      }
      let countryFromBrowser;
      countryFromBrowser = Object.keys(Country).forEach((countryKey) => {
        const country = Country[countryKey];
        if(country.iso === valueToCheck.toLowerCase()) {
          return countryKey;
        }
      });
      if(countryFromBrowser === undefined || countryFromBrowser.length === 0) {
        countryFromBrowser = 'grolmanGroup';
      }
      resolve(countryFromBrowser);
    });
  }

  function handleLanguageChange(language) {
    setCurrentLanguage(language);
  }

  useEffect(() => {
    handleCountryChange(currentCountry, false);
    getImprint();
    getMainProductGroups();
  },[currentLanguage])

  useEffect(() => {
    getMainProductGroups(currentBU);
  },[currentBU])

  function handleBUChange(bu,page = null,shouldNotScroll = null) {
    if(bu !== null) {
      history.pushState({businessUnit: bu}, "", "/products/" + bu);
      setCurrentBU(bu);
      setCurrentIndustry((page === 'products') ? '' : currentIndustry);
      setShowMainProductGroups(true);
    }
  }

  useEffect(() => {
    getMainProductGroupsForIndustry();
  },[currentIndustry])

  function handleIndustryChange(industry) {
    if(industry !== null) {
      history.pushState({industry: industry}, "", "/industries/" + industry);
      setCurrentIndustry(industry);
      setCurrentBU('');
      setMainProductGroups([]);
      setShowMainProductGroups(true);
    }
  }

  useEffect(() => {
    if(showMainProductGroupDetails !== previousShowMainProductGroupDetails && currentMainProductGroup !== null) {
      getProductGroups()
      .then((mainProductGroupsWithProductGroups) => {
        setMainProductGroups(mainProductGroupsWithProductGroups);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    }
  },[showMainProductGroupDetails]);

  function handleShowMainProductGroupDetailsChange(mainProductGroup) {
    setCurrentMainProductGroup(mainProductGroup);
    if(showMainProductGroupDetails.indexOf(mainProductGroup.id) === -1) {
      setShowMainProductGroupDetails([...showMainProductGroupDetails, mainProductGroup.id]);
    }
    else {
      let array = showMainProductGroupDetails.filter(item => item !== mainProductGroup.id)
      setShowMainProductGroupDetails(array);
    }
  }

  function handleShowProductGroupDetailsChange(productGroup) {
    if(showProductGroupDetails.indexOf(productGroup.productGroupID) === -1) {
      setShowProductGroupDetails([...showProductGroupDetails, productGroup.productGroupID]);
    }
    else {
      let array = showProductGroupDetails.filter(item => item !== productGroup.productGroupID)
      setShowProductGroupDetails(array);
    }
  }

  useEffect(() => {
    getMainProductGroups();  
    if(currentIndustry !== '') {
      getMainProductGroupsForIndustry();
    }
  },[currentCountry]);

  function handleCountryChange(country,trackChange = true) {
    if(trackChange) {
      Tracking.trackEvent('Country','Country change - Country: ' + country, 'Country change - Country: ' + country);
    }
    setIsLoading(true);
    window.axios.post(window.location.origin + '/country/locationDetails', {
      requestType: 'getLocationDetails',
      countryID: Country[country].countryNo,
      languageID: Language[currentLanguage].languageNo,
    })
    .then((response) => {
      if(response.data?.success) {
        let data = response.data;
        Country[country].key = country;
        Country[country].address = data.country.fullAddress;
        Country[country].companyName = data.country.companyName;
        Country[country].street = data.country.street;
        Country[country].zip = data.country.zip;
        Country[country].city = data.country.city;
        Country[country].countryToken = data.country.countryToken;
        Country[country].phone = data.country.phone;
        Country[country].fax = data.country.fax;
        Country[country].email = data.country.email;
        Country[country].homepage = data.country.homepage;
        Country[country].vatNo = data.country.vatNo;
        Country[country].registrationOffice = data.country.registrationOffice;
        Country[country].companyNo = data.country.companyNo;
        Country[country].fiaccNo = data.country.fiaccNo;
        Country[country].managingDirectors = data.managingDirectors;
        Country[country].alternateImprintText = data.alternateImprintText;
        setCurrentCountry(country);
        setCurrentCountryObject(Country[country]);
        setContacts(data.contacts);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    loadMainProductGroupDetails();
    setIsLoadingMainProductGroups(false);
  },[mainProductGroups]);

  function getMainProductGroups(bu = null) {
    setIsLoadingMainProductGroups(true);
    window.axios.post(window.location.origin + '/business/mainProductGroups', {
      requestType: 'getMainProductGroups',
      countryID: countryObject.countryNo,
      languageID: Language[currentLanguage].languageNo,
      bu: (bu === null) ? currentBU : bu,
      industry: currentIndustry,
    })
    .then((response) => {
      if(response.data?.success) {
        setMainProductGroups(response.data.mainProductGroups);
      }
    })
    .catch(function (error) {
      console.log(error);
      setIsLoadingMainProductGroups(false);
    });
  }

  useEffect(() => {
    updateBULinks();
  },[mainProductGroupsForIndustry])

  function getMainProductGroupsForIndustry() {
    if(currentIndustry !== '') {
      setIsLoadingMainProductGroups(true);
      window.axios.post(window.location.origin + '/business/industry/mainProductGroups', {
        requestType: 'getMainProductGroupsForIndustry',
        countryID: countryObject.countryNo,
        languageID: Language[currentLanguage].languageNo,
        industry: currentIndustry,
      })
      .then((response) => {
        if(response.data?.success) {
          setMainProductGroupsForIndustry(response.data.mainProductGroups);
        }
        setIsLoadingMainProductGroups(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoadingMainProductGroups(false);
      });
    }
  }

  function updateBULinks() {
    let visibleBusinessUnits = getVisibleBusinessUnits(mainProductGroupsForIndustry);
    setShowBUAdditivesInIndustries(visibleBusinessUnits.isBusinessUnitAdditivesVisible);
    setShowBUBindersInIndustries(visibleBusinessUnits.isBusinessUnitBindersVisible);
    setShowBUFillersInIndustries(visibleBusinessUnits.isBusinessUnitFillersVisible);
    setShowBUPigmentsInIndustries(visibleBusinessUnits.isBusinessUnitPigmentsVisible);
  }

  function loadMainProductGroupDetails() {
    if(mainProductGroups !== previousMainProductGroups) {
      showMainProductGroupDetails.forEach((mainProductGroupId) => {
        let shouldLoadProductGroups = false;
        let mainProductGroup = mainProductGroups.find((mainProductGroup) => mainProductGroup.id === mainProductGroupId);
        if(
          (mainProductGroup !== undefined && mainProductGroup.productGroups === undefined) ||
          previousLanguage !== currentLanguage
        ) {
          shouldLoadProductGroups = true;
        }
        if(shouldLoadProductGroups) {
          getProductGroups(mainProductGroupId)
          .then((mainProductGroupsWithProductGroups) => {
            setMainProductGroups(mainProductGroupsWithProductGroups);
            setIsLoading(false);
          }).catch((error) => {
            console.error(error);
          });
        }
      });
    }
  }
  
  function getProductGroups(mainProductGroupId = null) {
      return new Promise((resolve,reject) => {
        if(mainProductGroupId === null && currentMainProductGroup !== null) {
          mainProductGroupId = currentMainProductGroup.id;
        }
        if(mainProductGroupId !== null) {
          if(mainProductGroups.find((mainProductGroup) => mainProductGroup === mainProductGroupId)?.productGroups == undefined) {
            setIsLoading(true);
            window.axios.post(window.location.origin + '/business/productGroups', {
              requestType: 'getProductGroups',
              countryID: countryObject.countryNo,
              languageID: Language[currentLanguage].languageNo,
              mainProductGroupID: mainProductGroupId,
            })
            .then((response) => {
              if(response.data?.success) {
                let mainProductGroupsUpdated = mainProductGroups.map((mainProductGroup) => {
                  if(mainProductGroup.id === mainProductGroupId) {
                    mainProductGroup.productGroups = response.data.productGroups;
                  }
                  return mainProductGroup;
                });
                resolve(mainProductGroupsUpdated);
              }
            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            });
          }
        }
        else reject('No main product group set');
    });
  }
          
  function getImprint(language = null) {
    window.axios.post(window.location.origin + '/legal/imprint', {
      requestType: 'getImprint',
      languageID: (language != null) ? Language[language].languageNo : Language[currentLanguage].languageNo,
    })
    .then((response) => {
      if(response.data?.success) {
        setImprint(response.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  function onShowOptOutOption(newValue) {
    let hideOptOutTemporarily;
    if(newValue !== undefined) {
      hideOptOutTemporarily = false;
    }
    setOptOutOptions(newValue);
    setHideOptOutTemporarily(hideOptOutTemporarily);
  }

  function onHideOptOutTemporarily() {
    setHideOptOutTemporarily(true);
  }

  return (
    <div style={{minHeight:'100%'}}>
          <WrapDiv id="wrap">
            <FullWidthContainer id="main" className="container-fluid clear-top marginBottom50px main">
                <div id="content">
                  <Navigation 
                    key="navBar" 
                    languagesToShow={languagesToShow} 
                    currentLanguage={currentLanguage}
                    currentCountry={currentCountry}
                    onLanguageChange={handleLanguageChange} 
                    onBUChange={handleBUChange} 
                    onIndustryChange={handleIndustryChange} 
                    onCountryChange={handleCountryChange} 
                  />
                  <div style={{paddingTop:'50px'}}>
                    <Routes>
                      {
                        routes.map((route, index) => (
                            <Route 
                              key={index} 
                              path={route.path} 
                              exact={route.exact} 
                              render={route.components} 
                              element={route.components} 
                            />
                          )
                        )
                      }
                    </Routes>
                  </div>
                </div>
            </FullWidthContainer>
            <Footer 
              key="footer" 
              currentLanguage={currentLanguage} 
              country={countryObject} />
          </WrapDiv>
          

        <PrivacyOptOut 
          key="usageDataOptOut" 
          optOutOptions={optOutOptions} 
          currentLanguage={currentLanguage} 
          onShowOptOutOption={onShowOptOutOption}
          onHideOptOutTemporarily={onHideOptOutTemporarily}
          hideOptOutTemporarily={hideOptOutTemporarily}
        />
      <ScrollUp 
        key="scrollUp" 
      />
    </div>
  );
}

export default App;
