import React from 'react';
import Language from '../../Language.data.js';
import Link from '../../Link.component';

function LinkedIn(props) {
    return (
        <>
            <div className="row mt-2" id="linkedIn">
                <div className="col text-justify font-weight-bold">
                    {
                        Language[props.selectedLanguage].privacyTexts.linkedIn.linkedIn
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.linkedIn.linkedInText1
                    }
                    <Link 
                        linkUrl="https://www.linkedin.com/legal/privacy-policy" 
                        target="_blank"
                    />
                    {
                        Language[props.selectedLanguage].privacyTexts.linkedIn.linkedInText2
                    }
                    <Link 
                        linkUrl="https://legal.linkedin.com/dpa" 
                        target="_blank"
                    />
                    {
                        Language[props.selectedLanguage].privacyTexts.linkedIn.linkedInText3
                    }
                    <Link 
                        linkUrl="https://legal.linkedin.com/dpa" 
                        target="_blank"
                    />
                    {
                        Language[props.selectedLanguage].privacyTexts.linkedIn.linkedInText4
                    }
                    <Link 
                        linkUrl="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" 
                        target="_blank"
                    />.
                </div>
            </div>
        </>
    )
}

export default LinkedIn;