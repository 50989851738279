import React from 'react';
import '../css/loadingSpinner.css';

const LoadingSpinner = () => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-1 col-md-5"></div>
            <div className="col-10 col-md-2 text-center">
                <div className="d-flex justify-content-center">
                    <div className="dot-loader"></div>
                    <div className="dot-loader"></div>
                    <div className="dot-loader"></div>
                </div>
            </div>
            <div className="col-1 col-md-5"></div>
        </div>
    </div>
);

export default LoadingSpinner;