import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Language from './Language.data';
import * as Tracking from '../utils/matomo';

import surfaceCoatings from '../images/Coatings.webp';
import adhesives from '../images/Adhesives.webp';
import printingInks from '../images/Inks_Graphic_Arts.webp';
import rubber from '../images/Rubber.webp';
import paper from '../images/Papier.webp';
import building from '../images/Construction.webp';
import plastics from '../images/Plastics.webp';
import personalCare from '../images/PersonalCare.webp';
import sealants from '../images/Sealants.webp';
import battery from '../images/Energy_Storage.webp';
import moreIndustries from '../images/MoreIndustries.webp';

const RoundCornersImg = styled.img`
    border-radius: 1rem;
    animation-duration: ${props => props.isActive ? 'unset' : '0.5'};
    animation-name: ${props => props.isActive ? 'unset' : blendIn};
    opacity: ${props => props.isActive ? 'unset' : '0.3'};
`

const blendIn = keyframes`
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
`;

const IndustriesDiv = styled.div`
    @media (max-width: 576px) { 
        padding-left:5px;
        padding-right:5px;
        margin-left:0px;
        margin-right:0px;
    }
`

function IndustrySelection(props) {
    const [column, setColumn] = useState(setColumnWidth());
    const [hoverImageElement, setHoverImageElement] = useState(null);
    
    function setColumnWidth() {
        let column = 'col-6 col-sm-6 col-md-4';
        if(props.currentBU !== '' || props.areImagesSmall === true) {
            column = 'col-6 col-md-2';
        }
        return column;
    }

    function handleClickIndustry(event, industry) {
        setColumn('col-3 col-md-2');
        Tracking.trackEvent('Industries','Click Industries - ' + industry, 'Industry - ' + industry);
        props.onIndustryChange(industry);
    }

    function handleHover(even, industry) {
        var element = document.getElementById(industry);
        if(element.classList.contains("fadeInDown") || element.classList.contains("fadeInUp")) {
            if(element.classList.contains("fadeInDown")) {
                element.classList.remove("fadeInDown");
            }
            if(element.classList.contains("fadeInUp")) {
                element.classList.remove("fadeInUp");
            }
            element.classList.remove("animDelay2");
            element.classList.add("productLink");
        }
    }

    function onPersonalCareIndustryClick(event) {
        Tracking.trackEvent('Industries','Click Industries Personal Care', 'Industry Personal Care');
    }

    function onBatteryIndustryClick(event) {
        Tracking.trackEvent('Industries','Click Industries Battery', 'Industry Battery');
    }

    return (
        <IndustriesDiv className="container marginTop50px marginBottom50px area" id="productsDiv">
            <div className="row">
                <div className="col-12 text-center">
                    <h2 id="products">{Language[props.currentLanguage].menu.industries}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    {Language[props.currentLanguage].industriesText}
                </div>
            </div>
            <div className="row marginTop25px d-flex justify-content-center align-items-start">
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="surfaceCoatings" 
                    onClick={(e) => handleClickIndustry(e, 'surfaceCoatings')} 
                    onMouseOver={(e) => handleHover(e, 'surfaceCoatings')}
                    onMouseEnter={() => setHoverImageElement('surfaceCoatings')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'surfaceCoatings' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'surfaceCoatings' ||
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={surfaceCoatings}
                                alt="Surface Coatings" 
                                className="img-fluid" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].surfaceCoatings}
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="adhesives" 
                    onClick={(e) => handleClickIndustry(e, 'adhesives')} 
                    onMouseOver={(e) => handleHover(e, 'adhesives')}
                    onMouseEnter={() => setHoverImageElement('adhesives')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'adhesives' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'adhesives' || 
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={adhesives} 
                                alt="Adhesives" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].adhesives}
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="printingInk" 
                    onClick={(e) => handleClickIndustry(e, 'printingInk')} 
                    onMouseOver={(e) => handleHover(e, 'printingInk')}
                    onMouseEnter={() => setHoverImageElement('printingInk')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'printingInk' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'printingInk' ||
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={printingInks} 
                                alt="Printing Ink" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].printingInk}
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="rubber" 
                    onClick={(e) => handleClickIndustry(e, 'rubber')} 
                    onMouseOver={(e) => handleHover(e, 'rubber')}
                    onMouseEnter={() => setHoverImageElement('rubber')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'rubber' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'rubber' ||
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={rubber} 
                                alt="Rubber" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].rubber}
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="construction" 
                    onClick={(e) => handleClickIndustry(e, 'construction')} 
                    onMouseOver={(e) => handleHover(e, 'construction')}
                    onMouseEnter={() => setHoverImageElement('construction')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'construction' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'construction' || 
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={building} 
                                alt="Construction" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].construction}
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="plastics" 
                    onClick={(e) => handleClickIndustry(e, 'plastics')} 
                    onMouseOver={(e) => handleHover(e, 'plastics')}
                    onMouseEnter={() => setHoverImageElement('plastics')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'plastics' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'plastics' || 
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={plastics} 
                                alt="Plastics" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].plastics}
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="paper" 
                    onClick={(e) => handleClickIndustry(e, 'paper')} 
                    onMouseOver={(e) => handleHover(e, 'paper')}
                    onMouseEnter={() => setHoverImageElement('paper')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'paper' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'paper' || 
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={paper} 
                                alt="Paper" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].paper}
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="sealants" 
                    onClick={(e) => handleClickIndustry(e, 'sealants')} 
                    onMouseOver={(e) => handleHover(e, 'sealants')}
                    onMouseEnter={() => setHoverImageElement('sealants')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'sealants' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'sealants' || 
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={sealants} 
                                alt="Sealants" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].sealants}
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="personalCare" 
                    onMouseOver={(e) => handleHover(e, 'personalCare')}
                    onMouseEnter={() => setHoverImageElement('personalCare')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <a href="http://grolman-personalcare.com" target="_blank" onClick={onPersonalCareIndustryClick} rel="noopener noreferrer">
                                <RoundCornersImg 
                                    isActive={(
                                            props.currentIndustry === 'personalCare' || 
                                            props.currentIndustry === '' || 
                                            hoverImageElement === 'personalCare' ||
                                            props.areImagesActive
                                        ) ? true : false
                                    }
                                    src={personalCare} 
                                    alt="Personal Care" 
                                    className="img-fluid" 
                                />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <a href="http://grolman-personalcare.com" className="pictureLink" onClick={onPersonalCareIndustryClick} target="_blank" rel="noopener noreferrer">
                                {Language[props.currentLanguage].personalCare}
                            </a>
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="battery" 
                    onMouseOver={(e) => handleHover(e, 'battery')}
                    onMouseEnter={() => setHoverImageElement('battery')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <a href="//grolman-energystorage.com" target="_blank" onClick={onBatteryIndustryClick} rel="noopener noreferrer">
                                <RoundCornersImg 
                                    isActive={(
                                            props.currentIndustry === 'battery' || 
                                            props.currentIndustry === '' || 
                                            hoverImageElement === 'battery' || 
                                            props.areImagesActive
                                        ) ? true : false
                                    }
                                    src={battery} 
                                    alt="Battery" 
                                    className="img-fluid" 
                                />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <a href="//grolman-energystorage.com" className="pictureLink" onClick={onBatteryIndustryClick} target="_blank" rel="noopener noreferrer">
                                {Language[props.currentLanguage].battery}
                            </a>
                        </div>
                    </div>
                </div>
                <div 
                    className={`${column} text-center padding10px imageLink animated cursorPointer`} 
                    id="moreIndustries" 
                    onClick={(e) => handleClickIndustry(e, 'moreIndustries')} 
                    onMouseOver={(e) => handleHover(e, 'moreIndustries')}
                    onMouseEnter={() => setHoverImageElement('moreIndustries')}
                    onMouseLeave={() => setHoverImageElement(null)}
                >
                    <div className="row">
                        <div className="col-12">
                            <RoundCornersImg 
                                isActive={(
                                        props.currentIndustry === 'moreIndustries' || 
                                        props.currentIndustry === '' || 
                                        hoverImageElement === 'moreIndustries' || 
                                        props.areImagesActive
                                    ) ? true : false
                                }
                                src={moreIndustries} 
                                alt="More Industries" 
                                className="img-fluid" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {Language[props.currentLanguage].moreIndustries}
                        </div>
                    </div>
                </div>
            </div>
        </IndustriesDiv>
    );
}

export default IndustrySelection;