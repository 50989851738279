import React, { Component } from 'react';
import styled from 'styled-components';
import ProductGroupList from './ProductGroupList.container';
import LoadingSpinner from './LoadingSpinner.component.js';
import Language from './Language.data';

const MainProductGroupCardDiv = styled.div`
    border:0px;
`

class MainProductGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMainProductGroupOpen: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.showMainProductGroupDetails !== prevProps.showMainProductGroupDetails) {
            let isMainPgOpen = false;
            if(this.props.showMainProductGroupDetails.find((pg) => pg === this.props.mainProductGroup.id) !== undefined) {
                isMainPgOpen = true;
            }
            else {
                isMainPgOpen = false;
            }
            this.setState({
                isMainProductGroupOpen: isMainPgOpen
            });
        }
            
    }

    handleMainProductGroupClick(event) {
        this.props.onMainProductGroupClick(this.props.mainProductGroup);
    }

    handleProductGroupClick(productGroup) {
        this.props.onProductGroupClick(productGroup);
    }

    getProductGroups() {
        window.axios.post(window.location.origin + '/business/productGroups', {
            requestType: 'getProductGroups',
            countryID: this.props.country.countryNo,
            languageID: Language[this.props.currentLanguage].languageNo,
            mainProductGroupID: this.props.mainProductGroup.id,
        })
        .then((response) => {
            if(response.data?.success) {
                this.setState({
                    productGroups: response.data.productGroups,
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    render() {
        let needCountrySelection = (this.props.country.countryNo === 9999) ? true : false;
        let isLastAddedMainProductGroup = (this.props.showMainProductGroupDetails[this.props.showMainProductGroupDetails.length - 1] === this.props.mainProductGroup.id) ? true : false;
        return (
            <MainProductGroupCardDiv className="card text-center mainProductGroupDiv mb-1">
                <div className={"card-body animated mainProductGroupLink borderBottom1pxSolid888"}>
                    <h5 className="card-title cursorPointer mainProductGroupTitle d-flex align-items-center justify-content-center" onClick={(event) => this.handleMainProductGroupClick(event)}>
                        {this.props.mainProductGroup.name}
                        {
                            this.state.isMainProductGroupOpen &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#004839" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
                            </svg>
                        }
                        {
                            !this.state.isMainProductGroupOpen &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#004839" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
                            </svg>
                        }
                    </h5>
                    <div id={this.props.mainProductGroup.name.toLowerCase().replace(/-/gi,'').replace(/ /gi,'').replace(/\//gi,'').replace(/,/gi,'')}>
                        {
                            (needCountrySelection === false ) && this.props.displayMainProductGroupDetails &&
                            <div>
                                {
                                    this.props.isLoading && isLastAddedMainProductGroup &&
                                    <LoadingSpinner key={"mainProductGroup" + this.props.mainProductGroup.id + "LoadingSpinner"} />
                                }
                                <ProductGroupList 
                                    currentLanguage={this.props.currentLanguage} 
                                    mainProductGroup={this.props.mainProductGroup} 
                                    country={this.props.country} 
                                    key={"ProductGroupList" + this.props.mainProductGroup.id} 
                                    showProductGroupDetails={this.props.showProductGroupDetails} 
                                    onShowProductGroupDetailsChange={this.handleProductGroupClick.bind(this)} 
                                />
                            </div> 
                        }
                        {
                            needCountrySelection && this.props.displayMainProductGroupDetails &&
                            <div>
                                {Language[this.props.currentLanguage].pleaseSelectACountryForMoreInformation}
                            </div>
                        }
                    </div>
                </div>
            </MainProductGroupCardDiv>
        );
    }
}

export default MainProductGroup;