import React, { Component } from 'react';
import styled from 'styled-components';
import $ from "jquery";
import ProductGroupList from './ProductGroupList.container';
import LoadingSpinner from './LoadingSpinner.component.js';
import Language from './Language.data';
require('bootstrap');

const MainProductGroupCardDiv = styled.div`
    border:0px;
`

class MainProductGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        if(this.props.displayMainProductGroupDetails) {
            //this.getProductGroups();
        }
    }

    handleMainProductGroupClick(event) {
        this.props.onMainProductGroupClick(this.props.mainProductGroup);
        //this.getProductGroups();
    }

    handleProductGroupClick(productGroup) {
        this.props.onProductGroupClick(productGroup);
    }

    getProductGroups() {
        window.axios.post(window.location.origin + '/business/productGroups', {
            requestType: 'getProductGroups',
            countryID: this.props.country.countryNo,
            languageID: Language[this.props.currentLanguage].languageNo,
            mainProductGroupID: this.props.mainProductGroup.id,
        })
        .then((response) => {
            if(response.data?.success) {
                this.setState({
                    productGroups: response.data.productGroups,
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    render() {
        let needCountrySelection = (this.props.country.countryNo === 9999) ? true : false;
        let isLastAddedMainProductGroup = (this.props.showMainProductGroupDetails[this.props.showMainProductGroupDetails.length - 1] === this.props.mainProductGroup.id) ? true : false;
        return (
            <MainProductGroupCardDiv className="card text-center mainProductGroupDiv">
                <div className={"card-body animated mainProductGroupLink borderBottom1pxSolid888"}>
                    <h5 className="card-title cursorPointer mainProductGroupTitle" onClick={(event) => this.handleMainProductGroupClick(event)}>
                        {this.props.mainProductGroup.name}
                    </h5>
                    <div id={this.props.mainProductGroup.name.toLowerCase().replace(/-/gi,'').replace(/ /gi,'').replace(/\//gi,'').replace(/,/gi,'')}>
                        {
                            (needCountrySelection === false ) && this.props.displayMainProductGroupDetails &&
                            <div>
                                {
                                    this.props.isLoading && isLastAddedMainProductGroup &&
                                    <LoadingSpinner key={"mainProductGroup" + this.props.mainProductGroup.id + "LoadingSpinner"} />
                                }
                                <ProductGroupList 
                                    currentLanguage={this.props.currentLanguage} 
                                    mainProductGroup={this.props.mainProductGroup} 
                                    country={this.props.country} 
                                    key={"ProductGroupList" + this.props.mainProductGroup.id} 
                                    showProductGroupDetails={this.props.showProductGroupDetails} 
                                    onShowProductGroupDetailsChange={this.handleProductGroupClick.bind(this)} 
                                />
                            </div> 
                        }
                        {
                            needCountrySelection && this.props.displayMainProductGroupDetails &&
                            <div>
                                {Language[this.props.currentLanguage].pleaseSelectACountryForMoreInformation}
                            </div>
                        }
                    </div>
                </div>
            </MainProductGroupCardDiv>
        );
    }
}

export default MainProductGroup;