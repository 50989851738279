import React from 'react';
import Language from '../Language.data';

function Rubber(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].industries.rubber.rubber}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].applications}:
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.rubber.applicationText}
                </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.rubber.industryText1}
                </div>
            </div>
            <div className="row mt-1 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.rubber.industryText2}
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <ul>
                        <li>{Language[props.currentLanguage].functionalities.renewableRawMaterials}</li>
                        <li>{Language[props.currentLanguage].functionalities.wettingAndDispersing}</li>
                        <li>{Language[props.currentLanguage].functionalities.heatManagement}</li>
                        <li>{Language[props.currentLanguage].functionalities.flameRetardancy}</li>
                    </ul>
                </div>
                <div className="col">
                    <ul>                            
                        <li>{Language[props.currentLanguage].functionalities.mechanicalImprovement}</li>
                        <li>{Language[props.currentLanguage].functionalities.durability}</li>
                        <li>{Language[props.currentLanguage].functionalities.defoaming}</li>
                        <li>{Language[props.currentLanguage].functionalities.electricalProperties}</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Rubber;