import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Language from './Language.data';
import EmailAddress from './EmailAddress.component';
import { base64toBlob } from '../utils/utils';

import pdfIcon from '../images/pdf.svg'

const PDFImage = styled.img`
    height:35px;
`

const Card = styled.div`
    background-color: #BACCFF !important; 
    color: #004839 !important;
    border: 0px !important;
`

const CardHeader = styled.div`
    background-color: #BACCFF !important; 
    color: #004839 !important;
`
const Flag = styled.span`
    display: inline-block;
    width: 30px !important;
    height: 20px;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #eee;
    background-position: center;
`

const ColumnHeaderText = styled.span`
    color: #004839;
    font-weight: 700;
`

const ButtonWithoutBorders = styled.button`
    background-color:transparent;
    border:0px;
`

function Job(props) {
    const [isCardOpen, setIsCardOpen] = useState(false);

    function openDocument(documentId) {
        window.open('/jobs/document/' + documentId + '/' + props.job.title.replaceAll(' ','').replaceAll('/',''), '_blank');
    }

    function onCardHeaderClick() {
        setIsCardOpen(!isCardOpen);
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center align-items-center h-100 marginBottom50px">
                <div className="offset-1 marginTop50px">
                </div>
                <div className="col-10 text-center justify-content-center">
                    <Card className="card">
                        <CardHeader onClick={onCardHeaderClick} className="card-header" data-toggle="collapse" data-target={"#job" + props.job.id} aria-expanded="false" aria-controls={"job" + props.job.id}>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="text-align-end">
                                    <div className="text-center cursorPointer">
                                        <Flag className={"fi " + props.job.country.flag.replace("flag-icon-","fi-")}></Flag> {props.job.country.name + ((props.job.location !== null && props.job.location.length > 0) ? ', ' + props.job.location : '')}
                                    </div>
                                    <h5 className="card-title cursorPointer mainProductGroupTitle">
                                        {props.job.title}
                                        <br /> 
                                        {Language["en"].jobsSection.mfd}
                                    </h5>
                                </div>
                                <div className="text-align-left">
                                    {
                                        isCardOpen &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#004839" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
                                        </svg>
                                    }
                                    {
                                        !isCardOpen &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#004839" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
                                        </svg>
                                    }
                                </div>
                            </div>
                        </CardHeader>
                        <div className="card-body borderBottom1pxSolid888 padding5px collapse" id={"job" + props.job.id}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 text-left">
                                    <ColumnHeaderText>
                                        {
                                            props.job?.job_texts?.find((jobText) => jobText.column_no === 1 && jobText.is_header === 1)?.text
                                        }
                                    </ColumnHeaderText>
                                    <ul>
                                        {
                                            props.job?.job_texts?.filter((jobText) => jobText.column_no === 1 && jobText.is_header === 0).map((jobText) => {
                                                return (
                                                    <li key={"jobText" + jobText.id}>
                                                        {
                                                            jobText.text
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>                       
                                <div className="col-xs-12 col-sm-12 col-md-6 text-left">
                                    <ColumnHeaderText>
                                        {
                                            props.job?.job_texts?.find((jobText) => jobText.column_no === 2 && jobText.is_header === 1)?.text
                                        }
                                    </ColumnHeaderText>
                                    <ul>
                                        {
                                            props.job?.job_texts?.filter((jobText) => jobText.column_no === 2 && jobText.is_header === 0).map((jobText) => {
                                                return (
                                                    <li key={"jobText" + jobText.id}>
                                                        {
                                                            jobText.text
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {Language["en"].jobsSection.findDetailsInPDF + ':'}
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                {
                                    props.job?.job_files?.map((jobFile) => {
                                        return (
                                            <div key={"jobFile" + jobFile.id} className="col justify-content-center">
                                                <ButtonWithoutBorders onClick={(e) => openDocument(jobFile.id)} target="_blank" className="link">
                                                    <PDFImage src={pdfIcon} alt=""></PDFImage>
                                                </ButtonWithoutBorders>
                                                <br />
                                                <small>{jobFile.language.name}</small>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="row align-items-center h-100 marginBottom50px">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                    {Language["en"].jobsSection.footerText + ' '}
                                    <br />
                                    <EmailAddress key={"emailAddressKeyApplicants"} classNames={"link"} emailAddress={props.job?.send_email_to} />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="offset-1 marginTop50px">
                </div>
            </div>
        </div>
    )
}

export default Job;