import React from 'react';
import Language from '../Language.data';
import styled from 'styled-components';
import Matomo from './tracking/Matomo.component';

const PrivacyDataListItem = styled.li`
    width: fit-content;
`

function DataProcessing(props) {

    function onShowOptOutOption(data) {
        props.onShowOptOutOption(data);
    }
    
    return (
        <>
            <div className="row mt-5">
                <div className="col text-center">
                    <h4>
                        {
                            Language[props.selectedLanguage].privacyTexts.dataProcessingWhenUsingThisSite
                        }
                    </h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.weProcessData
                    }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-justify font-weight-bold">
                    {
                        Language[props.selectedLanguage].privacyTexts.serverLogFiles
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.automaticallyProcessesInformationProvidedByBrowser
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-center d-flex justify-content-start">
                    <ul>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.browserTypeAndVersion}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.operatingSystem}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.referrerUrl}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.dateAndTimeOfServerRequest}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.ipAddress}</PrivacyDataListItem>
                    </ul>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.serverLogFilesText1
                    }
                        <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.serverLogFilesText2
                    }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-justify font-weight-bold">
                    {
                        Language[props.selectedLanguage].privacyTexts.cookies.cookies
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.cookies.cookiesText1
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.cookies.cookiesText2
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.cookies.cookiesText3
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify d-flex justify-content-center">
                    <ul>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.cookies.cookieConsent1}</PrivacyDataListItem>
                        <PrivacyDataListItem>{Language[props.selectedLanguage].privacyTexts.cookies.cookieConsent2}</PrivacyDataListItem>
                    </ul>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.cookies.cookiesText4
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.cookies.cookiesText5
                    }
                </div>
            </div>
            <Matomo
                key={"privacyTrackingMatomo"}
                selectedLanguage={props.selectedLanguage}
                onShowOptOutOption={onShowOptOutOption}
            />
        </>
    )
}

export default DataProcessing;