import React, { useState, useEffect, useRef } from 'react';
import LoadingSpinner from './LoadingSpinner.component';
import Address from './Address.component';
import Language from './Language.data';
import { v4 as uuidv4 } from 'uuid';

function AllLocations(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getData();
    },[]);

    useEffect(() => {
        setIsLoading(true);
        getData();
    },[props.currentLanguage]);

    function getData() {
        window.axios.post(window.location.origin + '/company/locations', {
            requestType: 'getAllLocations',
            languageID: Language[props.currentLanguage].languageNo,
        })
        .then((response) => {
            if(response.data?.success) {
                setLocations(response.data.companyAddresses);
                setIsLoading(false);
            }
        })
        .catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    return (
        <>
            {
                isLoading &&
                <div className="container-fluid">
                    <div className="row lower">
                        <LoadingSpinner key=""></LoadingSpinner>
                    </div>
                </div>
            }
            {
                isLoading === false && locations.length > 0 &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col d-flex justify-content-center text-center">
                            <strong>
                                Headoffice
                            </strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center text-center">
                            <Address
                                country={locations?.find((location) => location.headoffice === 1)}
                                showWebsiteLink={false}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        {
                            locations?.filter((location) => location.headoffice === 0 && location.companyNo !== 13).map((location) => {
                                const uuid = uuidv4();
                                return (
                                    <div className="col-sm-12 col-md-6 col-lg-4 mt-4 justify-content-center text-center">
                                        <div className="row">
                                            <div className="col">
                                                <strong>
                                                    {
                                                        location.countryName
                                                    }
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <Address
                                                    key={`location${uuid}`}
                                                    country={location}
                                                    showWebsiteLink={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) 
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default AllLocations;