import React, { Component, Fragment } from 'react';
import Divider from './Divider.component';
//import MeetUs from './MeetUs.container.js';
import StartPageVideoBackground from './StartPageVideoBackground.component.js';
import StartPageSlider from './StartPageSlider.component';
import BusinessUnitSelection from './BusinessUnitSelection.component';
import IndustrySelection from './IndustrySelection.component.js';
import { useNavigate } from "react-router-dom";

const MeetUs = React.lazy(() => import('./MeetUs.container.js'));

function MainPage(props) {
    let navigate = useNavigate();

    return (
        <>
            <React.Suspense fallback={<div></div>}>
                <StartPageVideoBackground 
                    key={"startPageVideoBackgroundComponent"} 
                    currentLanguage={props.currentLanguage} 
                />
            </React.Suspense>
            <React.Suspense fallback={<div></div>}>
                <StartPageSlider 
                    key={"startPageSliderComponent"} 
                    currentLanguage={props.currentLanguage} 
                />
            </React.Suspense>
            <Divider 
                key="divider1" 
                marginBottom={"35px"}
            />
            <BusinessUnitSelection 
                currentLanguage={props.currentLanguage}
                currentBU={props.currentBU} 
                areImagesActive={true}
                onBUChange={(bu,page) => {
                    navigate(`/products/${bu}`);
                    props.onBUChange(bu,page);
                }}
            />
            <IndustrySelection
                currentLanguage={props.currentLanguage}
                currentIndustry={props.currentIndustry}
                areImagesActive={true}
                onIndustryChange={(industry) => {
                    navigate(`/industries/${industry}`);
                    props.onIndustryChange(industry)
                }}
            />
            <Divider 
                key="divider4"
            />
            <React.Suspense fallback={<div></div>}>
                <MeetUs 
                    key="meetUs" 
                    currentLanguage={props.currentLanguage}
                />
            </React.Suspense>
        </>
    );
}

export default MainPage;