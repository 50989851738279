import React from 'react';
import Language from '../../Language.data.js';
import styled from 'styled-components';

const CookieConsentLink = styled.div`
    cursor: pointer;
    text-decoration: underline;
`

function Matomo(props) {

    function onShowMatomoClickHandler(event) {
        let target = event.target;
        if(target !== undefined && target.id === 'openMatomoOptOut') {
            props.onShowOptOutOption({showOptOut: true, language: props.selectedLanguage});
        }
    }

    return (
        <>
            <div className="row mt-2">
                <div className="col text-justify font-weight-bold">
                    {
                        Language[props.selectedLanguage].privacyTexts.matomo.matomo
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.matomo.matomoText1
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.matomo.matomoText2
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.matomo.matomoText3
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.matomo.matomoText4
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.matomo.matomoText5
                    }
                    <br />
                    {
                        Language[props.selectedLanguage].privacyTexts.matomo.matomoText6
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.hereYouCanGiveOrRevokeCookieConsent
                    }
                    <CookieConsentLink id="openMatomoOptOut" onClick={onShowMatomoClickHandler}>
                        {
                            Language[props.selectedLanguage].privacyTexts.giveOrRevokeConsent
                        }
                    </CookieConsentLink>
                </div>
            </div>
        </>
    )
}

export default Matomo;