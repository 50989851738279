import React, { Component } from 'react';
import Language from './Language.data';
import MainProductGroup from './MainProductGroup.component';
import EmailAddress from './EmailAddress.component';
import ContactList from './ContactList.container';
import LoadingSpinner from './LoadingSpinner.component';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
require('bootstrap');

const MainProductGroupContainer = styled.div`
    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
    }
` 

class MainProductGroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    handleMainProductGroupClick(mainProductGroup) {
        this.props.onShowMainProductGroupDetailsChange(mainProductGroup);
    }

    handleProductGroupClick(productGroup) {
        this.props.onShowProductGroupDetailsChange(productGroup);
    }

    render() {
        let buName = '';
        switch(this.props.currentBU) {
            case 'additives':
                buName = Language[this.props.currentLanguage].additives;
                break;
            case 'binders':
                buName = Language[this.props.currentLanguage].binders;
                break;
            case 'fillers':
                buName = Language[this.props.currentLanguage].fillers;
                break;
            case 'pigments':
                buName = Language[this.props.currentLanguage].pigments;
                break;
            case 'personalCare':
                buName = Language[this.props.currentLanguage].personalCare;
            default:
                break;
        }
        let mainProductGroups = this.props.mainProductGroups;
        let mainProductGroupsToShow = this.props.showMainProductGroupDetails;
        return (
            <MainProductGroupContainer className="container-fluid marginTop50px marginBottom50px area" key={"mainProductGroupContainer"}>
                {
                    (!this.props.hideHeader || this.props.hideHeader !== true) &&
                    <div className="row">
                        <div className="col-12 text-center">
                            <a className="anchor" id="mainProductGroupList"> </a>
                            <h3>{buName}</h3>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12 text-center">
                        {
                            this.props.isLoadingMainProductGroups &&
                            <div>
                                <LoadingSpinner key="loadingSpinnerMainProductGroups"></LoadingSpinner>
                            </div>
                        }
                        {
                            this.props.isLoadingMainProductGroups === false && mainProductGroups !== undefined && mainProductGroups.length > 0 && !this.props.country.productGroupInfosNotAvailable &&
                            mainProductGroups.map((mainProductGroup) => {
                                let displayMainProductGroupDetails = (mainProductGroupsToShow.indexOf(mainProductGroup.id) !== -1) ? true : false;
                                return (
                                    <div key={"mainProductGroupDiv." + mainProductGroup.buId +"." + mainProductGroup.id}>
                                        <MainProductGroup 
                                            showMainProductGroupDetails={this.props.showMainProductGroupDetails} 
                                            isLoading={this.props.isLoading} 
                                            currentLanguage={this.props.currentLanguage} 
                                            mainProductGroup={mainProductGroup} 
                                            country={this.props.country} 
                                            key={"mainProductGroupComponent" + mainProductGroup.id} 
                                            displayMainProductGroupDetails={displayMainProductGroupDetails} 
                                            onMainProductGroupClick={this.handleMainProductGroupClick.bind(this)} 
                                            showProductGroupDetails={this.props.showProductGroupDetails} 
                                            onProductGroupClick={this.handleProductGroupClick.bind(this)} 
                                        />
                                    </div>
                                );
                            })
                        }
                        {
                            this.props.isLoadingMainProductGroups === false && mainProductGroups !== undefined && mainProductGroups.length === 0 && buName !== '' &&
                            <div>
                                {
                                    Language[this.props.currentLanguage].noProductsToDisplay
                                }
                            </div>
                        }
                        {
                            ((this.props.country.productGroupInfosNotAvailable)) &&
                            <div>
                                {
                                    Language[this.props.currentLanguage].noFurtherInformationAvailable
                                }
                                <EmailAddress key={uuidv4()} emailAddress={this.props.country.email} />
                                <ContactList currentLanguage={this.props.currentLanguage} contacts={this.props.contacts} country={this.props.country} hideHeader={true} key={uuidv4()} />
                            </div>
                        }
                    </div>
                </div>
            </MainProductGroupContainer>
        );
    }
}

export default MainProductGroupList;