import React, { Component } from 'react';
import ProductGroup from './ProductGroup.component';
import styled from 'styled-components';
require('bootstrap');


const ProductGroupListContainer = styled.div`

    background-color:transparent !important;

    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
        background-color:transparent;
    }
` 


class ProductGroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productGroups: [],
        };
    }  

    handleProductGroupClick(productGroup) {
        this.props.onShowProductGroupDetailsChange(productGroup);
    }

    render() {
        return (
            <ProductGroupListContainer className="container-fluid area">
                <div className="row">
                    <div className="col-12 text-center padding0px">
                        {
                            this.props.mainProductGroup.productGroups?.map((productGroup) => {
                                let displayProductGroupDetails = (this.props.showProductGroupDetails.indexOf(productGroup.productGroupID) !== -1 || this.props.mainProductGroup.productGroups.length === 1) ? true : false; /*this.props.showProductGroupDetails.includes(productGroup.productGroupID);*/
                                return (
                                    <ProductGroup 
                                        currentLanguage={this.props.currentLanguage} 
                                        mainProductGroup={this.props.mainProductGroup}
                                        productGroup={productGroup} 
                                        country={this.props.country} 
                                        key={productGroup.productGroupName} 
                                        displayProductGroupDetails={displayProductGroupDetails} 
                                        onProductGroupClick={this.handleProductGroupClick.bind(this)} 
                                    />
                                );
                            })
                        }
                    </div>
                </div>
            </ProductGroupListContainer>
        );
    }
}

export default ProductGroupList;