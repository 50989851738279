import { useEffect, useRef } from 'react';

export function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
}

export function scrollToObject(object) {

      window.setTimeout(function() {
        let element = document.getElementById(object.object);
        if(element !== null) {
          let positionInfo = element.getBoundingClientRect();
          let heightOfNavigationPanel = 80;
          window.scrollTo({top: positionInfo.top - heightOfNavigationPanel, behavior: 'smooth'});
        }
      }, 250);

}

export function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = window.atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
  
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
  
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
}

export function isNullOrUndefined(value) {
  return value === undefined || value === null;
}

export function phoneNumberToLink(number) {
  return "tel:"+ number.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll(' ','');
}

export function usePrevious(value) {
  const ref = useRef();
  
  useEffect(() => {
      ref.current = value; 
  },[value]); 
  
  return ref.current;
}

export function highlightText(text, highlight) {
  if(!isNullOrUndefined(text)) {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
    const parts = text.split(regex);
    const tempArray = parts.filter(part => part).map((part, i) => ( regex.test(part) ? `<mark key="${i}">${part}</mark>` : `<span key="${i}">${part}</span>`));
    return tempArray.join('');
  }
  return '';
}

export function replaceSystemNewLineCharactersWithHtml(text) {
  return text.replaceAll('\r\n', "<br />");
}