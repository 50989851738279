import { createGlobalStyle } from 'styled-components';
import bobbyJonesSoftCondensedFontWoff from '../fonts/Bobby_Jones_Soft_W05_Condensed.woff';
import bobbyJonesSoftCondensedFontWoff2 from '../fonts/Bobby_Jones_Soft_W05_Condensed.woff2';
import bobbyJonesSoftRegularFontWoff from '../fonts/Bobby_Jones_Soft_W05_Regular.woff';
import bobbyJonesSoftRegularFontWoff2 from '../fonts/Bobby_Jones_Soft_W05_Regular.woff2';

export default createGlobalStyle`
    ${
        /*
            @font-face {
            font-family: ` + props.font?.font_family + `;
            src: url('` + props.fontEotFile + `');
            src: url('` + props.fontEotFile + `?#iefix') format('embedded-opentype'), url('` + props.fontWoff2File + `') format('woff2'), url('` + props.fontWoffFile + `') format('woff'), url('` + props.fontTtfFile + `') format('truetype'), url('` + props.fontSvgFile + '#' + props.appText?.font?.font_family + `') format('svg');
            font-weight: ` + props.appText?.font?.font_weight + `; 
            font-style: ` + props.appText?.font?.font_style + `;
        }
        */
        `
        @font-face {
            font-family: "Bobby Jones Soft W05 Condensed";
            src:url(` + bobbyJonesSoftCondensedFontWoff2 + `) format("woff2"),url(` + bobbyJonesSoftCondensedFontWoff +`) format("woff");
            font-display: swap;
        }

        @font-face {
            font-family: "Bobby Jones Soft W05 Regular";
            src:url(` + bobbyJonesSoftRegularFontWoff2 + `) format("woff2"),url(` + bobbyJonesSoftRegularFontWoff +`) format("woff");
            font-display: swap;
        }
        `
    }
`;