import React, { useState, useEffect } from 'react';
import EmailAddress from './EmailAddress.component';
import Language from './Language.data';
import styled from 'styled-components';
import * as Tracking from '../utils/matomo';
import _ from 'lodash';
import { ImageColumnDiv, ImageColumn, ImageColumnTextDiv } from '../styles/Image.styles';
import { isNullOrUndefined } from '../utils/utils';
import HighlightText from './HighlightText.component';

import phone from '../images/phoneBlack.svg';
import fax from '../images/faxBlack.svg';
import email from '../images/emailBlack.svg';
import mobile from '../images/mobileBlack.svg';
import phoneWhite from '../images/phoneWhite.svg';
import faxWhite from '../images/faxWhite.svg';
import emailWhite from '../images/emailWhite.svg';
import mobileWhite from '../images/mobileWhite.svg';

const PhoneIconImage = styled.img`
    width:20px;
    height:20px;
    color:#000 !important;
`

const EmailIconImage = styled.img`
  width:25px;
  height:25px;
  color:#000 !important;
`

const MarginLeftLink = styled.a`
    margin-left:20px;
`

const WhiteSpaceNoWrap = styled.div`
    white-space:nowrap;
`

const ContactCard = styled.div`
    max-width: 80vw;
    border:0px;
    background-color:transparent;
`

const CardSubtitle = styled.h6`
    color:#004839 !important;
`

function ContactPerson(props) {

    const [contact, setContact] = useState(props.contact);

    function onContactClick(event,clickAction) {
        Tracking.trackEvent('Contact','Click Contact ' + clickAction, 'Contact ' + clickAction + ' - ' + props.contact.Name);
    }

    useEffect(() => {
        let updatedContact = _.cloneDeep(props.contact);
        /* SONDERLOGIK Aylin Anik - Anzeige "Geschäftsführerin" */
        if(updatedContact?.id?.toString() === "46035361836425") {
            updatedContact.function = Language[props.currentLanguage].managingDirectorFemale;
        }
        updatedContact.imageName = updatedContact.Name?.replaceAll(' ', '') + '.jpg';
        updatedContact.image = getPortraitImage(updatedContact);
        setContact(updatedContact);
    },[props.contact]);

    function getPortraitImage(contact) {
        try {
            const image = require(`../images/portraits/${contact.imageName}`)?.default; 
            return image;
        }
        catch(error) {
            const image = require(`../images/portraits/UnisexAvatar.jpg`)?.default; 
            return image;
        }
    }

    return (
        <ContactCard className="card p-2">
            <div className="card-body animate animDelay2 text-center">
                <div className="row d-flex justify-content-center align-items-center">
                    <ImageColumnDiv 
                        className={(props.imagePosition ? "" : "col") + " d-flex"}
                        imagePosition={props.imagePosition ? props.imagePosition : null}
                    >
                        {
                            !isNullOrUndefined(contact) &&
                            <ImageColumn 
                                backgroundImage={contact.image} 
                                imageSize={props.imageSize ? props.imageSize : null}
                            />
                        }
                    </ImageColumnDiv>
                    <ImageColumnTextDiv className="col">                 
                        <h5 
                            className="card-title"
                        >
                            {
                                isNullOrUndefined(props.currentSearchText) &&
                                (!isNullOrUndefined(contact.Titel) ? `${contact.Titel} ${contact.Name}` : `${contact.Name}`)
                            }
                            {
                                !isNullOrUndefined(props.currentSearchText) &&
                                <HighlightText
                                    text={(!isNullOrUndefined(contact.Titel) ? `${contact.Titel} ${contact.Name}` : `${contact.Name}`)}
                                    highlight={props.currentSearchText}
                                />
                            }
                        </h5>
                        {
                            !isNullOrUndefined(contact.function) &&
                            <CardSubtitle 
                                className="card-subtitle mb-2 text-muted" 
                                dangerouslySetInnerHTML={{__html: contact.function}}
                            />
                        }
                        <div>
                            {
                                (!isNullOrUndefined(contact) && !isNullOrUndefined(contact.Telefon) && contact.Telefon.length > 0) &&
                                <WhiteSpaceNoWrap className="padding2px">
                                    <PhoneIconImage 
                                        src={phone} 
                                        alt="Anrufen" 
                                    />
                                    <span> </span>
                                    <MarginLeftLink 
                                        href={contact.phoneHTML} 
                                        className="link" 
                                        onClick={(e) => this.onContactClick(e,'phone')}
                                    >
                                        {
                                            contact.Telefon.replace("(1)", "( 1 )")
                                                            .replace("(2)", "( 2 )")
                                                            .replace("(3)", "( 3 )")
                                                            .replace("(4)", "( 4 )")
                                                            .replace("(5)", "( 5 )")
                                                            .replace("(6)", "( 6 )")
                                                            .replace("(7)", "( 7 )")
                                                            .replace("(8)", "( 8 )")
                                                            .replace("(9)", "( 9 )")
                                        }
                                    </MarginLeftLink>
                                </WhiteSpaceNoWrap>
                            }
                            {
                                (!isNullOrUndefined(contact) && !isNullOrUndefined(contact.Fax) && contact.Fax.length > 0) &&
                                <WhiteSpaceNoWrap className="padding2px">
                                    <PhoneIconImage 
                                        src={fax} 
                                        alt="Fax" 
                                    />
                                    <span> </span>
                                    <MarginLeftLink 
                                        href={contact.faxHTML} 
                                        className="link" 
                                        onClick={(e) => this.onContactClick(e,'fax')}
                                    >
                                        {
                                            contact.Fax.replace("(1)", "( 1 )")
                                                        .replace("(2)", "( 2 )")
                                                        .replace("(3)", "( 3 )")
                                                        .replace("(4)", "( 4 )")
                                                        .replace("(5)", "( 5 )")
                                                        .replace("(6)", "( 6 )")
                                                        .replace("(7)", "( 7 )")
                                                        .replace("(8)", "( 8 )")
                                                        .replace("(9)", "( 9 )")
                                        }
                                    </MarginLeftLink>
                                </WhiteSpaceNoWrap>
                            }
                            {
                                (!isNullOrUndefined(contact) && !isNullOrUndefined(contact.Mobil) && contact.Mobil.length > 0) &&
                                <WhiteSpaceNoWrap className="padding2px">
                                    <PhoneIconImage 
                                        src={mobile} 
                                        alt="Mobile" 
                                    />
                                    <span> </span>
                                    <MarginLeftLink 
                                        href={contact.mobileHTML} 
                                        className="link" 
                                        onClick={(e) => this.onContactClick(e,'mobile')}
                                    >
                                        {
                                            contact.Mobil.replace("(1)", "( 1 )")
                                                        .replace("(2)", "( 2 )")
                                                        .replace("(3)", "( 3 )")
                                                        .replace("(4)", "( 4 )")
                                                        .replace("(5)", "( 5 )")
                                                        .replace("(6)", "( 6 )")
                                                        .replace("(7)", "( 7 )")
                                                        .replace("(8)", "( 8 )")
                                                        .replace("(9)", "( 9 )")
                                        }
                                    </MarginLeftLink>
                                </WhiteSpaceNoWrap>
                            }
                            {
                                (!isNullOrUndefined(contact) && !isNullOrUndefined(contact.Email) && contact.Email.length > 0) &&
                                <WhiteSpaceNoWrap className="padding2px">
                                    <EmailIconImage src={email} alt="Email" />
                                    <span> </span>
                                    <EmailAddress 
                                        key={`emailAddressKey${contact.ID}`} 
                                        classNames="link" 
                                        additionalStyles={{marginLeft:'15px'}}
                                        emailAddress={contact.Email} 
                                    />
                                </WhiteSpaceNoWrap>
                            }
                        </div>
                    </ImageColumnTextDiv>
                </div>
            </div>
        </ContactCard>
    );
}

export default ContactPerson;
