import React, { Component } from 'react';
import arrowUp from '../images/arrowUp.svg';
import styled from 'styled-components';

let smoothScroll = require('smoothscroll');

const ScrollUpLink = styled.a`
	width: 50px;
	height: 50px;
	bottom: 18px;
	right: 18px;
	color: #fff;
	font-size: 18px;
	line-height: 50px;
	text-align: center;
	text-decoration: none;
	background-color: rgba(000,000,000,0.3);
	opacity: 0.6;
	border-radius: 5px;
	position: fixed;
	z-index: 2147483647;
	display:inline-block;
`

class ScrollUp extends Component {

	upscrolling() {
        let destination = document.querySelector('.main');
		smoothScroll(destination);
	}

	render() {
 		return (
 			<div>
 				<ScrollUpLink id="scrollUp" href="#top" onClick={this.upscrolling.bind(this)}><img src={arrowUp} alt="Up" /></ScrollUpLink>
			</div>
 		);
	}
}

export default ScrollUp;