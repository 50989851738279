import React, { useState, useEffect } from 'react';
import Language from './Language.data';
import MainProductGroupList from './MainProductGroupList.container';
import CountrySelection from './CountrySelection.component';
import styled from 'styled-components';
import FocusBusinessSegment from './FocusBusinessSegment.component';
import * as Tracking from '../utils/matomo';
import BusinessUnitSelection from './BusinessUnitSelection.component';

const MainProductGroupListContainer = styled.div`
    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
    }

    @media (min-width: 992px) and (max-width:1200px) {
        padding-left:5vw;
        padding-right:5vw;
    }

    @media (min-width: 1200px) {
        padding-left:10vw;
        padding-right:10vw;
    }
` 

const CountrySelectionSticked = styled.div`
    position:sticky;
    top:72px;
    z-index:200;
    background-color:#fff;
`

function Products(props) {

    const handleCountryChange = (country) => {
        props.onCountryChange(country);
    }

    function handleShowMainProductGroupDetailsChange(mainProductGroup) {
        if(!props.showMainProductGroupDetails.includes(mainProductGroup.id)) {
            Tracking.trackEvent('Products','Click Products - ' + props.currentBU + ' - MPG: ' + mainProductGroup.name, 'Products - ' + props.currentBU + ' - MPG: ' + mainProductGroup.name);
        }
        props.onShowMainProductGroupDetailsChange(mainProductGroup);
    }

    function handleShowProductGroupDetailsChange(productGroup) {
        if(!props.showProductGroupDetails.includes(productGroup.productGroupID)) {
            Tracking.trackEvent('Products','Click Products - ' + props.currentBU + ' - PG: ' + productGroup.productGroupName + ' (' + productGroup.productGroupID + ')', 'Products - ' + props.currentBU + ' - PG: ' + productGroup.productGroupName + ' (' + productGroup.productGroupID + ')');
        }
        props.onShowProductGroupDetailsChange(productGroup);
    }

    useEffect(() => {
        if(document.getElementById('focusBU')) {
            window.scroll({
                top: document.getElementById('focusBU').offsetTop - 120, 
                left: 0, 
                behavior: 'smooth'
            });
        }
    },[props.currentBU]);

    return (
        <div>
            <BusinessUnitSelection
                currentLanguage={props.currentLanguage}
                currentBU={props.currentBU}
                onBUChange={(bu,page) => props.onBUChange(bu,page)}
            />
            <div id="focusBU">
                {
                    props.currentBU !== '' &&
                        <FocusBusinessSegment
                            businessSegment={props.currentBU}
                            currentLanguage={props.currentLanguage}
                        />
                }
            </div>
            <MainProductGroupListContainer className="container-fluid marginTop50px">
                { 
                    props.showMainProductGroups &&                         
                    <div>
                        <CountrySelectionSticked>
                            <div className="row marginTop50px marginBottom50px backgroundColorWhite">
                                <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3 text-center">
                                    <CountrySelection showSpacing={false} currentLanguage={props.currentLanguage} country={props.country} onCountryChange={handleCountryChange} />
                                </div>
                            </div>
                        </CountrySelectionSticked>
                        <div id="mainProductGroups" style={{paddingTop:"10px"}}>
                            <MainProductGroupList 
                                contacts={props.contacts} 
                                isLoading={props.isLoading} 
                                isLoadingMainProductGroups={props.isLoadingMainProductGroups}
                                currentLanguage={props.currentLanguage} 
                                country={props.country} 
                                currentBU={props.currentBU} 
                                mainProductGroups={props.mainProductGroups} 
                                showMainProductGroupDetails={props.showMainProductGroupDetails} 
                                onShowMainProductGroupDetailsChange={handleShowMainProductGroupDetailsChange} 
                                showProductGroupDetails={props.showProductGroupDetails} 
                                onShowProductGroupDetailsChange={handleShowProductGroupDetailsChange} 
                            />
                        </div>
                    </div>
                }	
            </MainProductGroupListContainer>
        </div>
    );

}

export default Products;