import React from 'react';
import Language from '../Language.data';

function Paper(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].industries.paper.paper}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].applications}:
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.paper.applicationText}
                </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.paper.industryText1}
                </div>
            </div>
            <div className="row mt-1 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.paper.industryText2}
                </div>
            </div>
            <div className="row mt-1 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.paper.industryText3}
                </div>
            </div>
            <div className="row mt-1 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.paper.productsAndProperties}:
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <ul>
                        <li>{Language[props.currentLanguage].functionalities.coloursAndPigmentsForSpecialEffects}</li>
                        <li>{Language[props.currentLanguage].functionalities.functionalFillersAndExtenders}</li>
                        <li>{Language[props.currentLanguage].functionalities.laminatingAdhesives}</li>
                        <li>{Language[props.currentLanguage].functionalities.opticalBrighteners}</li>
                    </ul>
                </div>
                <div className="col">
                    <ul>                            
                        <li>{Language[props.currentLanguage].functionalities.thickeners}</li>
                        <li>{Language[props.currentLanguage].functionalities.defoamers}</li>
                        <li>{Language[props.currentLanguage].functionalities.electricalConductivityAndInsulation}</li>
                        <li>{Language[props.currentLanguage].functionalities.flameRetardancy}</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Paper;