import React from 'react';
import Language from '../Language.data';

function PerformanceMaterialsAndFillers(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.fillers}</h3>
                    {Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.highQualityProductsRequirePerformance}
                    <br />
                    {Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.areYouLookingForRawMaterialsWithTheseFunctionalitiesProperties}
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <ul>
                        <li>{Language[props.currentLanguage].functionalities.mechanicalStrength}</li>
                        <li>{Language[props.currentLanguage].functionalities.barrierProperties}</li>
                        <li>{Language[props.currentLanguage].functionalities.heatTransfer}</li>
                        <li>{Language[props.currentLanguage].functionalities.thermalInsulation}</li>
                        <li>{Language[props.currentLanguage].functionalities.electricalAndThermalConductivity}</li>
                        <li>{Language[props.currentLanguage].functionalities.electricalInsulation}</li>
                        <li>{Language[props.currentLanguage].functionalities.durability}</li>
                        <li>{Language[props.currentLanguage].functionalities.resistance}</li>
                    </ul>
                </div>
                <div className="col">
                    <ul>                            
                        <li>{Language[props.currentLanguage].functionalities.ageingStability}</li>
                        <li>{Language[props.currentLanguage].functionalities.dimensionalStability}</li>
                        <li>{Language[props.currentLanguage].functionalities.lightReflectance}</li>
                        <li>{Language[props.currentLanguage].functionalities.mattingEffects}</li>
                        <li>{Language[props.currentLanguage].functionalities.rheologyImprovers}</li>
                        <li>{Language[props.currentLanguage].functionalities.easyProcessability}</li>
                        <li>{Language[props.currentLanguage].functionalities.soundAbsorbing}</li>
                    </ul>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.buText1}
                    <br />
                    {Language[props.currentLanguage].businessUnits.performanceMaterialsAndFillers.buText2}
                </div>
            </div>
        </>
    )
}

export default PerformanceMaterialsAndFillers;