import React, { Component } from 'react';
import Language from './Language.data';
import 'flag-icons/css/flag-icons.min.css';
require('bootstrap');
const jQuery = require('jquery');
window.jQuery = jQuery;
import ('bootstrap-select/dist/css/bootstrap-select.min.css');
require('bootstrap-select');
var _ = require('lodash');

class LanguageSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        jQuery('.selectpicker').selectpicker({
            liveSearch:true,
            selectOnTab: true,
        });
    }  

    componentDidMount() {
        jQuery('.selectpicker').selectpicker({
            liveSearch:true,
            selectOnTab: true,
        });
    }

    componentDidUpdate() {
        jQuery('.selectpicker').selectpicker({
            liveSearch:true,
            selectOnTab: true,
        });
    }
    

    onLanguageChange() {
        let language = this.refs.language.value;
        this.props.onLanguageChange(language);
    }

    render() {
        let languageList = this.props.languagesToDisplay.map((language) => {
            return ({
                    key: language,
                    language: Language[language]
            });
        });
        let languageListSorted = _.sortBy(languageList, 'languageName');
        return (
            <div className="form-group">
                <select id="selectLanguage" data-size="10" className="selectpicker form-control" ref="language" onChange={this.onLanguageChange.bind(this)} /*defaultValue={this.props.selectedLanguage}*/>
                    <option value="0">{Language[this.props.currentLanguage].selectLanguage.toUpperCase()}</option>
                    {
                        languageListSorted.map((language) => {
                            return (
                                <option value={language.key} key={"lang" + language.language.languageNo} data-icon={"fi fi-" + language.language.flag + " flagSelect"}>{language.language.languageName.toUpperCase()}</option>
                            );
                        })
                    }
                </select>
            </div>
        );
    }
}

export default LanguageSelection;