import React from 'react';
import styled from 'styled-components';
import { isNullOrUndefined } from '../utils/utils';

import externalLinkImage from '../images/externalLink.svg';

const StyledLink = styled.a`
    color:${props => props.color ? props.color : '#006384'};

    &:hover {
        color:${props => props.color ? props.color : '#006384'};
    }

    &:visited {
        color:${props => props.color ? props.color : '#006384'};
    }
`

export function Link(props) {
    return (
        <>
            <StyledLink 
                color={props.color}
                href={props.linkUrl}
                rel="noopener noreferrer"
                target={props.target ? props.target : '_self'}
            >
                {
                    props.linkText ? props.linkText : props.linkUrl
                }
            </StyledLink>
            {
                (isNullOrUndefined(props.isExternalLink) || props.isExternalLink === true) &&
                <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
            }
        </>
    )
}

export default Link;
