import React from 'react';
import Language from '../Language.data';

function Plastics(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].industries.plastics.plastics}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].applications}:
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.plastics.applicationText}
                </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.plastics.industryText1}
                </div>
            </div>
            <div className="row mt-1 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.plastics.industryText2}
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <ul>
                        <li>{Language[props.currentLanguage].functionalities.renewableRawMaterials}</li>
                        <li>{Language[props.currentLanguage].functionalities.wettingAndDispersing}</li>
                        <li>{Language[props.currentLanguage].functionalities.temperatureResistance}</li>
                        <li>{Language[props.currentLanguage].functionalities.flameRetardancy}</li>
                        <li>{Language[props.currentLanguage].functionalities.mechanicalImprovement}</li>
                        <li>{Language[props.currentLanguage].functionalities.defoaming}</li>
                        <li>{Language[props.currentLanguage].functionalities.enhancedElectricalProperties}</li>
                        <li>{Language[props.currentLanguage].functionalities.rheologyModification}</li>
                        <li>{Language[props.currentLanguage].functionalities.abrasionAndScratchResistance}</li>
                    </ul>
                </div>
                <div className="col">
                    <ul>                            
                        <li>{Language[props.currentLanguage].functionalities.surfaceImprovement}</li>
                        <li>{Language[props.currentLanguage].functionalities.weightReduction}</li>
                        <li>{Language[props.currentLanguage].functionalities.irReflective}</li>
                        <li>{Language[props.currentLanguage].functionalities.coloursAndEffects}</li>
                        <li>{Language[props.currentLanguage].functionalities.laserMarking}</li>
                        <li>{Language[props.currentLanguage].functionalities.tribology}</li>
                        <li>{Language[props.currentLanguage].functionalities.lightProtection}</li>
                        <li>{Language[props.currentLanguage].functionalities.thermalConductivity}</li>
                        <li>{Language[props.currentLanguage].functionalities.crosslinkingProperties}</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Plastics;