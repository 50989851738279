import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import Language from './Language.data.js';
import externalLinkImage from '../images/externalLink.svg';
import { isNullOrUndefined } from '../utils/utils.js';

const blendOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
`;

const Link = styled.a`
    position:relative;
`

const Image = styled.img`
    min-width:${props => props.imageMinWidth ? props.imageMinWidth : "auto"};
    max-width:${props => props.imageMaxWidth ? props.imageMaxWidth : "auto"};
    min-height:${props => props.imageMinHeight ? props.imageMinHeight : "auto"};
    width:${props => props.imageWidth ? props.imageWidth : "auto"};
    height:${props => props.imageHeight ? props.imageHeight : "auto"};
    cursor:pointer;
    margin-top:${props => props.marginTop ? props.marginTop : 'auto'};
    margin-bottom:${props => props.marginBottom ? props.marginBottom : 'auto'};
    -webkit-mask-image: ${props => props.imageIsMaskImage ? 'url(' + props.imageSrcTag + ')' : undefined};
    mask-image: ${props => props.imageIsMaskImage ? 'url(' + props.imageSrcTag + ')' : undefined};
    -webkit-mask-size: ${props => props.imageIsMaskImage ? 'contain' : undefined};
    mask-size: ${props => props.imageIsMaskImage ? 'contain' : undefined};
    -webkit-mask-position: ${props => props.imageIsMaskImage ? 'center' : undefined};
    mask-position: ${props => props.imageIsMaskImage ? 'center' : undefined};
    -webkit-mask-repeat: ${props => props.imageIsMaskImage ? 'no-repeat' : undefined};
    mask-repeat: ${props => props.imageIsMaskImage ? 'no-repeat' : undefined};
    background-color: ${props => props.imageBackgroundColor ? props.imageBackgroundColor : undefined};

    ${Link}:hover & {
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-animation-name: ${blendOut};
        animation-name: ${blendOut};
    }
`

const ExternalLinkImageDiv = styled.div`
    position:absolute;
    width:${props => props.iconWidth};
    height:${props => props.iconHeight};
    left:${props => props.iconPositonLeft ? props.iconPositonLeft : "auto"};
    top:${props => props.iconPositonTop ? props.iconPositonTop : "auto"};
    bottom:${props => props.iconPositonBottom ? props.iconPositonBottom : "auto"};
    right:${props => props.iconPositonRight ? props.iconPositonRight : "auto"};
    margin-left:${props => props.iconMarginLeft ? props.iconMarginLeft : "auto"};
    margin-top:${props => props.iconMarginTop ? props.iconMarginTop : "auto"};
    display:none;
    cursor:pointer;

    ${Link}:hover & {
        display:block;
    }
`

const ExternalLinkMask = styled.div`
    width:100%;
    height:100%;
    background-color:${props => props.maskColor ? props.maskColor : '#000'};
    -webkit-mask-image: url(${externalLinkImage});
    mask-image: url(${externalLinkImage});
    -webkit-mask-size: cover;
    mask-size:cover;
    background-size: cover;
    background-position: center;
`

const ExternalLinkText = styled.div`
    position:absolute;
    left:50%;
    top:50%;
    width:200px;
    height:30px;
    margin-left:-100px;
    margin-top:15px;
    display:none;
    color:#000;

    ${Link}:hover & {
        display:block;
    }
`

function ExternalLink(props) {
    return (
        <Link 
            href={props.linkHrefTag} 
            target={props.linkTargetTag} 
            rel="noopener noreferrer" 
            className={props.linkAdditionalClasses}
        >
            <Image 
                {...props} 
                src={(!isNullOrUndefined(props.imageIsMaskImage) && props.imageIsMaskImage === true) ? 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' : props.imageSrcTag} 
                alt={props.imageAltTag} 
                id={props.id}
                className="img-fluid"
                marginTop={props.imageMarginTop}
                marginBottom={props.imageMarginBottom}
            />
            <ExternalLinkImageDiv 
                {...props} 
                id={props.id + "ExternalLinkImage"} 
                title={Language[props.currentLanguage].externalLink}
            >
                <ExternalLinkMask 
                    {...props} 
                    key={props.id + "ExternalMask"}
                />
            </ExternalLinkImageDiv>
            { 
                props.showExternalLinkText && 
                <ExternalLinkText id={props.id + "externalLinkText"}>
                    <small>
                        {Language[props.currentLanguage].externalLink}
                    </small>
                </ExternalLinkText>
            }
        </Link>
    );
}

export default ExternalLink;