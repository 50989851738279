import React from 'react';
import Language from '../Language.data';

function Sealants(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].industries.sealants.sealants}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].applications}:
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.sealants.applicationText}
                </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.sealants.industryText}
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <ul>
                        <li>{Language[props.currentLanguage].functionalities.defoaming}</li>
                        <li>{Language[props.currentLanguage].functionalities.wettingAndDispersing}</li>
                        <li>{Language[props.currentLanguage].functionalities.viscosityAndRheologyModification}</li>
                        <li>{Language[props.currentLanguage].functionalities.flameRetardancy}</li>
                        <li>{Language[props.currentLanguage].functionalities.heatManagement}</li>
                        <li>{Language[props.currentLanguage].functionalities.colourAndEffectPigmentation}</li>
                    </ul>
                </div>
                <div className="col">
                    <ul>                            
                        <li>{Language[props.currentLanguage].functionalities.adhesion}</li>
                        <li>{Language[props.currentLanguage].functionalities.durability}</li>
                        <li>{Language[props.currentLanguage].functionalities.uvProtection}</li>
                        <li>{Language[props.currentLanguage].functionalities.renewableRawMaterials}</li>
                        <li>{Language[props.currentLanguage].functionalities.circularity}</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sealants;