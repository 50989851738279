import React, { Component } from 'react';
import Language from './Language.data';
import ContactPerson from './ContactPerson.component';
import CountrySelection from './CountrySelection.component';
import styled from 'styled-components';
import EmailAddress from './EmailAddress.component';
import LoadingSpinner from './LoadingSpinner.component.js';
import { ImageColumnDiv, ImageColumn, ImageColumnTextDiv } from '../styles/Image.styles';

import womenAndEqualOpportunitiesOfficer from '../images/portraits/AylinAnik.jpg';
import phone from '../images/phoneBlack.svg';
import fax from '../images/faxBlack.svg';
import email from '../images/emailBlack.svg';

const ContactRow = styled.div`
    display:flex;
    justify-content:center;
`

const ContactColumn = styled.div`
    display:flex;
    margin:1rem;
    width:100%;
`

/*
const ImageColumnDiv = styled.div`
    justify-content: end;

    @media only screen and (max-width: 425px) { 
        justify-content: center;
    }
    
`

const ImageColumn = styled.div`
    background-image:url(${womenAndEqualOpportunitiesOfficer});
    border-radius: 50%;
    width: 200px;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
`

const ImageColumnTextDiv = styled.div`
    text-align: left;

    @media only screen and (max-width: 425px) { 
        text-align: center;
    }
    
`
*/

class ContactList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts:[],
            isLoading:true
        };
        this.loadContacts = this.loadContacts.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0); 
        this.loadContacts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.country !== prevProps.country || this.props.currentLanguage !== prevProps.currentLanguage) {
            this.loadContacts();
        }
    }   

    shouldComponentUpdate() {
        return true;
    }

    handleCountryChange = (country) => {
        this.props.onCountryChange(country);
    }

    loadContacts() {
        this.setState({
            isLoading: true
        },() => {
            window.axios.post(window.location.origin + '/country/contacts', {
                requestType: 'getContacts',
                countryID: this.props.country.countryNo,
                languageID: Language[this.props.currentLanguage].languageNo
            })
            .then((response) => {
                if(response.data?.success) {
                    this.setState({ 
                        contacts: response.data.contacts, 
                        isLoading: false 
                    });
                }
            })
            .catch(function (error) {
                this.setState({ isLoading: false });
                console.log(error);
            });
        });
    }

    render() {
        return (
            <div className="container marginTop50px marginBottom50px area" id="contacts">
                {
                    (!this.props.hideHeader || this.props.hideHeader !== true) &&
                    <div className="row marginTop50px">
                        <div className="col-12 text-center">
                            <h2>{Language[this.props.currentLanguage].contact}</h2>
                        </div>
                    </div>
                }
                {
                    this.props.showCountrySelection &&
                    <div className="row marginTop50px marginBottom50px upper">
                        <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3 text-center">
                            <CountrySelection key={"contactListCountrySelection"} showSpacing={false} currentLanguage={this.props.currentLanguage} country={this.props.country} onCountryChange={this.handleCountryChange.bind(this)} />
                        </div>
                    </div>
                }
                <ContactRow className="row lower">
                    {
                        this.state.isLoading && 
                        <LoadingSpinner key="loadingSpinnerTermsAndConditions"></LoadingSpinner>
                    }
                    {
                        !this.state.isLoading && this.state.contacts?.map((contact) => {
                            return (
                                <ContactColumn key={"contactDiv" + contact.id} className="justify-content-center align-items-center">
                                    <ContactPerson contact={contact} currentLanguage={this.props.currentLanguage} key={"contact" + contact.id} />
                                </ContactColumn>
                            );
                        })
                    }
                </ContactRow>
                {
                    // Temporary show women's and equal opportunities officer here:
                    // will be transferred to separate page at a later stage
                    (this.props.country?.countryNo === 4 || this.props.country?.countryNo === 9999) &&
                    <>
                        <div className="mt-5">
                            <hr style={{width:'33%'}} />
                        </div>
                        <div className="row mt-5">
                            <div className="col text-center">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <ImageColumnDiv className="col d-flex">
                                        <ImageColumn 
                                            backgroundImage={womenAndEqualOpportunitiesOfficer}
                                        />
                                    </ImageColumnDiv>
                                    <ImageColumnTextDiv className="col">
                                        <div>
                                            <h5>
                                                Aylin Anik
                                            </h5>
                                        </div>
                                        <div className="card-subtitle mb-2 text-muted">
                                            {
                                                Language[this.props.currentLanguage].womensAndEqualOpportunitiesOfficer
                                            }
                                        </div>
                                        <div>
                                            <img src={phone} alt="Anrufen" className="phoneMain"></img><span> </span>
                                            <a href={"tel:+492131936801"} className="link">+49 (2131) 93 68-01</a>
                                        </div>
                                        <div>
                                            <img src={fax} alt="Fax" className="phoneMain"></img><span> </span>
                                            <a href={"tel:+4921319368264"} className="link">+49 (2131) 93 68-264</a>
                                        </div>
                                        <div>
                                            <img src={email} alt="Email" className="emailMain"></img><span> </span>
                                            <EmailAddress key={"emailAddressWomensAndEqualOpportunitiesOfficer"} classNames="link" emailAddress={"a.anik@grolman-group.com"} />
                                        </div>
                                    </ImageColumnTextDiv>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default ContactList;