import React, { Component } from 'react';
import Language from './Language.data';
import styled from 'styled-components';
import { getVisibleBusinessUnits } from '../utils/businessUnit';

import additives from '../images/Additives.webp';
import binders from '../images/Resins.webp';
import fillers from '../images/Fillers_Perfomormance_Materials.webp';
import pigments from '../images/Pigments_Dyes.webp';

require('bootstrap');

const RoundCornersImg = styled.img`
    border-radius: 1rem;
`

class SelectCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }  

    componentDidMount() {
        this.updateBULinks();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.mainProductGroupsForIndustry !== this.props.mainProductGroupsForIndustry) {
            this.updateBULinks();
        }
    }

    onBUChange(event, bu) {
		this.props.onBUChange(bu, true);
    }

    handleHover(event, bu) {
        var element = document.getElementById(bu);
        if(element.classList.contains("fadeInDown") || element.classList.contains("fadeInUp")) {
            if(element.classList.contains("fadeInDown")) {
                element.classList.remove("fadeInDown");
            }
            if(element.classList.contains("fadeInUp")) {
                element.classList.remove("fadeInUp");
            }
            element.classList.remove("animDelay2");
            element.classList.add("productLink");
        }
    }

    updateBULinks() {
        let visibleBusinessUnits = getVisibleBusinessUnits(this.props.mainProductGroupsForIndustry);
        this.setState({
            showBUAdditives: visibleBusinessUnits.isBusinessUnitAdditivesVisible,
            showBUBinders: visibleBusinessUnits.isBusinessUnitBindersVisible,
            showBUFillers: visibleBusinessUnits.isBusinessUnitFillersVisible,
            showBUPigments: visibleBusinessUnits.isBusinessUnitPigmentsVisible,
        });
    }
    

    render() {
        let noOfBUsToShow = 0;
        let buColumnClassName = '';
        if(this.state.showBUAdditives) {
            noOfBUsToShow++;
        }
        if(this.state.showBUBinders) {
            noOfBUsToShow++;
        }
        if(this.state.showBUFillers) {
            noOfBUsToShow++;
        }
        if(this.state.showBUPigments) {
            noOfBUsToShow++;
        }
        switch(noOfBUsToShow) {
            case 1:
                buColumnClassName = 'col-12';
                break;
            case 2:
                buColumnClassName = 'col-12 col-sm-6 col-md-6';
                break;
            case 3:
                buColumnClassName = 'col-12 col-sm-4 col-md-4';
                break;
            case 4:
                buColumnClassName = 'col-12 col-sm-6 col-md-3';
                break;
            default:
                break;
        }
        return (
                (noOfBUsToShow > 0) &&
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4>{Language[this.props.currentLanguage].pleaseSelectACategory}</h4>
                        </div>
                    </div>
                    <div className="row">
                        {
                            this.state.showBUAdditives &&
                            <div className={buColumnClassName + " text-center padding10px animated cursorPointer animDelay2 productLink"} id="additives1" onClick={(e) => this.onBUChange(e, 'additives')} onMouseOver={(e) => this.handleHover(e, 'additives1')}>
                                <div className="row">
                                    <div className="col-12">
                                        <RoundCornersImg src={additives} alt="Additives" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {Language[this.props.currentLanguage].businessUnits.additives.additives}
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.showBUBinders &&
                            <div className={buColumnClassName + " text-center padding10px animated cursorPointer animDelay2 productLink"} id="binders1" onClick={(e) => this.onBUChange(e, 'binders')} onMouseOver={(e) => this.handleHover(e, 'binders1')}>
                                <div className="row">
                                    <div className="col-12">
                                        <RoundCornersImg src={binders} alt="Resins" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {Language[this.props.currentLanguage].businessUnits.binders.binders}
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.showBUFillers &&
                            <div className={buColumnClassName + " text-center padding10px animated cursorPointer animDelay2 productLink"} id="fillers" onClick={(e) => this.onBUChange(e, 'fillers')} onMouseOver={(e) => this.handleHover(e, 'fillers')}>
                                <div className="row">
                                    <div className="col-12">
                                        <RoundCornersImg src={fillers} alt="Fillers" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {Language[this.props.currentLanguage].businessUnits.performanceMaterialsAndFillers.fillers}
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.showBUPigments &&
                            <div className={buColumnClassName + " text-center padding10px animated cursorPointer animDelay2 productLink"} id="pigments" onClick={(e) => this.onBUChange(e, 'pigments')} onMouseOver={(e) => this.handleHover(e, 'pigments')}>
                                <div className="row">
                                    <div className="col-12">
                                        <RoundCornersImg src={pigments} alt="PigmentsAndDyes" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {Language[this.props.currentLanguage].businessUnits.pigmentsAndDyes.pigments}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
        );
    }
}

export default SelectCategory;