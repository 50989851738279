import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import ExternalLink from './ExternalLink.component';
import * as Tracking from '../utils/matomo';
import { FormattedText } from '../styles/FormattedText.styles';
import Fonts from './Fonts.component';

import amazon from '../images/podcast/amazon_music.png';
import apple from '../images/podcast/apple_podcasts.png';
import google from '../images/podcast/google_podcasts.png';
import spotify from '../images/podcast/spotify.png';
import anchor from '../images/podcast/anchor_fm.png';

const fadeIn = keyframes`
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
`;

const Inner = styled.div`
    height:0px;
    display:none;
    opacity:0;
`

const OverlayDiv = styled.div`
    position: fixed;
    top:0px;
    right:25%;
    width: 265px;
    height: fit-content;
    background-color: #B4C985;
    color: #fff;
    font-weight: bold;
    z-index: 10000;
    padding: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    &:hover {
        ${Inner} {
            height:fit-content;
            display:inline-block;
            animation: ${fadeIn};
            animation-duration: 1.2s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
            /*animation-delay: 0.3s;*/
        }
    }

    @media (max-width: 1336px) { 
        display:none;
    }
`

const PodcastFooterContainerDiv = styled.div`
    @media (min-width: 1336px) { 
        display:none;
    }
` 

const PodcastDiv = styled.div`
    background-color:#B4C985 !important;
    color:#fff !important;
`

const PodcastContainer = styled.div`
    background-color:#B4C985 !important;
    color:#fff !important;
    padding:1.5rem;
`

function PodcastAdvertisingSpace(props) {

    const [isPodcastAdvertiseVisible, setIsPodcastAdvertiseVisible] = useState(false);

    useEffect(() => {
        if(new Date() > new Date(2023, 0, 17)) {
            setIsPodcastAdvertiseVisible(true);
        }
    },[]);

    function handlePodcastClick() {
        Tracking.trackEvent('General','Click Podcast','Podcast overlay link');
    }

    return (
        <>
            {
                isPodcastAdvertiseVisible && 
                <>
                    <Fonts />
                    {
                        props.isOverlay === true &&
                        <OverlayDiv>
                            <div className="text-center">
                                <FormattedText
                                    color={"#006384"}
                                    fontFamily={"Bobby Jones Soft W05 Regular"}
                                    fontSize={"1.25rem"}
                                    letterSpacing={"1.5px"}
                                    lineHeight={"0.3"}
                                >
                                    NEW:
                                </FormattedText>
                                <FormattedText
                                    className="ml-1"
                                    color={"#fff"}
                                    fontFamily={"Bobby Jones Soft W05 Regular"}
                                    fontSize={"1.25rem"}
                                    letterSpacing={"1.5px"}
                                    lineHeight={"0.3"}
                                >
                                    Grolman Podcast Circular Table Talks
                                </FormattedText>
                            </div>
                            <Inner className="w-100 mt-2">
                                <div className="mt-1 text-center">
                                    <span>
                                        <ExternalLink
                                            currentLanguage={props.currentLanguage}
                                            key={spotify}
                                            linkHrefTag={"https://open.spotify.com/show/1DDuzUHgOtBxDrbXOr7AEq"}
                                            linkTargetTag={"_blank"}
                                            linkAdditionalClasses={"img-fluid"}
                                            imageSrcTag={spotify}
                                            imageAltTag={"Grolman Podcast on Spotify"}
                                            imageHeight={"30px"}
                                            iconWidth={"16px"}
                                            iconHeight={"16px"}
                                            iconPositonRight={"-5px"}
                                            iconPositonBottom={"-10px"}
                                            maskColor={"#006384"}
                                            showExternalLinkText={false}
                                        />
                                    </span>
                                    <span className="ml-1">
                                        <ExternalLink
                                            currentLanguage={props.currentLanguage}
                                            key={apple}
                                            linkHrefTag={"https://podcasts.apple.com/us/podcast/grolman-circular-table-talks/id1662850135"}
                                            linkTargetTag={"_blank"}
                                            linkAdditionalClasses={"img-fluid"}
                                            imageSrcTag={apple}
                                            imageAltTag={"Grolman Podcast on Apple Podcasts"}
                                            imageHeight={"30px"}
                                            iconWidth={"16px"}
                                            iconHeight={"16px"}
                                            iconPositonRight={"-5px"}
                                            iconPositonBottom={"-10px"}
                                            maskColor={"#006384"}
                                            showExternalLinkText={false}
                                        />
                                    </span>
                                    <span className="ml-1">
                                        <ExternalLink
                                            currentLanguage={props.currentLanguage}
                                            key={google}
                                            linkHrefTag={"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kNDU1MTkzMC9wb2RjYXN0L3Jzcw"}
                                            linkTargetTag={"_blank"}
                                            linkAdditionalClasses={"img-fluid"}
                                            imageSrcTag={google}
                                            imageAltTag={"Grolman Podcast on Google"}
                                            imageHeight={"30px"}
                                            iconWidth={"16px"}
                                            iconHeight={"16px"}
                                            iconPositonRight={"-5px"}
                                            iconPositonBottom={"-10px"}
                                            maskColor={"#006384"}
                                            showExternalLinkText={false}
                                        />
                                    </span>
                                    <span className="ml-1">
                                        <ExternalLink
                                            currentLanguage={props.currentLanguage}
                                            key={amazon}
                                            linkHrefTag={"https://music.amazon.com/podcasts/d005b892-bca7-4e37-8af1-0d4784ea7530/grolman-circular-table-talks"}
                                            linkTargetTag={"_blank"}
                                            linkAdditionalClasses={"img-fluid"}
                                            imageSrcTag={amazon}
                                            imageAltTag={"Grolman Podcast on Amazon Music"}
                                            imageHeight={"30px"}
                                            iconWidth={"16px"}
                                            iconHeight={"16px"}
                                            iconPositonRight={"-5px"}
                                            iconPositonBottom={"-10px"}
                                            maskColor={"#006384"}
                                            showExternalLinkText={false}
                                        />
                                    </span>
                                    <span className="ml-1">
                                        <ExternalLink
                                            currentLanguage={props.currentLanguage}
                                            key={anchor}
                                            linkHrefTag={"https://anchor.fm/grolman-podcast"}
                                            linkTargetTag={"_blank"}
                                            linkAdditionalClasses={"img-fluid"}
                                            imageSrcTag={anchor}
                                            imageAltTag={"Grolman Podcast on Anchor FM"}
                                            imageHeight={"30px"}
                                            iconWidth={"16px"}
                                            iconHeight={"16px"}
                                            iconPositonRight={"-5px"}
                                            iconPositonBottom={"-10px"}
                                            maskColor={"#006384"}
                                            showExternalLinkText={false}
                                        />
                                    </span>
                                </div>
                                <div className="mt-2 text-center">
                                    <Link
                                        className="podcastLink font-weight-normal"
                                        to="/podcast" 
                                        key={"linkToPodcast"} 
                                        onClick={handlePodcastClick}
                                    >
                                        More Info
                                    </Link>
                                </div>
                            </Inner>
                        </OverlayDiv>
                    }
                    {/*<div className="d-xs-block d-xl-none">*/}
                    {
                        (props.isOverlay === undefined || props.isOverlay === false) &&
                        
                        <PodcastFooterContainerDiv>
                            <PodcastDiv>
                                <PodcastContainer className="container-fluid">
                                    <div className="row d-flex align-items-center">
                                        <div className="col text-center">
                                            <span className="font-weight-bold">
                                                <FormattedText
                                                    color={"#006384"}
                                                    fontFamily={"Bobby Jones Soft W05 Regular"}
                                                    fontSize={"1.25rem"}
                                                    letterSpacing={"1.5px"}
                                                >
                                                    NEW:
                                                </FormattedText>
                                                <FormattedText
                                                    className="ml-1"
                                                    color={"#fff"}
                                                    fontFamily={"Bobby Jones Soft W05 Regular"}
                                                    fontSize={"1.25rem"}
                                                    letterSpacing={"1.5px"}
                                                >
                                                    Grolman Podcast Circular Table Talks
                                                </FormattedText>
                                            </span>
                                        </div>
                                        <div className="col-12 col-md text-center">
                                            <span className="ml-2">
                                                <ExternalLink
                                                    currentLanguage={props.currentLanguage}
                                                    key={spotify}
                                                    linkHrefTag={"https://open.spotify.com/show/1DDuzUHgOtBxDrbXOr7AEq"}
                                                    linkTargetTag={"_blank"}
                                                    linkAdditionalClasses={"img-fluid"}
                                                    imageSrcTag={spotify}
                                                    imageAltTag={"Grolman Podcast on Spotify"}
                                                    imageHeight={"32px"}
                                                    iconWidth={"16px"}
                                                    iconHeight={"16px"}
                                                    iconPositonRight={"-5px"}
                                                    iconPositonBottom={"-10px"}
                                                    maskColor={"#006384"}
                                                    showExternalLinkText={false}
                                                />
                                            </span>
                                            <span className="ml-1">
                                                <ExternalLink
                                                    currentLanguage={props.currentLanguage}
                                                    key={apple}
                                                    linkHrefTag={"https://podcasts.apple.com/us/podcast/grolman-circular-table-talks/id1662850135"}
                                                    linkTargetTag={"_blank"}
                                                    linkAdditionalClasses={"img-fluid"}
                                                    imageSrcTag={apple}
                                                    imageAltTag={"Grolman Podcast on Apple Podcasts"}
                                                    imageHeight={"32px"}
                                                    iconWidth={"16px"}
                                                    iconHeight={"16px"}
                                                    iconPositonRight={"-5px"}
                                                    iconPositonBottom={"-10px"}
                                                    maskColor={"#006384"}
                                                    showExternalLinkText={false}
                                                />
                                            </span>
                                            <span className="ml-1">
                                                <ExternalLink
                                                    currentLanguage={props.currentLanguage}
                                                    key={google}
                                                    linkHrefTag={"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kNDU1MTkzMC9wb2RjYXN0L3Jzcw"}
                                                    linkTargetTag={"_blank"}
                                                    linkAdditionalClasses={"img-fluid"}
                                                    imageSrcTag={google}
                                                    imageAltTag={"Grolman Podcast on Google"}
                                                    imageHeight={"32px"}
                                                    iconWidth={"16px"}
                                                    iconHeight={"16px"}
                                                    iconPositonRight={"-5px"}
                                                    iconPositonBottom={"-10px"}
                                                    maskColor={"#006384"}
                                                    showExternalLinkText={false}
                                                />
                                            </span>
                                            <span className="ml-1">
                                                <ExternalLink
                                                    currentLanguage={props.currentLanguage}
                                                    key={amazon}
                                                    linkHrefTag={"https://music.amazon.com/podcasts/d005b892-bca7-4e37-8af1-0d4784ea7530/grolman-circular-table-talks"}
                                                    linkTargetTag={"_blank"}
                                                    linkAdditionalClasses={"img-fluid"}
                                                    imageSrcTag={amazon}
                                                    imageAltTag={"Grolman Podcast on Amazon Music"}
                                                    imageHeight={"32px"}
                                                    iconWidth={"16px"}
                                                    iconHeight={"16px"}
                                                    iconPositonRight={"-5px"}
                                                    iconPositonBottom={"-10px"}
                                                    maskColor={"#006384"}
                                                    showExternalLinkText={false}
                                                />
                                            </span>
                                            <span className="ml-1">
                                                <ExternalLink
                                                    currentLanguage={props.currentLanguage}
                                                    key={anchor}
                                                    linkHrefTag={"https://anchor.fm/grolman-podcast"}
                                                    linkTargetTag={"_blank"}
                                                    linkAdditionalClasses={"img-fluid"}
                                                    imageSrcTag={anchor}
                                                    imageAltTag={"Grolman Podcast on Anchor FM"}
                                                    imageHeight={"32px"}
                                                    iconWidth={"16px"}
                                                    iconHeight={"16px"}
                                                    iconPositonRight={"-5px"}
                                                    iconPositonBottom={"-10px"}
                                                    maskColor={"#006384"}
                                                    showExternalLinkText={false}
                                                />
                                            </span>
                                        </div>
                                        <div className="col text-center">
                                            <Link
                                                className="podcastLink font-weight-normal ml-3"
                                                to="/podcast" 
                                                key={"linkToPodcast"} 
                                                onClick={handlePodcastClick}
                                            >
                                                More Info
                                            </Link>
                                        </div>
                                    </div>
                                </PodcastContainer>
                            </PodcastDiv>
                        </PodcastFooterContainerDiv>
                    }
                </>
            }
        </>
    )
}

export default PodcastAdvertisingSpace;