const Country = {
    grolmanGroup:
    {
        'key':'grolmanGroup',
        'iso':'gg',
        'countryNo': 9999,
        'name': 
        {
            de: 'Grolman Group',
            en: 'Grolman Group',
            pl: 'Grupa Grolman',
        },
        'flag':'fi fi-group',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': true,
        'productGroupInfosNotAvailable' : false,
    },
    albania:
    {
        'key':'albania',
        'iso':'al',
        'countryNo': 70,
        'name': 
        {
            de: 'Albanien',
            en: 'Albania',
            pl: 'Albania',
        },
        'flag':'fi fi-al',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    algeria:
    {
        'key':'algeria',
        'iso':'dz',
        'countryNo': 202,
        'name': 
        {
            de: 'Algieria',
            en: 'Algerien',
            pl: 'Algieria',
        },
        'flag':'fi fi-dz',
        'showCountry':false,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    austria:
    {
        'key':'austria',
        'iso':'at',
        'countryNo': 38,
        'name': 
        {
            de: 'Österreich',
            en: 'Austria',
            pl: 'Austria',
        },
        'flag':'fi fi-at',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    belgium:
    {
        'key':'belgium',
        'iso':'be',
        'countryNo': 2,
        'name': 
        {
            de: 'Belgien',
            en: 'Belgium',
            pl: 'Belgia',
        },
        'flag':'fi fi-be',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    bosniaAndHerzegovina:
    {
        'key':'bosniaAndHerzegovina',
        'iso':'ba',
        'countryNo': 93,
        'name': 
        {
            de: 'Bosnien und Herzegowina',
            en: 'Bosnia and Herzegovina',
            pl: 'Bośnia i Hercegowina',
        },
        'flag':'fi fi-ba',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    bulgaria:
    {
        'key':'bulgaria',
        'iso':'bg',
        'countryNo': 68,
        'name': 
        {
            de: 'Bulgarien',
            en: 'Bulgaria',
            pl: 'Bulgaria',
        },
        'flag':'fi fi-bg',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    china:
    {
        'key':'china',
        'iso':'cn',
        'countryNo': 720,
        'name': 
        {
            de: 'China',
            en: 'China',
            pl: 'Chiny',
        },
        'flag':'fi fi-cn',
        'showCountry':true,
        'showVATNo':false,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable': false,
    },
    croatia:
    {
        'key':'croatia',
        'iso':'hr',
        'countryNo': 92,
        'name': 
        {
            de: 'Kroatien',
            en: 'Croatia',
            pl: 'Chorwacja',
        },
        'flag':'fi fi-hr',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    cyprus:
    {
        'key':'cyprus',
        'iso':'CY',
        'countryNo': 12,
        'name': 
        {
            de: 'Zypern',
            en: 'Cyprus',
            pl: 'Cypr',
        },
        'flag':'fi fi-cy',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    czechRepublic:
    {
        'key':'czechRepublic',
        'iso':'cz',
        'countryNo': 61,
        'name': 
        {
            de: 'Tschechische Republik',
            en: 'Czech Republic',
            pl: 'Czechy',
        },
        'flag':'fi fi-cz',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    denmark:
    {
        'key':'denmark',
        'iso':'dk',
        'countryNo': 8,
        'name': 
        {
            de: 'Dänemark',
            en: 'Denmark',
            pl: 'Dania',
        },
        'flag':'fi fi-dk',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    eire:
    {
        'key':'eire',
        'iso':'ie',
        'countryNo': 7,
        'name': 
        {
            de: 'Irland',
            en: 'Eire',
            pl: 'Irlandia',
        },
        'flag':'fi fi-ie',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    egypt:
    {
        'key':'egypt',
        'iso':'eg',
        'countryNo': 201,
        'name': 
        {
            de: 'Ägypten',
            en: 'Egypt',
            pl: 'Egipt',
        },
        'flag':'fi fi-eg',
        'showCountry':true,
        'showVATNo':false,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    estonia:
    {
        'key':'estonia',
        'iso':'ee',
        'countryNo': 53,
        'name': 
        {
            de: 'Estland',
            en: 'Estonia',
            pl: 'Estonia',
        },
        'flag':'fi fi-ee',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    finland:
    {
        'key':'finland',
        'iso': 'fi',
        'countryNo': 32,
        'name': 
        {
            de: 'Finnland',
            en: 'Finland',
            pl: 'Finlandia',
        },
        'flag':'fi fi-fi',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    france:
    {
        'key':'france',
        'iso':'fr',
        'countryNo': 1,
        'name': 
        {
            de: 'Frankreich',
            en: 'France',
            pl: 'Francja',
        },
        'flag':'fi fi-fr',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    germany:
    {
        'key':'germany',
        'iso':'de',
        'countryNo': 4,
        'name': 
        {
            de: 'Deutschland',
            en: 'Germany',
            pl: 'Niemcy',
        },
        'flag':'fi fi-de',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    greatBritain:
    {
        'key':'greatBritain',
        'iso':'gb',
        'countryNo': 6,
        'name': 
        {
            de: 'Großbritannien',
            en: 'Great Britain',
            pl: 'Wielka Brytania',
        },
        'flag':'fi fi-gb',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    greece:
    {
        'key':'greece',
        'iso':'gr',
        'countryNo': 9,
        'name': 
        {
            de: 'Griechenland',
            en: 'Greece',
            pl: 'Grecja',
        },
        'flag':'fi fi-gr',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    hungary:
    {
        'key':'hungary',
        'iso':'hu',
        'countryNo': 64,
        'name': 
        {
            de: 'Ungarn',
            en: 'Hungary',
            pl: 'Węgry',
        },
        'flag':'fi fi-hu',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    iceland:
    {
        'key':'iceland',
        'iso':'is',
        'countryNo': 24,
        'name': 
        {
            de: 'Island',
            en: 'Iceland',
            pl: 'Islandia',
        },
        'flag':'fi fi-is',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    india:
    {
        'key':'india',
        'iso':'in',
        'countryNo': 664,
        'name': 
        {
            de: 'Indien',
            en: 'India',
            pl: 'Indie',
        },
        'flag':'fi fi-in',
        'showCountry':true,
        'showVATNo':false,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    italy:
    {
        'key':'italy',
        'iso':'it',
        'countryNo': 5,
        'name': 
        {
            de: 'Italien',
            en: 'Italy',
            pl: 'Włochy',
        },
        'flag':'fi fi-it',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    }, 
    kosovo:
    {
        'key':'kosovo',
        'iso':'xk',
        'countryNo': 95,
        'name': 
        {
            de: 'Kosovo',
            en: 'Kosovo',
            pl: 'Kosowo',
        },
        'flag':'fi fi-xk',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    latvia:
    {
        'key':'latvia',
        'iso':'lv',
        'countryNo': 54,
        'name': 
        {
            de: 'Lettland',
            en: 'Latvia',
            pl: 'Łotwa',
        },
        'flag':'fi fi-lv',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    lithuania:
    {
        'key':'lithuania',
        'iso':'lt',
        'countryNo': 55,
        'name': 
        {
            de: 'Litauen',
            en: 'Lithuania',
            pl: 'Lithania',
        },
        'flag':'fi fi-lt',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    luxemburg:
    {
        'key':'luxemburg',
        'iso':'lu',
        'countryNo': 960,
        'name': 
        {
            de: 'Luxemburg',
            en: 'Luxemburg',
            pl: 'Luksemburg',
        },
        'flag':'fi fi-lu',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    macedonia:
    {
        'key':'macedonia',
        'iso':'mk',
        'countryNo': 96,
        'name': 
        {
            de: 'Mazedonien',
            en: 'Macedonia',
            pl: 'Macedonia',
        },
        'flag':'',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    moldova:
    {
        'key':'moldova',
        'iso':'md',
        'countryNo': 74,
        'name': 
        {
            de: 'Republik Moldau',
            en: 'Moldova',
            pl: 'Mołdawia',
        },
        'flag':'fi fi-md',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    montenegro:
    {
        'key':'montenegro',
        'iso':'me',
        'countryNo': 97,
        'name': 
        {
            de: 'Montenegro',
            en: 'Montenegro',
            pl: 'Montenegro',
        },
        'flag':'fi fi-me',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    morocco:
    {
        'key':'morocco',
        'iso':'ma',
        'countryNo': 204,
        'name': 
        {
            de: 'Marokko',
            en: 'Morocco',
            pl: 'Maroko',
        },
        'flag':'fi fi-ma',
        'showCountry':true,
        'showVATNo':false,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    netherlands:
    {
        'key':'netherlands',
        'iso':'nl',
        'countryNo': 3,
        'name': 
        {
            de: 'Niederlande',
            en: 'Netherlands',
            pl: 'Holandia',
        },
        'flag':'fi fi-nl',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    norway:
    {
        'key':'norway',
        'iso':'no',
        'countryNo': 28,
        'name': 
        {
            de: 'Norwegen',
            en: 'Norway',
            pl: 'Norwegia',
        },
        'flag':'fi fi-no',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    poland:
    {
        'key':'poland',
        'iso':'pl',
        'countryNo': 60,
        'name': 
        {
            de: 'Polen',
            en: 'Poland',
            pl: 'Polska',
        },
        'flag':'fi fi-pl',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    portugal:
    {
        'key':'portugal',
        'iso':'pt',
        'countryNo': 10,
        'name': 
        {
            de: 'Portugal',
            en: 'Portugal',
            pl: 'Portugalia',
        },
        'flag':'fi fi-pt',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    romania:
    {
        'key':'romania',
        'iso':'ro',
        'countryNo': 66,
        'name': 
        {
            de: 'Rumänien',
            en: 'Romania',
            pl: 'Rumunia',
        },
        'flag':'fi fi-ro',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    serbia:
    {
        'key':'serbia',
        'iso':'rs',
        'countryNo': 94,
        'name': 
        {
            de: 'Serbien',
            en: 'Serbia',
            pl: 'Serbia',
        },
        'flag':'fi fi-rs',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    slovakia:
    {
        'key':'slovakia',
        'iso':'sk',
        'countryNo': 63,
        'name': 
        {
            de: 'Slowakei',
            en: 'Slovakia',
            pl: 'Słowacja',
        },
        'flag':'fi fi-sk',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    slovenia:
    {
        'key':'slovenia',
        'iso':'si',
        'countryNo': 91,
        'name': 
        {
            de: 'Slowenien',
            en: 'Slovenia',
            pl: 'Słowenia',
        },
        'flag':'fi fi-si',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    spain:
    {
        'key':'spain',
        'iso':'es',
        'countryNo': 11,
        'name': 
        {
            de: 'Spanien',
            en: 'Spain',
            pl: 'Hiszpania',
        },
        'flag':'fi fi-es',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    sweden:
    {
        'key':'sweden',
        'iso':'se',
        'countryNo': 30,
        'name': 
        {
            de: 'Schweden',
            en: 'Sweden',
            pl: 'Szwecja',
        },
        'flag':'fi fi-se',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    switzerland:
    {
        'key':'switzerland',
        'iso':'ch',
        'countryNo': 39,
        'name': 
        {
            de: 'Schweiz',
            en: 'Switzerland',
            pl: 'Szwajcaria',
        },
        'flag':'fi fi-ch',
        'showCountry':true,
        'showVATNo':false,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    tunisia:
    {
        'key':'tunisia',
        'iso':'tn',
        'countryNo': 212,
        'name': 
        {
            de: 'Tunesien',
            en: 'Tunisia',
            pl: 'Tunezja',
        },
        'flag':'fi fi-tn',
        'showCountry':false,
        'showVATNo':false,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable' : false,
    },
    turkey:
    {
        'key':'turkey',
        'iso':'tr',
        'countryNo': 52,
        'name': 
        {
            de: 'Türkei',
            en: 'Turkiye',
            pl: 'Turcja',
        },
        'flag':'fi fi-tr',
        'showCountry':true,
        'showVATNo':true,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable': false,
    },
    ukraine: 
    {
        'key':'ukraine',
        'iso':'ua',
        'countryNo':72,
        'name': {
            de: 'Ukraine',
            en: 'Ukraine',
            pl: 'Ukraina',
        },
        'flag': 'fi fi-ua',
        'showCountry': true,
        'showVATNo': false,
        'productGroupInfosNeedCountrySelection': false,
        'productGroupInfosNotAvailable': false,
    },
}
export default Country;