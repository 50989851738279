import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Link as ExternalLink } from '../Link.component.js';
import HighlightText from '../HighlightText.component.js';
import { isNullOrUndefined } from '../../utils/utils.js';

const LinkResult = styled.div`
    margin-top:1rem;
    margin-bottom:1rem;
    font-size:1.25rem;
    font-weight:bold;
`

const LinkResultText = styled.div`
    margin-top:0.25rem;
    font-size:1rem;
    font-weight:normal;
    color:#777;
`

const SearchLinkResultContainer = styled.div`
    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
        
    }
`

function SearchLinkResult(props) {

    return (
        <SearchLinkResultContainer className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card-body">
                        <div className="borderRadius10px border1pxSolide0e0e0 padding10px">
                            <div className="row">
                                <LinkResult className="col-12">
                                    <div>
                                        {
                                            props.link.isInternalLink === 0 &&
                                            <ExternalLink
                                                currentLanguage={props.currentLanguage}
                                                key={props.link.id}
                                                linkUrl={props.link.link}
                                                linkText={
                                                    /*props.link.title*/
                                                    <HighlightText
                                                        text={props.link.title}
                                                        highlight={props.currentSearchText}
                                                    />
                                                }
                                                target={"_blank"}
                                            />
                                        }
                                        {
                                            props.link.isInternalLink === 1 && 
                                            <Link 
                                                to={props.link.link} 
                                                className="color006384"
                                            >
                                                {
                                                    /*
                                                    props.link.title
                                                    */
                                                }
                                                <HighlightText
                                                    text={props.link.title}
                                                    highlight={props.currentSearchText}
                                                />
                                            </Link>
                                        }
                                    </div>
                                    {
                                        !isNullOrUndefined(props.link.search_keyword_text) &&
                                        <LinkResultText>
                                            {
                                                /*
                                                `${props.link.search_keyword_text.keyword_text}...`
                                                */
                                            }
                                            <HighlightText
                                                text={props.link.search_keyword_text.keyword_text}
                                                highlight={props.currentSearchText}
                                            />
                                        </LinkResultText>
                                    }
                                </LinkResult>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SearchLinkResultContainer>
    )
}

export default SearchLinkResult;