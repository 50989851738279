import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Language from '../Language.data.js';
import Address from '../Address.component';
import LoadingSpinner from '../LoadingSpinner.component.js';

const PrivacyDataListItem = styled.li`
    width: fit-content;
`

function ResponsibilityAndDataProtectionOfficer(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [country, setCountry] = useState(null);

    useEffect(() => {
        window.axios.post(window.location.origin + '/country/locationDetails', {
            requestType: 'getLocationDetails',
            countryID: 4,
            languageID: 6
          })
          .then((response) => {
            if(response.data.success === true) {
                setCountry(response.data.country);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
    },[]);

    return (
        <>
            <div className="row mt-5">
                <div className="col text-center">
                    <h4>
                        {
                            Language[props.selectedLanguage].privacyTexts.responsibilityAndDataProtectionOfficer
                        }
                    </h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        Language[props.selectedLanguage].privacyTexts.controllerOfProcessingPersonalData
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        isLoading && 
                        <LoadingSpinner key="loadingSpinnerPrivacyResponsibilityAndDataProtectionOfficer" />
                    }
                    {
                        !isLoading &&
                        <Address
                            country={country}
                            showWebsiteLink={true}
                        />
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-justify">
                    {
                        new Date() < new Date('2024-01-01 00:00:00') &&
                        Language[props.selectedLanguage].privacyTexts.dataProtectionOfficerFoxOn
                    }
                    {
                        new Date() >= new Date('2024-01-01 00:00:00') &&
                        Language[props.selectedLanguage].privacyTexts.dataProtectionOfficerThomasSchlueter
                    }
                </div>
            </div>
        </>
    )
}

export default ResponsibilityAndDataProtectionOfficer;