import React, { Component, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import App from './app.container';
//import Podcast from './components/Podcast.component';
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BrowserRouter as Router } from 'react-router-dom';

if('serviceWorker' in navigator) {
    navigator.serviceWorker
    .register('/serviceWorker.js')
    .then(function() {
        console.log("Service worker registered!");
    });
}

if(document.getElementById('app')) {
    const container = document.getElementById('app');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Router>
                <App />
            </Router>
        </React.StrictMode>
    );
}

/*
if(document.getElementById('podcast')) {

    const container = document.getElementById('podcast');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Podcast />
        </React.StrictMode>
    );
}
*/
