import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import LoadingSpinner from '../LoadingSpinner.component.js';
import ContactPerson from '../ContactPerson.component.js';
import Language from '../Language.data';
import Country from '../Country.data.js';
import { isNullOrUndefined } from '../../utils/utils.js';

const DistributionCountryDiv = styled.div`
    border-radius:0.25rem;
    padding:0.5rem;
    cursor:pointer;
`

const DistributionCountryDetailsDiv = styled.div`
    cursor:default;
    color:#000;
    margin-top:0.5rem;
    background-color:#fff !important;
    border-radius:0.25rem;
`

function DistributionCountry(props) {

    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [contact, setContact] = useState(props.contact);
    const country = Country[Object.keys(Country).find(key => Country[key].countryNo === props.countryLink.country.ID)];

    useEffect(() => {
        let updatedContact = _.cloneDeep(props.contact);
        let roles = [];
        props.contact.contact_roles.forEach((contactRole) => {
            let rolesInLanguage = contactRole.roles.filter((role) => role.Sprach_ID === Language[props.currentLanguage].languageNo); 
            rolesInLanguage.forEach((role) => {
                let isAlreadyIn = roles.find((r) => r.ID === role.ID);
                if(isAlreadyIn === undefined) {
                    roles.push(role);
                }
            });
        });
        updatedContact.function = roles.map((role) => role.Funktion_Bezeichnung).join(', ');
        setContact(updatedContact);
    },[]);
    
    return (
        <DistributionCountryDiv className="animated mainProductGroupLink" onClick={(event) => setIsDetailsOpen(!isDetailsOpen)}>
            {
                !isNullOrUndefined(country) &&
                <span className={country.flag + " flagSelect"} />
            }
            {
                ` ${props.countryLink.country.Bezeichnung}`
            }
            {
                isDetailsOpen &&
                <DistributionCountryDetailsDiv>
                    <ContactPerson 
                        contact={contact} 
                        key={`contact.${props.countryLink.Auto_ID}`} 
                        imagePosition={"center"}
                        imageSize={"140px"}
                    />
                </DistributionCountryDetailsDiv>
            }
        </DistributionCountryDiv>
    )
}

export default DistributionCountry;