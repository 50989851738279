import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SocialMediaLinksInFixedRightBarContent from './SocialMediaLinksInFixedRightBarContent.component';

const FixedRightBarOverlayContainer = styled.div`
    position:fixed;
    right:0px;
    height:175px;
    width:70px;
    top:50%;
    margin-top:-117px;
    background-color:#006384;
    border-radius:1rem 0 0 1rem;
    z-index:10001;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);

    @media (max-width: 992px) { 
        display:none;
    }
`

function FixedRightBarOverlay(props) {

    return (
        <FixedRightBarOverlayContainer>
            <SocialMediaLinksInFixedRightBarContent 
                currentLanguage={props.currentLanguage}
            />
        </FixedRightBarOverlayContainer>
    );
}

export default FixedRightBarOverlay;