import React, { Component, useState, useEffect } from 'react';
import Language from './Language.data.js';
import EmailAddress from './EmailAddress.component';
import $ from "jquery";
import Job from './Job.component';
import LoadingSpinner from './LoadingSpinner.component.js';

function Jobs(props) {
    
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadJobs();
    },[]);

    function loadJobs() {
        window.axios.post(window.location.origin + '/company/jobs', {
            requestType: 'getJobs'
        })
        .then((response) => {
            if(response.data?.success) {
                setJobs(response.data.jobs);
                setIsLoading(false);
            }
        })
        .catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    return (
        <div className="container marginBottom50px area" id="jobs">
            <div className="row">
                <div className="col-12 text-center">
                    <h2>{Language[props.currentLanguage].menu.jobs}</h2>
                </div>
            </div>
            {
                isLoading && 
                <LoadingSpinner key="loadingSpinnerTermsAndConditions"></LoadingSpinner>
            }
            {
                isLoading === false && jobs?.length === 0 &&
                <div className="row align-items-center h-100 marginBottom50px mt-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-left justify-content-center">
                        { 
                            Language["en"].jobsSection.noJobsAvailablePart1
                        }
                        <div className={"mb-3 mt-3"}>
                            <EmailAddress key={"emailAddressKeyApplicants"} classNames="link" emailAddress={'applicants@grolman-group.com'} />
                        </div>
                        {
                        Language["en"].jobsSection.noJobsAvailablePart2
                        }
                    </div>
                </div>
            }
            

            {
                isLoading === false && jobs?.length > 0 &&
                <>
                    <div className="row marginTop50px mt-5">
                        <div className="col-12 text-center">
                            <h2>{Language[props.currentLanguage].jobs}</h2>
                        </div>
                    </div>
                    <div className="row align-items-center h-100 marginBottom50px mt-1">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                            { Language["en"].jobsSection.headerText + ' '}
                        </div>
                    </div>
                </>
            }                         
            {
                jobs?.map((job, index) => {
                    return (
                        <Job key={"job"+index} job={job}/>
                    )
                })
            }

            <div className="row mt-3">
                <div className="col text-left">
                    <h3>Application process</h3>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col text-left">
                    <h4>Who is involved</h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-left">
                        All your future colleagues are strongly involved throughout the entire application process, taking care of suitable job advertisements and interviews, they also take the decision who is invited and later hired on eye level with management.
                </div>
            </div>

            <div className="row mt-3">
                <div className="col text-left">
                    <h4>We'll be in touch</h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-left">
                    Your application will be looked at by your future team and we will do our best to give you feedback as soon as possible - of course in compliance with data protection laws. 
                    As a matter of principle, we want to avoid dragging out an application process unnecessarily. We want to reach a decision as quickly as possible.  
                </div>
            </div>

            <div className="row mt-3">
                <div className="col text-left">
                    <h4>Interview</h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-left">
                    An initial interview to get to know each other takes place with two to three team members, the head of the department and a colleague from our HR department. That sounds like a lot, but it's important to us that you and your future team get to know each other well from the very beginning.
                    Eventually there will be other meetings afterwards where other people can join in.  
                </div>
            </div>

            <div className="row mt-3">
                <div className="col text-left">
                    <h4>Taster Day</h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-left">
                    Then, a taster day follows to give you a better insight into our working environment and processes, and it is also a great way to get to know each other better in a real working environment. During this day a team member accompanies you and shows you all the processes and answers your questions.
                </div>
            </div>

            <div className="row mt-3">
                <div className="col text-left">
                    <h4>Setting</h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-left">
                    If everything is right, we will do our best to make the preparations for your hiring quickly.
                </div>
            </div>

            <div className="row mt-3">
                <div className="col text-left">
                    <h4>Onboarding</h4>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-left">
                    An individual training plan for your time at Grolman, a meet & greet with your new colleagues and a presentation of the company and company’s vision and purpose by our CEO, Florian Grolman, will ensure that you feel at home with us very quickly and you can easily become a team member and start your new job with full enthusiasm and good spirit. 
                </div>
            </div>

            <div className="row mt-5">
                <div className="col text-left">
                    <h3>Equal Opportunity Employer Statement </h3>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-left">
                    We as Grolman are an equal opportunity employer that is committed to diversity and inclusion in the workplace. All employment decisions at Grolman are based on business needs, job requirements and individual qualifications, without regard to race, colour, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability or gender identity.
                </div>
            </div>
            <div className="row mt-3">
                <div className="col text-left">
                    For us, these guidelines apply within our organization to all employment practices, including hiring, recruiting, promotion, termination, layoff, recall, leave of absence, compensation, benefits, training, and apprenticeship. Grolman will not tolerate discrimination or harassment based on any of these characteristics. 
                </div>
            </div>
            <div className="row mt-3">
                <div className="col text-left">
                    Please let us know via <EmailAddress key={"emailAddressKeyHR"} classNames="link" emailAddress={'HumanResources@grolman-group.com'} />, if you have special needs that require accommodating.
                </div>
            </div>
        </div>
    );
}

export default Jobs;