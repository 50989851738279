import React, { useState, useEffect } from 'react';
import ExternalLink from './ExternalLink.component';
import styled from 'styled-components';

import bannerSmallScreens from '../images/PodcastGrolmanCircularTable Talks_640-1024.jpg';
import bannerBigScreens from '../images/PodcastGrolmanCircularTable Talks_1366-1920.png';
import amazon from '../images/podcast/amazon_music.png';
import apple from '../images/podcast/apple_podcasts.png';
import google from '../images/podcast/google_podcasts.png';
import spotify from '../images/podcast/spotify.png';
import anchor from '../images/podcast/anchor_fm.png';

import trailerSeason1mp3 from '../audios/Trailer _GrolmanCirculartableTalks_Season1.mp3';
import trailerSeason2mp3 from '../audios/Trailer _GrolmanCirculartableTalks_Season2.mp3';

const BannerImage = styled.img`
    max-height:300px;
`

const PodcastLinksContainer = styled.div`
    background-color: #006384;
    color:#fff;
    padding:1.5rem;
    border-radius:1rem;
`

function Podcast(props) {

    const [image,setImage] = useState(null);

    useEffect(() => {
        setImage(getImage());

        window.addEventListener("resize", onResize);
        window.scrollTo(0, 0);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    },[])

    function onResize() {
        setImage(getImage());
    }

    function getImage() {
        let image = bannerBigScreens;
        if(window.innerWidth < 1025) {
            image = bannerSmallScreens;
        }
        return image;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col text-center">
                    <BannerImage 
                        className="img-fluid" 
                        src={image}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    Our vision is to sell chemicals to save the planet. Circularity  is the best, 
                    if not the only way to achieve this vision by decoupling growth from the use of resources.
                </div>
            </div>
            <div className="row mt-4">
                <div className="col">
                    "Grolman Circular Table Talks" is a podcast series in which we explore pioneering ideas and projects around circularity with our guests. 
                    We want to discuss what it means to transform linear industries into circular ones and explore the field of innovative solutions with promising potential.
                    Each episode focuses on a different circular idea and its challenges.
                </div>
            </div>
            <div className="row mt-4">
                <div className="col font-weight-bold">
                    Why should you subscribe?
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ul>
                        <li>
                            Get to know pioneers in circularity
                        </li>
                        <li>
                            Listen to different perspectives of how to put circularity into practice along value chains
                        </li>
                        <li>
                            Learn more about practical circular examples in your specific industry
                        </li>
                        <li>
                            Don't miss out any circular innovations
                        </li>
                        <li>
                            We as Grolman know the industry - let's connect to save the planet together
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col font-weight-bold">
                    Designing Circularity
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Our series of Grolman Circular Table Talks will cover pioneers of different industries weekly, discussing the design of circularity.
                </div>
            </div>
            {
                /*
                DN 10.01.2023: After discussing with Marketing - Partner logos will be shown later. Add logos below...
                */
                /*
                <div className="row">
                    <div className="col font-weight-bold">
                        Partners with Innovative Ideas - Get to know their solutions for a circular economy!
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        //add logos here
                    </div>
                </div>
                */
            }
            <PodcastLinksContainer className="row mt-4">
                <div className="col">
                    <div className="row">
                        <div className="col font-weight-bold">
                            Just click the follow button on your favorite streaming platform to join us and also get our next episodes.
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <span>
                                <ExternalLink
                                    currentLanguage={props.currentLanguage}
                                    key={spotify}
                                    linkHrefTag={"https://open.spotify.com/show/1DDuzUHgOtBxDrbXOr7AEq"}
                                    linkTargetTag={"_blank"}
                                    linkAdditionalClasses={"img-fluid"}
                                    imageSrcTag={spotify}
                                    imageAltTag={"Grolman Podcast on Spotify"}
                                    imageHeight={"32px"}
                                    iconWidth={"16px"}
                                    iconHeight={"16px"}
                                    iconPositonRight={"-5px"}
                                    iconPositonBottom={"-10px"}
                                    maskColor={"#fff"}
                                    showExternalLinkText={false}
                                />
                            </span>
                            <span className="ml-1">
                                <ExternalLink
                                    currentLanguage={props.currentLanguage}
                                    key={apple}
                                    linkHrefTag={"https://podcasts.apple.com/us/podcast/grolman-circular-table-talks/id1662850135"}
                                    linkTargetTag={"_blank"}
                                    linkAdditionalClasses={"img-fluid"}
                                    imageSrcTag={apple}
                                    imageAltTag={"Grolman Podcast on Apple Podcasts"}
                                    imageHeight={"32px"}
                                    iconWidth={"16px"}
                                    iconHeight={"16px"}
                                    iconPositonRight={"-5px"}
                                    iconPositonBottom={"-10px"}
                                    maskColor={"#fff"}
                                    showExternalLinkText={false}
                                />
                            </span>
                            <span className="ml-1">
                                <ExternalLink
                                    currentLanguage={props.currentLanguage}
                                    key={google}
                                    linkHrefTag={"https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kNDU1MTkzMC9wb2RjYXN0L3Jzcw"}
                                    linkTargetTag={"_blank"}
                                    linkAdditionalClasses={"img-fluid"}
                                    imageSrcTag={google}
                                    imageAltTag={"Grolman Podcast on Google"}
                                    imageHeight={"32px"}
                                    iconWidth={"16px"}
                                    iconHeight={"16px"}
                                    iconPositonRight={"-5px"}
                                    iconPositonBottom={"-10px"}
                                    maskColor={"#fff"}
                                    showExternalLinkText={false}
                                />
                            </span>
                            <span className="ml-1">
                                <ExternalLink
                                    currentLanguage={props.currentLanguage}
                                    key={amazon}
                                    linkHrefTag={"https://music.amazon.com/podcasts/d005b892-bca7-4e37-8af1-0d4784ea7530/grolman-circular-table-talks"}
                                    linkTargetTag={"_blank"}
                                    linkAdditionalClasses={"img-fluid"}
                                    imageSrcTag={amazon}
                                    imageAltTag={"Grolman Podcast on Amazon Music"}
                                    imageHeight={"32px"}
                                    iconWidth={"16px"}
                                    iconHeight={"16px"}
                                    iconPositonRight={"-5px"}
                                    iconPositonBottom={"-10px"}
                                    maskColor={"#fff"}
                                    showExternalLinkText={false}
                                />
                            </span>
                            <span className="ml-1">
                                <ExternalLink
                                    currentLanguage={props.currentLanguage}
                                    key={anchor}
                                    linkHrefTag={"https://anchor.fm/grolman-podcast"}
                                    linkTargetTag={"_blank"}
                                    linkAdditionalClasses={"img-fluid"}
                                    imageSrcTag={anchor}
                                    imageAltTag={"Grolman Podcast on Anchor FM"}
                                    imageHeight={"32px"}
                                    iconWidth={"16px"}
                                    iconHeight={"16px"}
                                    iconPositonRight={"-5px"}
                                    iconPositonBottom={"-10px"}
                                    maskColor={"#fff"}
                                    showExternalLinkText={false}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            You can listen our trailers here:
                        </div>
                    </div>
                    <div className="row mt-1 d-flex align-items-center">
                        <div className="col">
                            Trailer season I: Designing circularity in plastics
                        </div>
                        <div className="col">
                            <audio controls>
                                <source 
                                    src={trailerSeason1mp3} 
                                    type="audio/mpeg"
                                />
                                We are sorry - your browser does not support this audio format.
                            </audio>
                        </div>
                    </div>
                    <div className="row mt-1 d-flex align-items-center">
                        <div className="col">
                            Trailer season II: Designing circularity in coatings
                        </div>
                        <div className="col">
                            <audio controls>
                                <source 
                                    src={trailerSeason2mp3} 
                                    type="audio/mpeg"
                                />
                                We are sorry - your browser does not support this audio format.
                            </audio>
                        </div>
                    </div>
                </div>
            </PodcastLinksContainer>
        </div>
    )
}

export default Podcast;