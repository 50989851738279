import React, { Component } from 'react';
import Language from './Language.data';
import Country from './Country.data';
import 'flag-icons/css/flag-icons.min.css';
require('bootstrap');
const jQuery = require('jquery');
window.jQuery = jQuery;
import ('bootstrap-select/dist/css/bootstrap-select.min.css');
require('bootstrap-select');
var _ = require('lodash');

class CountrySelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }  

    componentDidMount() {
        this.refreshSelectPickerBoxes();
    }

    componentDidUpdate() {
        this.refreshSelectPickerBoxes();
    }

    refreshSelectPickerBoxes() {
        jQuery('.selectpicker').selectpicker({
            liveSearch:true,
            selectOnTab: true,
        });
        let country = Object.keys(Country).find(key => Country[key].countryNo === this.props.country.countryNo);
        jQuery('.selectpicker').val(country);
        //jQuery('.selectpicker').selectpicker('hide');
	    //jQuery('.selectpicker').selectpicker('render');
	    jQuery('.selectpicker').selectpicker('refresh');
    }
    
    handleClick() {
        this.forceUpdate();
    }

    onCountryChange(event) {
        let countryName = event.target.value; //this.refs.country.value;
        this.props.onCountryChange(countryName);
    }

    render() {
        let country = Object.keys(Country).find(key => Country[key].countryNo === this.props.country.countryNo);
        let countryList = _.sortBy(Country,(c) => { return _.deburr(c.name[this.props.currentLanguage]) });
        let className = "form-group zIndex101 spacing";
        if(this.props.showSpacing === false) {
            className = "form-group zIndex101";
        }
        return (
            <div className={className} id="spacing">
                <select id="selectCountry" data-size="10" className="selectpicker form-control" onClick={this.handleClick.bind(this)} onChange={this.onCountryChange.bind(this)} defaultValue={country}>
                    <option value={"grolmanGroup"} key={"locgrolmanGroup"} data-icon={"fi fi-group flagSelect"}>{Language[this.props.currentLanguage].selectLocation.toUpperCase()}</option> 
                    {
                        countryList.map((countryElement) => {
                            if(countryElement.showCountry === true && countryElement.key !== "grolmanGroup") {
                                return (
                                    <option value={countryElement.key} key={"country" + countryElement.key}  data-icon={countryElement.flag + " flagSelect"}>{countryElement.name[this.props.currentLanguage].toUpperCase()}</option>
                                );
                            }
                            else {
                                return undefined;
                            }
                        }).filter(country => country !== undefined)
                    }
                </select>
            </div>
        );
    }
}

export default CountrySelection;