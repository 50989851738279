import React from 'react';
import Language from '../Language.data';

function PrintingInksAndGraphicArts(props) {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <h3>{Language[props.currentLanguage].industries.printingInksAndGraphicArts.printingInk}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].marketSegments}:
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.printingInksAndGraphicArts.marketSegmentsText}
                </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center align-items-center">
                <div className="col">
                    {Language[props.currentLanguage].industries.printingInksAndGraphicArts.industryText}
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col">
                    <ul>
                        <li>{Language[props.currentLanguage].functionalities.coloursAndEffects}</li>
                        <li>{Language[props.currentLanguage].functionalities.viscosityAndRheologyModification}</li>
                        <li>{Language[props.currentLanguage].functionalities.renewableRawMaterials}</li>
                        <li>{Language[props.currentLanguage].functionalities.wettingAndDispersing}</li>
                        <li>{Language[props.currentLanguage].functionalities.defoaming}</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default PrintingInksAndGraphicArts;