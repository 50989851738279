import React, { useState, useEffect } from 'react';
import Language from './Language.data.js';
import LanguageSelection from './LanguageSelection.component';
import DataProcessing from './privacy/DataProcessing.component';
import DataProcessingSocialNetworks from './privacy/DataProcessingSocialNetworks.component';
import Rights from './privacy/Rights.component';
import ResponsibilityAndDataProtectionOfficer from './privacy/ResponsibilityAndDataProtectionOfficer.component';
require('bootstrap');

function Privacy(props) {
    const languages = ['de','en'];
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    useEffect(() => {
        window.scrollTo(0, 0);
        //this.setDefaultLanguage();
        if(window._paq) {
            window._paq.push(['trackEvent', 'Webseite', 'Privacy.js', '']);
        }
    },[])

    useEffect(() => {
        if((props.currentLanguage === 'en' || props.currentLanguage === 'de')) {
            setSelectedLanguage(props.currentLanguage);
        }
    },[props.currentLanguage])

    function setDefaultLanguage() {
        let newLanguage = 'en';
        switch(this.props.country.countryNo) {
            case 4:
                newLanguage = 'de';
                break;
            case 5:
                newLanguage = 'pl';
                break;
            case 11:
                newLanguage = 'es';
                break;
            case 60:
                newLanguage = 'pl';
                break;
            default:
                newLanguage = this.props.currentLanguage;
                break;
        }
        setSelectedLanguage(newLanguage);
    }

    function handleLanguageChange(language) {
        if(language !== "0") {
            setSelectedLanguage(language);
        }
    }

    function onShowOptOutOption(option) {
        props.onShowOptOutOption(option);
    }

    return (
        <div className="container marginTop50px marginBottom50px area" id="privacy">
            <div className="row marginTop50px">
                <div className="col-12 text-center">
                    <h2>{Language[selectedLanguage].privacy}</h2>
                </div>
            </div>
            <div className="row marginTop50px marginBottom50px upper">
                <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3 text-center">
                    <LanguageSelection 
                        key="languageSelectionKey" 
                        currentLanguage={props.currentLanguage} 
                        country={props.country} 
                        languagesToDisplay={languages} 
                        selectedLanguage={selectedLanguage} 
                        onLanguageChange={handleLanguageChange} 
                    />
                </div>
            </div>
            <div className="row mt-3 marginTop50px">
                <div className="col text-justify">
                    {
                        Language[selectedLanguage].privacyTexts.whenYouVisitOurWebsite
                    }
                </div>
            </div>
            <DataProcessing
                key={"privacyDataProcessing"}
                selectedLanguage={selectedLanguage}
                onShowOptOutOption={onShowOptOutOption}
            />
            <DataProcessingSocialNetworks
                key={"privacyDataProcessingSocialNetworks"}
                selectedLanguage={selectedLanguage}
            />
            <Rights
                key={"privacyRights"}
                selectedLanguage={selectedLanguage}
            />
            <ResponsibilityAndDataProtectionOfficer
                key={"privacyResponsibilityAndDataProtectionOfficer"}
                selectedLanguage={selectedLanguage}
                country={props.country}
            />
        </div>
    );
}

export default Privacy;